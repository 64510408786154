import { Button, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { uniq } from 'lodash';
import React, { useRef, useState } from 'react';
import { refreshDeeplink } from '../../api';
import { Tracker } from './HunterTool';

interface DeeplinkToolProps {
	tracker: Tracker[];
}

interface RefreshDeeplinkResult {
	countryCode: string;
	totalMovie: number;
	totalSeries: number;
}

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(1)
	},
	progress: {
		height: '4px'
	}
}));

const renderResult = (deeplinks: RefreshDeeplinkResult[]) => {
	return (
		<Table size="small">
			<TableHead>
				<TableRow>
					<TableCell>countryCode</TableCell>
					<TableCell>totalMovie</TableCell>
					<TableCell>totalSeries</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{deeplinks.map(({ countryCode, totalMovie, totalSeries }) => (
					<TableRow key={countryCode}>
						<TableCell>{countryCode}</TableCell>
						<TableCell>{totalMovie}</TableCell>
						<TableCell>{totalSeries}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

export default function DeeplinkTool(props: DeeplinkToolProps) {
	const classes = useStyles();
	const { tracker } = props;
	let [isRefreshing, setIsRefreshing] = useState(false);
	let refreshResult = useRef();

	const countries = uniq(tracker.map((tracker) => tracker.countryCode));

	const refreshDeeplinkCallback = async () => {
		try {
			refreshResult.current = undefined;
			setIsRefreshing(true);
			const res = await refreshDeeplink(countries);
			if (res.status === 200) {
				const { data } = await res.json();
				refreshResult.current = data.filter((item: any) => !!item);
				setIsRefreshing(false);
			}
		} catch (error) {
			console.log(error);
			setIsRefreshing(false);
		}
	};

	return (
		<Paper className={classes.paper}>
			{isRefreshing ? <LinearProgress /> : <div className={classes.progress} />}
			<Button variant="outlined" disabled={isRefreshing} onClick={() => refreshDeeplinkCallback()}>
				Refresh deeplinks for all countries.
			</Button>
			{refreshResult.current ? <h3>Refresh result:</h3> : null}
			{refreshResult.current ? renderResult(refreshResult.current as any) : null}
		</Paper>
	);
}
