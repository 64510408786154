import { LinearProgress, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { debounce, orderBy } from 'lodash';
import { calculateAsrTaskWer, fetchAsrAggregateData, fetchAsrNewTask, fetchAsrTaskList, updateAsrTask } from '../../api';
import WerQueryList from './WerQueryList';
import WerSelectOption from './WerSelectOption';
import WerTask from './WerTask';

interface AsrQuery {
	queryId: string;
	timestamp: string;
	deviceId: string;
	sessionId: string;
	transcript: string;
	correction?: string;
}
export interface AsrTask {
	_id: string;
	engine: string;
	locale: string;
	timestamp: string;
	status: string;
	queries: AsrQuery[];
	wer?: string;
}

interface AggregateInfo {
	[_: string]: {
		[_: string]: {
			region: string;
			count: number;
		}[];
	};
}

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(1)
	},
	progress: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		height: '4px'
	}
}));

const pushNewCorrection = async (taskId: string, queryId: string, correction: string) => {
	try {
		await updateAsrTask(taskId, queryId, correction);
	} catch (error) {
		console.log(error);
	}
};

// Push update to server ONLY 500ms after user stops typing
const debouncedPushNewCorrection = debounce(pushNewCorrection, 500);
// Query batch size
const QUERY_BATCH_SIZE = 100;

export default function Wer() {
	const classes = useStyles();

	const [isLoading, setIsLoading] = useState(false);
	const aggregateInfoRef = useRef<AggregateInfo>();
	const [engine, setEngine] = useState('mosaix');
	const [locale, setLocale] = useState('');
	const [region, setRegion] = useState('');
	const [tasks, setTasks] = useState<AsrTask[]>([]);
	const [currentTaskId, setCurrentTaskId] = useState('');

	useEffect(() => {
		const triggerFetchAggregateData = async () => {
			setIsLoading(true);
			try {
				const res = await fetchAsrAggregateData();
				if (res.status < 300) {
					const data = await res.json();
					aggregateInfoRef.current = data;
					if (!data[engine]) {
						setEngine(() => Object.keys(data)[0]);
					}
					updateLocaleAndRegionByEngine(engine);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};

		triggerFetchAggregateData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!engine || !locale || !region) return;

		const triggerFetchTasks = async () => {
			setIsLoading(true);
			try {
				const res = await fetchAsrTaskList(engine, locale, region);
				if (res.status < 300) {
					const data = await res.json();
					setTasks(data);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};

		triggerFetchTasks();
	}, [engine, locale, region]);

	const getLocalesAndRegionsForEngine = (engine: string): { [_: string]: { region: string; count: number }[] } => {
		const localesAndRegions = aggregateInfoRef.current?.[engine] ?? { 'en-US': [] };
		return localesAndRegions;
	};

	const updateLocaleAndRegionByEngine = (engine: string) => {
		const localesAndRegions = getLocalesAndRegionsForEngine(engine);
		const locales = Object.keys(localesAndRegions);
		const locale = locales.indexOf('en-US') > -1 ? 'en-US' : locales[0];
		const regionsAndCount = localesAndRegions[locale];
		setLocale(locale);
		setRegion(regionsAndCount[0]?.region);
	};

	const updateRegionByLocale = (engine: string, locale: string) => {
		const localesAndRegions = getLocalesAndRegionsForEngine(engine);
		const regionsAndCount = localesAndRegions[locale];
		setRegion(regionsAndCount[0].region);
	};

	const updateOption = (key: 'engine' | 'locale' | 'region', value: string) => {
		switch (key) {
			case 'engine':
				setEngine(value);
				updateLocaleAndRegionByEngine(value);
				break;
			case 'locale':
				setLocale(value);
				updateRegionByLocale(engine, value);
				break;
			case 'region':
				setRegion(value);
				break;
			default:
				break;
		}
	};

	const pickButtonPressed = async () => {
		try {
			setIsLoading(true);

			const res = await fetchAsrNewTask(engine, locale, region, QUERY_BATCH_SIZE);
			if (res.status < 300) {
				const data = await res.json();
				const id = data._id;
				setTasks((task) => [data, ...task]);
				setCurrentTaskId(() => id);
			}
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	};

	const updateCurrentTask = (taskId: string) => {
		setCurrentTaskId(taskId);
	};

	const updateQuery = (taskId: string, queryId: string, correction: string) => {
		// update on mongo
		debouncedPushNewCorrection(taskId, queryId, correction);

		// update on local
		const updatedTasks = tasks.map((task) => {
			if (task._id === taskId) {
				const updatedTask = { ...task };
				updatedTask.queries = updatedTask.queries.map((query) => {
					if (query.queryId === queryId) {
						query.correction = correction;
					}

					return query;
				});

				return updatedTask;
			}
			return task;
		});

		setTasks(updatedTasks);
	};

	const werButtonPressed = async (taskId: string) => {
		try {
			setIsLoading(true);
			const res = await calculateAsrTaskWer(taskId);
			if (res.status < 300) {
				const { wer } = await res.json();
				const updateTasks = tasks.map((task) => {
					if (task._id === taskId) {
						task.wer = wer;
						task.status = 'done';
					}

					return task;
				});

				setTasks(updateTasks);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const engines = Object.keys(aggregateInfoRef.current ?? {});
	const locales = Object.keys(getLocalesAndRegionsForEngine(engine)).sort();
	const regionsAndCount = orderBy(getLocalesAndRegionsForEngine(engine)[locale], ['count'], ['desc']);

	return (
		<Paper className={classes.paper}>
			{isLoading ? <LinearProgress className={classes.progress} /> : <div className={classes.progress} />}
			<WerSelectOption
				isLoading={isLoading}
				defaultOptions={{ engine, locale, region, engines, locales, regionsAndCount }}
				updateOption={updateOption}
				pickButtonPressed={pickButtonPressed}
			/>
			<WerTask isLoading={isLoading} tasks={tasks} currentTaskId={currentTaskId} taskButtonClicked={updateCurrentTask} />
			<hr />
			<WerQueryList isLoading={isLoading} task={tasks.find((el) => el._id === currentTaskId)} updateQuery={updateQuery} werButtonPressed={werButtonPressed} />
		</Paper>
	);
}
