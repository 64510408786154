import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Table, TableHead, TableRow, TableCell, TablePagination, TableBody } from '@material-ui/core';
import clsx from 'clsx';
import { get, first } from 'lodash';
import { PAGE_SIZE } from './TmdbMovies';

export interface TmdbImage {
	aspect_ratio: number;
	file_path: string;
	height: number;
	iso_639_1: string;
	vote_average: number;
	vote_count: number;
	width: number;
}

export interface TmdbGenre {
	id: number;
	name: string;
}

interface TmdbSpokenLanguage {
	english_name: string;
	iso_639_1: string;
	name: string;
}

export interface TmdbCast {
	adult: number;
	cast_id: number;
	character: string;
	credit_id: string;
	gender: number;
	id: number;
	known_for_department: string;
	name: string;
	order: number;
	original_name: string;
	popularity: number;
	profile_path: string;
}

export interface TmdbCrew {
	adult: number;
	credit_id: string;
	department: string;
	gender: number;
	id: number;
	job: string;
	known_for_department: string;
	name: string;
	original_name: string;
	popularity: number;
	profile_path: string;
}

export interface TmdbKeyword {
	id: number;
	name: string;
}

export interface TmdbVideo {
	id: string;
	iso_639_1: string;
	iso_3166_1: string;
	key: string;
	name: string;
	site: string;
	size: number;
	type: string;
}

export interface TmdbMovie {
	id: number;
	uid: string;
	imdbId: string;
	status: string;
	title: string;
	originalTitle: string;
	originalLanguage: string;
	overview: string;
	releaseDate: string;
	runtime: number;
	popularity: number;
	genres: TmdbGenre[];
	homepage: string;
	adult: number;
	spokenLanguages: TmdbSpokenLanguage[];
	tagline: string;
	video: number;
	voteAverage: number;
	voteCount: number;
	images: {
		backdrops: TmdbImage[];
		posters: TmdbImage[];
	};
	credits: {
		cast: TmdbCast[];
		crew: TmdbCrew[];
	};
	keywords: TmdbKeyword[];
	translations: any;
	videos: TmdbVideo[];
	recommendations: string;
	releaseDates: any;
	created: string;
	lastUpdated: string;
}

interface TmdbMovieListProps {
	movieItems: TmdbMovie[];
	currentPage: number;
	totalMovieCount: number;
	searchText: string;
	status: string;
	updateSearchText: (searchText: string) => void;
	updateStatus: (status: string) => void;
	startSearch: () => void;
	updatePage: (newPage: number) => void;
	updateCurrentMovieItem: (movie: TmdbMovie) => void;
}

const useStyle = makeStyles((theme) => ({
	container: {
		paddingBottom: theme.spacing(1),
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end'
	},
	searchText: {
		minWidth: '300px'
	},
	underlineText: {
		textDecoration: 'underline',
		fontWeight: 400,
		fontSize: '1rem'
	},
	hover: {
		'&:hover': {
			cursor: 'pointer'
		}
	},
	editText: {
		color: 'blue'
	},
	table: {
		tableLayout: 'fixed',
		border: '2px solid grey'
	},
	cell: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	poster: {
		height: '100px'
	}
}));

export default function TmdbMovieList(props: TmdbMovieListProps) {
	const { currentPage, totalMovieCount, updatePage, movieItems, searchText, updateSearchText, startSearch, updateCurrentMovieItem } = props;
	const classes = useStyle();

	const detailActionPressed = useCallback(
		(movieItem: TmdbMovie) => {
			updateCurrentMovieItem(movieItem);
		},
		[updateCurrentMovieItem]
	);

	return (
		<React.Fragment>
			<div className={classes.container}>
				<div></div>
				<div>
					<TextField
						className={classes.searchText}
						margin="none"
						id="search"
						name="search"
						placeholder="Key in and Press 'Return' to search"
						value={searchText}
						onChange={(event) => updateSearchText(event.target.value)}
						onKeyDown={(event) => (event.keyCode === 13 ? startSearch() : null)}
					/>
				</div>
			</div>

			<React.Fragment>
				<Table className={classes.table} size="small">
					<TableHead>
						<TableRow>
							<TableCell style={{ width: '5%' }}>Id</TableCell>
							<TableCell style={{ width: '15%' }}>Title</TableCell>
							<TableCell style={{ width: '10%' }}>Poster</TableCell>
							<TableCell style={{ width: '10%' }}>Release Date</TableCell>
							<TableCell style={{ width: '7%' }}>Runtime</TableCell>
							<TableCell style={{ width: '45%' }}>Overview</TableCell>
							<TableCell style={{ width: '7%' }}>action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{movieItems.map((movieItem) => (
							<TableRow key={movieItem.id} onClick={() => detailActionPressed(movieItem)}>
								<TableCell>{movieItem.id}</TableCell>
								<TableCell>{movieItem.title}</TableCell>
								<TableCell className={classes.cell}>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={`https://image.tmdb.org/t/p/original${get(first(movieItem.images.posters), 'file_path')}`}
										onClick={(e) => e.stopPropagation()}
									>
										<img src={`https://image.tmdb.org/t/p/w500${get(first(movieItem.images.posters), 'file_path')}`} alt={'img'} className={classes.poster} />
									</a>
								</TableCell>
								<TableCell className={classes.cell}>{movieItem.releaseDate}</TableCell>
								<TableCell>{movieItem.runtime}</TableCell>
								<TableCell>{movieItem.overview}</TableCell>
								<TableCell className={classes.cell}>
									<span className={clsx(classes.underlineText, classes.editText, classes.hover)} onClick={() => detailActionPressed(movieItem)}>
										Detail
									</span>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[PAGE_SIZE]}
					component="div"
					count={totalMovieCount}
					rowsPerPage={PAGE_SIZE}
					page={totalMovieCount > 0 ? currentPage - 1 : 0}
					onPageChange={(_, newPage: number) => {
						updatePage(newPage + 1);
					}}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
