import React, { useEffect, useRef, useState } from 'react';
import { ITVItem, ITVItemSeries, ITVOfferSection } from '../TVInterface';
import { Grid, Card, CardContent, Typography, LinearProgress } from '@material-ui/core';
import { fetchRecMenuItemExtra } from '../../../api';
import { useStyles } from './MovieDetail';

interface SeriesDetailProps {
	item: ITVItemSeries;
	headers: { serverType: number; client: string; regionCode: string; sdkVersion: number; languageCode?: string; refreshCache: boolean };
	updateCurrentSelectedItem: (currentItem: ITVItem) => void;
}

function renderMetadata(item: ITVItemSeries) {
	const { releaseYear, hasFreeSource, totalSeasons, totalEpisodes, synopsis, genres, casts, directors, offerSections } = item;
	const castsText = casts.map((cast) => cast.name).join(', ');
	const directorsText = directors.map((director) => director.name).join(', ');

	return (
		<React.Fragment>
			<CardContent>
				<Typography variant="h6" component="h4">
					<span>{releaseYear}&nbsp;&nbsp;&nbsp;</span>
					<span>{`${totalSeasons} season(s) ${totalEpisodes} episodes`}&nbsp;&nbsp;&nbsp;</span>
					{hasFreeSource ? <span>FREE&nbsp;&nbsp;&nbsp;</span> : null}
				</Typography>
				<Typography variant="body2" component="p">
					{synopsis}
				</Typography>
				<br />
				<Typography variant="body2" component="p">
					<span style={{ fontWeight: 700 }}>Genre: </span>
					{genres.join(', ')}
				</Typography>
				<Typography variant="body2" component="p">
					<span style={{ fontWeight: 700 }}>Cast: </span>
					{castsText}
				</Typography>
				<Typography variant="body2" component="p">
					<span style={{ fontWeight: 700 }}>Director: </span>
					{directorsText}
				</Typography>
			</CardContent>
			{offerSections.map((offerSection) => renderOfferSection(offerSection))}
		</React.Fragment>
	);
}

function renderOfferSection(offerSection: ITVOfferSection) {
	const { title, offers } = offerSection;
	return (
		<CardContent key={title}>
			<Typography variant="h6" component="h4">
				{title}
			</Typography>
			{offers.map((offer, index) => {
				const { type, source, url, audioLanguageDesc, currency, price, videoQuality } = offer;
				return (
					<Grid container key={`${source.code}-${index}`} alignItems="center">
						<img alt="logo" src={source.logo.url} style={{ height: '30px', width: 'auto', margin: '10px' }} />
						<a target="_blank" rel="noopener noreferrer" href={url.web}>
							{type === 'free' || type === 'subscription' ? (
								<span style={{ fontSize: '20px' }}>{`${type} - [audio: ${audioLanguageDesc}] - [quality: ${videoQuality}]`}</span>
							) : (
								<span style={{ fontSize: '20px' }}>{`$${price}(${currency}) - [audio: ${audioLanguageDesc}] - [quality: ${videoQuality}]`}</span>
							)}
						</a>
						<span style={{ marginLeft: '20px', fontSize: '20px' }}>
							D:&nbsp;
							<a target="_blank" rel="noopener noreferrer" href={url.android ? url.android : undefined}>
								{url.android ?? 'N/A'}
							</a>
						</span>
					</Grid>
				);
			})}
		</CardContent>
	);
}

export default function SeriesDetail(props: SeriesDetailProps) {
	const classes = useStyles();
	const { item, headers, updateCurrentSelectedItem } = props;

	const recDataRef = useRef([]);
	const trailerDataRef = useRef([]);
	const [isLoadingExtra, setIsLoadingExtra] = useState(false);

	useEffect(() => {
		async function effect() {
			recDataRef.current = [];
			trailerDataRef.current = [];
			setIsLoadingExtra(true);
			try {
				const res = await fetchRecMenuItemExtra({ ...headers, ...{ menuType: item.type, itemId: item.id } });
				const { status } = res;
				if (status === 200) {
					const { recommendations, trailers } = await res.json();
					recDataRef.current = recommendations;
					trailerDataRef.current = trailers;
				}
				setIsLoadingExtra(false);
			} catch (error) {
				console.log(error);
				setIsLoadingExtra(false);
			}
		}
		effect();
	}, [headers, item.type, item.id]);

	const renderRecItems = (recItems: ITVItem[]) => {
		return (
			<Grid>
				<Typography variant="h5" color="textSecondary">
					{'Recommendations'}
				</Typography>
				<Grid container wrap="nowrap" className={classes.container} alignItems="center">
					{recItems.map((item) => {
						const { media, title } = item;
						const posterUrl = media.posters[0].url;

						return (
							<div key={item.id} className={classes.portraitItemContainer} onClick={() => updateCurrentSelectedItem(item)}>
								<img alt="img" src={posterUrl} className={classes.portraitPoster} />
								<div style={{ textAlign: 'center', whiteSpace: 'nowrap', overflowX: 'hidden' }}>{title}</div>
							</div>
						);
					})}
				</Grid>
			</Grid>
		);
	};

	const renderTrailors = (trailors: ITVItem[]) => {
		return (
			<Grid>
				<Typography variant="h5" color="textSecondary">
					{'Trailers'}
				</Typography>
				<Grid container wrap="nowrap" className={classes.container} alignItems="center">
					{trailors.map((item) => {
						const { media, url, title } = item;
						const posterUrl = media.posters[0].url;

						return (
							<div key={item.id} className={classes.landscapeItemContainer}>
								<a target="_blank" rel="noopener noreferrer" href={`${url.web || url.android}`}>
									<img alt="img" src={posterUrl} className={classes.landscapePoster} />
								</a>
								<div style={{ textAlign: 'center', whiteSpace: 'nowrap', overflowX: 'hidden' }}>{title}</div>
							</div>
						);
					})}
				</Grid>
			</Grid>
		);
	};

	if (!item) return null;

	const { media } = item;
	const backgroundImageUrl = media.backdrops[0].url;

	return (
		<React.Fragment>
			<Grid container>
				<Grid item xs>
					<Card style={{ minHeight: '200px', marginRight: '20px' }}>{renderMetadata(item)}</Card>
				</Grid>
				<Grid item key={`summary-${item.id}`} className={classes.imageContainer}>
					<a target="_blank" rel="noopener noreferrer" href={backgroundImageUrl}>
						<img alt="img" src={backgroundImageUrl} className={classes.image} />
					</a>
				</Grid>
			</Grid>

			{/** Loading progress */}
			{isLoadingExtra ? <LinearProgress className={classes.progress} /> : <div className={classes.progress} />}

			{/** Recommendations */}
			<Grid className={classes.sectionContainer}>{recDataRef.current.length ? renderRecItems(recDataRef.current) : null}</Grid>
			{/** Trailers */}
			<Grid className={classes.sectionContainer}>{trailerDataRef.current.length ? renderTrailors(trailerDataRef.current) : null}</Grid>
		</React.Fragment>
	);
}
