import { ENTERTAINMENT_DOMAINS, SEARCH_SDK_VERSION_LIST } from './Const';
import { TmdbMovie } from '../components/tmdbMovies/TmdbMovieList';
import { TmdbSeriesItem } from '../components/tmdbSeries/TmdbSeriesList';
import { TmdbAlternativeName, TmdbPersonItem } from '../components/tmdbPerson/TmdbPersonList';

export const deleteCookies = () => {
	document.cookie.split(';').forEach((c) => {
		document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
	});
};

export const calRatio = (width: number | null, height: number | null): string => {
	if (width && height) {
		const gdcValue = gdc(width, height);
		return `${width / gdcValue}:${height / gdcValue}`;
	}
	return '';
};

const gdc = (a: number, b: number): number => {
	return b === 0 ? a : gdc(b, a % b);
};

export const pegiRatings = ['pegi3', 'pegi7', 'pegi12', 'pegi16', 'pegi18'];
export const esrbRatings = ['RP', 'E', 'E10plus', 'T', 'M', 'AO'];

export const filterInterpretations = (
	interpretations: { domain: string; intent: string; fulfillments: object[] }[],
	searchSDKVersion: string
): { isEntertainmentDomain: boolean; interpretations: { domain: string; intent: string; fulfillments: object[] }[] } => {
	const supportedIntents = SEARCH_SDK_VERSION_LIST.find((data) => data.value === searchSDKVersion)?.supportedIntents || [];
	for (const interpretation of interpretations) {
		const intent = interpretation.intent;
		if (supportedIntents.includes(intent)) {
			return {
				isEntertainmentDomain: false,
				interpretations: [interpretation]
			};
		}
	}

	const filteredInterpretations = [];
	for (const interpretation of interpretations) {
		const domain = interpretation.domain;
		if (ENTERTAINMENT_DOMAINS.includes(domain)) {
			filteredInterpretations.push(interpretation);
		}
	}

	return {
		isEntertainmentDomain: true,
		interpretations: filteredInterpretations
	};
};

export const formatInterpretations = (interpretations: { domain: string; fulfillments: object[] }[]) => {
	return interpretations.map((interpretation, index) => {
		const { domain, fulfillments } = interpretation;
		return {
			order: index + 1,
			name: domain,
			items: fulfillments
		};
	});
};

export const formatTmdbMovie = (tmdbMovie: any): TmdbMovie => {
	const { images, genres, spokenLanguages, credits, keywords, translations, videos, releaseDates } = tmdbMovie;
	return {
		...tmdbMovie,
		images: images ? JSON.parse(images) : { backdrops: [], posters: [] },
		genres: genres ? JSON.parse(genres) : [],
		spokenLanguages: spokenLanguages ? JSON.parse(spokenLanguages) : [],
		credits: credits ? JSON.parse(credits) : { cast: [], crew: [] },
		keywords: keywords ? JSON.parse(keywords) : [],
		translations: translations ? JSON.parse(translations) : [],
		videos: videos ? JSON.parse(videos) : [],
		releaseDates: releaseDates ? JSON.parse(releaseDates) : []
	};
};

export const formatTmdbSeries = (tmdbSeries: any): TmdbSeriesItem => {
	const {
		episodeRuntime,
		images,
		genres,
		languages,
		seasons,
		createdBy,
		lastEpisodeToAir,
		nextEpisodeToAir,
		alternativeTitles,
		contentRatings,
		credits,
		keywords,
		translations,
		videos,
		releaseDates
	} = tmdbSeries;
	return {
		...tmdbSeries,
		episodeRuntime: episodeRuntime ? JSON.parse(episodeRuntime) : [],
		images: images ? JSON.parse(images) : { backdrops: [], posters: [] },
		genres: genres ? JSON.parse(genres) : [],
		languages: languages ? JSON.parse(languages) : [],
		seasons: seasons ? JSON.parse(seasons) : [],
		createdBy: createdBy ? JSON.parse(createdBy) : [],
		lastEpisodeToAir: lastEpisodeToAir ? JSON.parse(lastEpisodeToAir) : null,
		nextEpisodeToAir: nextEpisodeToAir ? JSON.parse(nextEpisodeToAir) : null,
		alternativeTitles: alternativeTitles ? JSON.parse(alternativeTitles) : [],
		contentRatings: contentRatings ? JSON.parse(contentRatings) : [],
		credits: credits ? JSON.parse(credits) : { cast: [], crew: [] },
		keywords: keywords ? JSON.parse(keywords) : [],
		translations: translations ? JSON.parse(translations) : [],
		videos: videos ? JSON.parse(videos) : [],
		releaseDates: releaseDates ? JSON.parse(releaseDates) : []
	};
};

export const formatRuntime = (runtime: number) => {
	if (!runtime) return '';
	return runtime > 60 ? `${Math.floor(runtime / 60)}h ${runtime % 60}m` : `${runtime}m`;
};

export const formatTmdbPerson = (tmdbPerson: any): TmdbPersonItem => {
	const { alternativeNames, alsoKnownAs, images, movieCredits, tvCredits, translations, externalIds } = tmdbPerson;

	return {
		...tmdbPerson,
		alternativeNames: alternativeNames ? JSON.parse(alternativeNames) : [],
		alsoKnownAs: alsoKnownAs ? JSON.parse(alsoKnownAs) : [],
		images: images ? JSON.parse(images) : { profiles: [] },
		movieCredits: movieCredits ? JSON.parse(movieCredits) : { cast: [], crew: [] },
		tvCredits: tvCredits ? JSON.parse(tvCredits) : { cast: [], crew: [] },
		translations: translations ? JSON.parse(translations) : [],
		externalIds: externalIds ? JSON.parse(externalIds) : []
	};
};

export const formatPersonAlternativeName = (alternativeName: TmdbAlternativeName): string => {
	const formatAlias = alternativeName.aliases.map((alias) => `${alias.alias} - ${alias.score}`);
	return `${alternativeName.language_code}:\n${formatAlias.join('\n')}\n`;
};
