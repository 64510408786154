import React, { useRef } from 'react';
import { Grid, Typography, makeStyles, IconButton, Popover, List, ListItemText, ListItem } from '@material-ui/core';
import { IYoutubeVideo } from './Blacklist';
import MoreVertIcon from '@material-ui/icons/MoreVert';

interface BlacklistSectionProps {
	type: string;
	items: IYoutubeVideo[];
	addToBlacklist: (ids: string[]) => void;
}

const useStyles = makeStyles(() => ({
	container: {
		// overflowX: 'auto'
		flexWrap: 'wrap'
	},
	itemContainer: {
		padding: '10px'
	},
	posterContainer: {
		width: '250px',
		height: '140px',
		padding: '10px',
		flexShrink: 0,
		transition: '0.3s',
		'&:hover': {
			cursor: 'pointer',
			padding: '5px'
		}
	},
	poster: {
		width: '230px',
		height: '130px',
		transition: '0.3s',
		opacity: 0.85,
		'&:hover': {
			width: '240px',
			opacity: 1
		}
	},
	titleContainer: {
		width: '250px',
		paddingLeft: '10px',
		paddingRight: '10px',
		display: 'flex',
		alignItems: 'center'
	},
	title: {
		width: 'calc(100% - 24px)',
		height: '20px',
		overflowX: 'hidden',
		display: 'inline-block'
	}
}));

export default function BlacklistSection(props: BlacklistSectionProps) {
	const classes = useStyles();
	const { type, items, addToBlacklist } = props;
	const currentItemRef = useRef<IYoutubeVideo>(items[0]);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopover = !!anchorEl;

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const parseIds = (): string[] => {
		let ids = [];
		const { id, weblink } = currentItemRef.current;
		if (weblink.indexOf('watch?v=') > -1) {
			ids.push(weblink.split('watch?v=')[1]);
		} else if (weblink.indexOf('/embed/') > -1) {
			ids.push(weblink.split('/embed/')[1]);
		}

		const matchedResult = /^youtube-(.*)-(video|tv|Movie|Music)$/gi.exec(id);
		if (matchedResult != null) {
			ids.push(matchedResult[1]);
		}

		return ids;
	};

	return (
		<Grid style={{ border: '1px dashed lightgrey', padding: '10px', marginBottom: '10px' }}>
			<Typography variant="h5" color="textSecondary">
				{type}
			</Typography>
			<Grid container wrap="nowrap" className={classes.container} alignItems="center">
				{items
					? items.map((item, key) => {
							const posterUrl = item.image.medium.url;

							return (
								<div className={classes.itemContainer} key={`${type}-${item.id}-${key}`}>
									<div className={classes.posterContainer}>
										<a target="_blank" rel="noopener noreferrer" href={item.weblink}>
											<img alt="img" src={posterUrl} className={classes.poster} />
										</a>
									</div>
									<div className={classes.titleContainer}>
										<div className={classes.title}>{item.name}</div>
										<IconButton
											style={{ padding: '0px' }}
											onClick={(e) => {
												currentItemRef.current = item;
												handleClick(e);
											}}
										>
											<MoreVertIcon />
										</IconButton>
										<Popover
											elevation={1}
											open={openPopover}
											anchorEl={anchorEl}
											onClose={() => {
												setAnchorEl(null);
											}}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center'
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center'
											}}
										>
											<List>
												<ListItem
													button
													onClick={() => {
														addToBlacklist(parseIds());
														setAnchorEl(null);
													}}
												>
													<ListItemText primary="Add to blacklist" />
												</ListItem>
											</List>
										</Popover>
									</div>
								</div>
							);
					  })
					: null}
			</Grid>
		</Grid>
	);
}
