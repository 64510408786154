import React from 'react';
import { get } from 'lodash';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { ITVSection, ITVItem } from './TVInterface';

interface TVSectionProps {
	menu: string;
	section: ITVSection;
	updateCurrentFocusedItem: (currentItem: ITVItem) => void;
	updateCurrentSelectedItem: (currentItem: ITVItem) => void;
}

const useStyles = makeStyles((theme) => ({
	container: {
		overflowX: 'auto'
	},
	itemContainer: {
		width: (props) => ((props as any).posterRatio === 'Landscape' ? '300px' : '200px'),
		height: (props) => ((props as any).posterRatio === 'Landscape' ? '230px' : (props as any).posterRatio === 'Portrait' ? '300px' : '200px'),
		flexShrink: 0,
		padding: '10px',
		transition: '0.3s',
		'&:hover': {
			cursor: 'pointer',
			padding: '5px'
		}
	},
	poster: {
		width: (props) => ((props as any).posterRatio === 'Landscape' ? '280px' : '180px'),
		height: 'auto',
		transition: '0.3s',
		opacity: 0.85,
		'&:hover': {
			width: (props) => ((props as any).posterRatio === 'Landscape' ? '290px' : '190px'),
			opacity: 1
		}
	}
}));

function getMusicUrl(item: ITVItem) {
	return item.type.toLocaleLowerCase() === 'music' || item.type.toLocaleLowerCase() === 'video' ? item?.url?.web || item?.url?.android : undefined;
}

export default function TVSection(props: TVSectionProps) {
	const { menu, section, updateCurrentFocusedItem, updateCurrentSelectedItem } = props;
	const { name, items } = section;
	const posterRatio = get(items, '[0].media.posters[0].dimensions.orientation', 'Portrait');

	const classes = useStyles({ posterRatio });

	return (
		<Grid>
			<Typography variant="h5" color="textSecondary">
				{name}
			</Typography>
			<Grid container wrap="nowrap" className={classes.container} alignItems="center">
				{items
					? items.map((item, key) => {
							const posterUrl = item.media.posters[0].url;

							return (
								<div
									key={`${menu}-${item.id}-${name}-${key}`}
									className={classes.itemContainer}
									onMouseEnter={() => updateCurrentFocusedItem(item)}
									onClick={() => updateCurrentSelectedItem(item)}
								>
									<a target="_blank" rel="noopener noreferrer" href={getMusicUrl(item)}>
										<img alt="img" src={posterUrl} className={classes.poster} />
									</a>
								</div>
							);
					  })
					: null}
			</Grid>
		</Grid>
	);
}
