import React from 'react';
import { get } from 'lodash';
import { Grid, makeStyles, Card, CardContent, Typography } from '@material-ui/core';
import { ITVItem, ITVItemGame, ITVItemMovie, ITVItemSeries } from './TVInterface';

interface TVSummaryProps {
	item?: ITVItem;
}

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		height: '150px'
	},
	image: {
		minWidth: '266px',
		height: '150px'
	}
}));

function renderMetadata(item: ITVItem) {
	const { title, synopsis } = item;
	return (
		<Card style={{ minHeight: '150px', marginRight: '20px' }}>
			<CardContent>
				<Typography variant="h5" gutterBottom>
					{title}
				</Typography>
				<Typography component="div">
					{(() => {
						switch (item.type) {
							case 'movie':
								return renderMovieMetadata(item);
							case 'series':
								return renderSeriesMetadata(item);
							case 'game':
								return renderGameMetadata(item);
						}
					})()}
				</Typography>
				{synopsis ? (
					<Typography variant="body2" component="p">
						{synopsis}
					</Typography>
				) : null}
			</CardContent>
		</Card>
	);
}

function renderMovieMetadata(item: ITVItem) {
	const { releaseYear, hasFreeSource, duration, rating } = item as ITVItemMovie;
	const mins = Number(duration.value) / 60;
	const durationText = mins > 60 ? `${Math.floor(mins / 60)}h ${mins % 60}m` : `${mins}m`;

	return (
		<Typography variant="h6">
			<span>{releaseYear}&nbsp;&nbsp;&nbsp;</span>
			<span>{durationText}&nbsp;&nbsp;&nbsp;</span>
			{hasFreeSource ? <span>FREE&nbsp;&nbsp;&nbsp;</span> : null}
			{rating.content ? <span>{rating.content}</span> : null}
		</Typography>
	);
}

function renderSeriesMetadata(item: ITVItem) {
	const { releaseYear, hasFreeSource, totalSeasons } = item as ITVItemSeries;
	return (
		<Typography variant="h6">
			<span>{releaseYear}&nbsp;&nbsp;&nbsp;</span>
			<span>{`${totalSeasons} season(s)`}&nbsp;&nbsp;&nbsp;</span>
			{hasFreeSource ? <span>FREE&nbsp;&nbsp;&nbsp;</span> : null}
		</Typography>
	);
}

function renderGameMetadata(item: ITVItem) {
	const { releaseYear, genres, rating } = item as ITVItemGame;
	return (
		<Typography variant="h6">
			<span>{releaseYear}&nbsp;&nbsp;&nbsp;</span>
			<span>{`${rating.esrb.code}/${rating.pegi.code}`}&nbsp;&nbsp;&nbsp;</span>
			<span>{genres.join(', ')}</span>
		</Typography>
	);
}

export default function TVSummary(props: TVSummaryProps) {
	const classes = useStyles();

	const { item } = props;
	if (!item) return null;

	const { media } = item;
	const backgroundImageUrl = get(media, 'backdrops[0].url');

	return (
		<Grid container>
			<Grid item xs>
				{renderMetadata(item)}
			</Grid>
			{backgroundImageUrl ? (
				<Grid item key={`summary-${item.id}`} className={classes.imageContainer}>
					<a target="_blank" rel="noopener noreferrer" href={backgroundImageUrl}>
						<img alt="img" src={backgroundImageUrl} className={classes.image} />
					</a>
				</Grid>
			) : null}
		</Grid>
	);
}
