import { Grid, LinearProgress, makeStyles, Paper } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { uniqBy, get, find, filter } from 'lodash';
import { fetchBlacklist, fetchV1Recommendation, fetchYoutubeChannelBlacklist, pushBlacklist, pushYoutubeChannelBlacklist } from '../../api';
import BlacklistSelectOption from './BlacklistSelectOption';
import BlacklistSection from './BlacklistSection';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlacklistVideos from './BlacklistVideos';
import BlacklistChannels from './BlacklistChannels';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2)
	},
	searchText: {
		width: '200px',
		marginRight: '10px'
	},
	searchContainer: {
		marginBottom: '30px'
	},
	progress: {
		marginTop: theme.spacing(1),
		height: '4px'
	},
	button: {
		width: '150px',
		marginLeft: '20px'
	},
	blacklistText: {
		width: '100%'
	}
}));

export interface IYoutubeImage {
	url: string;
	width: number;
	height: number;
}
export interface IYoutubeVideo {
	id: string;
	name: string;
	weblink: string;
	image: {
		default: IYoutubeImage;
		medium: IYoutubeImage;
		high: IYoutubeImage;
		standard: IYoutubeImage;
	};
}

export function Blacklist() {
	const classes = useStyles();
	const [regionCode, setRegionCode] = useState('BD');
	const [env, setCurrentENV] = useState(3);
	const [channelId, setChannelId] = useState('');
	const [isFetching, setIsFetching] = useState(false);
	const [isPushing, setIsPushing] = useState(false);

	const movieRef = useRef<IYoutubeVideo[]>([]);
	const musicRef = useRef<IYoutubeVideo[]>([]);
	const tvRef = useRef<IYoutubeVideo[]>([]);
	const blacklistVideosRef = useRef<{ [id: string]: string }[]>([]);
	const blacklistChannelsRef = useRef<{ [channelId: string]: string }[]>([]);

	const updateOption = async function (
		key: 'env' | 'regionCode' | 'refreshCache' | 'clearVideoBlacklist' | 'channelId' | 'clearChannelBlacklist' | 'blacklistChannel',
		value: string | number
	) {
		switch (key) {
			case 'env':
				setCurrentENV(Number(value));
				break;
			case 'regionCode':
				setRegionCode(String(value));
				break;
			case 'refreshCache':
				runEffect(true);
				break;
			case 'clearVideoBlacklist':
				await clearVideoBlacklist();
				runEffect(true);
				break;
			case 'channelId':
				setChannelId(String(value).trim());
				break;
			case 'blacklistChannel':
				if (channelId) {
					await blacklistChannel();
				}
				break;
			case 'clearChannelBlacklist':
				await clearChannelBlacklist();
				runEffect(true);
				break;
		}
	};

	const fetchScopeItems = (data: any, scope: String) => {
		return (
			get(
				find(
					get(
						find(data, (item) => item.scope === scope),
						'thirdPartyResults'
					),
					(item) => item.source === 'youtube'
				),
				'results'
			) || []
		);
	};

	async function runEffect(forceRefresh = false) {
		if (isFetching) return;

		setIsFetching(true);
		try {
			const res = await fetchV1Recommendation(regionCode, env, forceRefresh);
			if (res.status === 200) {
				const data = await res.json();
				movieRef.current = fetchScopeItems(data, 'movie');
				musicRef.current = fetchScopeItems(data, 'music');
				tvRef.current = fetchScopeItems(data, 'tv');

				// Fetch latest blacklist videos and channel
				try {
					const blacklist = await Promise.all([fetchBlacklist(regionCode, env), fetchYoutubeChannelBlacklist(regionCode, env)]);
					blacklistVideosRef.current = filter((await blacklist[0].json()).blacklist, (item) => item.id.length === 11);
					blacklistChannelsRef.current = (await blacklist[1].json()).blacklist;
				} catch (error) {
					console.log(error);
				}
				setIsFetching(false);
			} else {
				setIsFetching(false);
			}
		} catch (error) {
			console.log(error);
			setIsFetching(false);
		}
	}

	useEffect(() => {
		runEffect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [env, regionCode]);

	const addToBlacklist = useCallback(
		async (ids: string[]) => {
			if (isPushing) return;
			const toastId = toast.loading('Pushing blacklist...');

			setIsPushing(true);
			try {
				var res = await fetchBlacklist(regionCode, env);
				if (res.status === 200) {
					var { blacklist } = await res.json();
					blacklist = [
						...blacklist,
						...ids.map((id) => {
							return { id };
						})
					];

					blacklist = uniqBy(blacklist, 'id');
					console.log(blacklist);
					await pushBlacklist(regionCode, blacklist, env);

					setIsPushing(false);

					await runEffect(true);
				} else {
					setIsPushing(false);
				}
				toast.dismiss(toastId);
			} catch (error) {
				console.log(error);
				setIsPushing(false);
				toast.dismiss(toastId);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[regionCode, isPushing, env, setIsPushing]
	);

	const clearVideoBlacklist = async () => {
		if (isPushing) return;

		setIsPushing(true);
		try {
			await pushBlacklist(regionCode, [], env);
			setIsPushing(false);
		} catch (error) {
			console.log(error);
			setIsPushing(false);
		}
	};

	const blacklistChannel = async () => {
		if (isPushing || !channelId) return;

		setIsPushing(true);
		try {
			var res = await fetchYoutubeChannelBlacklist(regionCode, env);
			if (res.status === 200) {
				var { blacklist } = await res.json();
				blacklist = [
					...blacklist,
					{
						channelId
					}
				];

				blacklist = uniqBy(blacklist, 'channelId');
				console.log(blacklist);
				await pushYoutubeChannelBlacklist(regionCode, blacklist, env);

				setIsPushing(false);
				setChannelId('');
				runEffect(true);
			} else {
				setIsPushing(false);
			}
		} catch (error) {
			console.log(error);
			setIsPushing(false);
		}
	};

	const clearChannelBlacklist = async () => {
		if (isPushing) return;

		setIsPushing(true);
		try {
			await pushYoutubeChannelBlacklist(regionCode, [], env);
			setIsPushing(false);
		} catch (error) {
			console.log(error);
			setIsPushing(false);
		}
	};

	return (
		<Paper className={classes.paper}>
			<div className={classes.searchContainer}>
				<BlacklistSelectOption isLoading={isFetching || isPushing} defaultOptions={{ env, regionCode, channelId }} updateOption={updateOption} />
			</div>

			{/** Loading progress */}
			{isFetching || isPushing ? <LinearProgress className={classes.progress} /> : <div className={classes.progress} />}

			<Grid>
				{/** Music */}
				{musicRef.current.length > 0 ? <BlacklistSection type="Music" items={musicRef.current} addToBlacklist={addToBlacklist} /> : null}

				{/** Movie */}
				{movieRef.current.length > 0 ? <BlacklistSection type="Movie" items={movieRef.current} addToBlacklist={addToBlacklist} /> : null}

				{/** TV */}
				{tvRef.current.length > 0 ? <BlacklistSection type="TV" items={tvRef.current} addToBlacklist={addToBlacklist} /> : null}

				{/** Blacklist Videos */}
				{blacklistVideosRef.current.length > 0 ? <BlacklistVideos items={blacklistVideosRef.current} /> : null}

				{/** Blacklist channels */}
				{blacklistChannelsRef.current.length > 0 ? <BlacklistChannels items={blacklistChannelsRef.current} /> : null}
			</Grid>
			{/* <ToastContainer hideProgressBar={true} autoClose={false} closeButton={true} /> */}
			<ToastContainer theme="dark" />
		</Paper>
	);
}
