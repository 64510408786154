import React, { useCallback } from 'react';
import { StatisticItemEntity } from './CatalogOverview';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Table, TableHead, TableRow, TableCell, TableBody, TablePagination, TextField, ButtonGroup, Button } from '@material-ui/core';
import moment from 'moment';
import { PAGE_SIZE } from './CatalogCrawlers';

export interface CatalogItem {
	id: number;
	countryCode: string;
	contentSource: string;
	crawlSource: string;
	tmdbId: number;
	status: string;
	contentUrl: string;
	crawlUrl: string;
	title: string;
	originalTitle: string;
	posterUrl: string;
	description: string;
	releaseYear: number;
	durationMin?: number; //for movie only
	casts: string;
	crews: string;
	extra: string;
	offers: CatalogOffer[];
	created: string;
	lastUpdated: string;
}

interface CatalogOffer {
	audioLanguage: string;
	contentSource: string;
	contentUrl: string;
	deeplinkUrl: string;
	countryCode: string;
	crawlSource: string;
	crawlUrl: string;
	currency: string;
	id: number;
	price: number;
	type: string;
	videoQuality: string;
}
interface CatalogListProps {
	searchText: string;
	orderFlag: number;
	catalogItems: CatalogItem[];
	totalCatalogCount: number;
	currentPage: number;
	statisticsItem: StatisticItemEntity;
	updateSearchText: (searchText: string) => void;
	updateOrderFlag: (orderFlag: number) => void;
	startSearch: () => void;
	backToHome: (country: string) => void;
	updatePage: (newPage: number) => void;
	editCatalogItem: (catalogItem: CatalogItem) => void;
}

const useStyle = makeStyles((theme) => ({
	container: {
		paddingBottom: theme.spacing(1),
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end'
	},
	homeText: {
		fontWeight: 700,
		fontSize: '1.2rem',
		color: 'blue'
	},
	searchText: {
		minWidth: '300px'
	},
	underlineText: {
		textDecoration: 'underline',
		fontWeight: 400,
		fontSize: '1rem'
	},
	hover: {
		'&:hover': {
			cursor: 'pointer'
		}
	},
	editText: {
		color: 'blue'
	},
	table: {
		tableLayout: 'fixed',
		border: '2px solid grey'
	},
	cell: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	poster: {
		height: '100px'
	}
}));

export default function CatalogList(props: CatalogListProps) {
	const classes = useStyle();
	const {
		searchText,
		orderFlag,
		catalogItems,
		totalCatalogCount,
		currentPage,
		statisticsItem,
		updateSearchText,
		updateOrderFlag,
		startSearch,
		backToHome,
		updatePage,
		editCatalogItem
	} = props;

	const editPressed = useCallback(
		(catalogItem: CatalogItem) => {
			editCatalogItem(catalogItem);
		},
		[editCatalogItem]
	);

	const openAllUrls = () => {
		catalogItems.forEach(({ contentUrl }) => {
			window.open(contentUrl, '_blank');
		});
	};

	return (
		<React.Fragment>
			<div className={classes.container}>
				<div>
					<span className={clsx(classes.underlineText, classes.homeText, classes.hover)} onClick={() => backToHome(statisticsItem.country)}>
						HOME
					</span>
					:&nbsp;You are viewing &nbsp;
					<span className={classes.underlineText}>{statisticsItem.type}</span> data from &nbsp;
					<span className={classes.underlineText}>{statisticsItem.source}</span> in &nbsp;
					<span className={classes.underlineText}>{statisticsItem.country}</span> with the status &nbsp;
					<span className={classes.underlineText}>{statisticsItem.status.toLocaleUpperCase()}</span>
				</div>
				<div>
					<ButtonGroup size="small" aria-label="Order label">
						<Button color={orderFlag === 0 ? 'secondary' : 'default'} onClick={() => updateOrderFlag(0)}>
							ID(⬆)
						</Button>
						<Button color={orderFlag === 1 ? 'secondary' : 'default'} onClick={() => updateOrderFlag(1)}>
							ID(⬇)
						</Button>
						<Button color={orderFlag === 2 ? 'secondary' : 'default'} onClick={() => updateOrderFlag(2)}>
							LAST(⬆)
						</Button>
						<Button color={orderFlag === 3 ? 'secondary' : 'default'} onClick={() => updateOrderFlag(3)}>
							LAST(⬇)
						</Button>
					</ButtonGroup>
				</div>
				<div>
					<TextField
						className={classes.searchText}
						margin="none"
						id="search"
						name="search"
						placeholder="Key in and Press 'Return' to search"
						value={searchText}
						onChange={(event) => updateSearchText(event.target.value)}
						onKeyDown={(event) => (event.keyCode === 13 ? startSearch() : null)}
					/>
				</div>
			</div>
			{catalogItems.length > 0 && statisticsItem ? (
				<React.Fragment>
					<Table className={classes.table} size="small">
						<TableHead>
							<TableRow>
								<TableCell style={{ width: '5%' }}>Id</TableCell>
								<TableCell style={{ width: '12%' }}>Title</TableCell>
								<TableCell style={{ width: '7%' }}>Poster</TableCell>
								<TableCell style={{ width: '5%' }}>Release Year</TableCell>
								<TableCell style={{ width: '20%' }}>
									Url
									<span>&nbsp;</span>
									<span className={clsx(classes.editText, classes.hover)} onClick={() => openAllUrls()}>
										(Open All)
									</span>
								</TableCell>
								<TableCell style={{ width: '35%' }}>Description</TableCell>
								<TableCell style={{ width: '10%' }}>Last Updated</TableCell>
								<TableCell style={{ width: '5%' }}>action</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{catalogItems.map((catalogItem) => (
								<TableRow key={`${catalogItem.id}-${statisticsItem.type}`} onClick={() => editPressed(catalogItem)}>
									<TableCell>{catalogItem.id}</TableCell>
									<TableCell>{catalogItem.title}</TableCell>
									<TableCell className={classes.cell}>
										<a target="_blank" rel="noopener noreferrer" href={catalogItem.posterUrl} onClick={(e) => e.stopPropagation()}>
											<img src={catalogItem.posterUrl} alt={'img'} className={classes.poster} />
										</a>
									</TableCell>
									<TableCell className={classes.cell}>{catalogItem.releaseYear}</TableCell>
									<TableCell className={classes.cell}>
										<a target="_blank" rel="noopener noreferrer" href={catalogItem.contentUrl} onClick={(e) => e.stopPropagation()}>
											{catalogItem.contentUrl}
										</a>
									</TableCell>
									<TableCell>{catalogItem.description}</TableCell>
									<TableCell>{moment(catalogItem.lastUpdated).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
									<TableCell className={classes.cell}>
										<span className={clsx(classes.underlineText, classes.editText, classes.hover)}>Edit</span>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<TablePagination
						rowsPerPageOptions={[PAGE_SIZE]}
						component="div"
						count={totalCatalogCount}
						rowsPerPage={PAGE_SIZE}
						page={totalCatalogCount > 0 ? currentPage - 1 : 0}
						onPageChange={(_, newPage: number) => {
							updatePage(newPage + 1);
						}}
					/>
				</React.Fragment>
			) : null}
		</React.Fragment>
	);
}
