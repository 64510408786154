import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Container, Grid, Paper, LinearProgress, Button } from '@material-ui/core';
import { get } from 'lodash';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ReactJson from 'react-json-view';
import { fetchRecMenu, fetchRecMenuData, fetchRecVersions, textToFulfillment } from '../../api';
import SelectOption from './SelectOption';
import { ENV_LIST, REGION_LIST, MENU_HOME, SDK_VERSION_LIST, MENU_SEARCH, SEARCH_SDK_VERSION_LIST, CLIENT_LIST } from '../../utils/Const';
import TVMenu from './TVMenu';
import TVSummary from './TVSummary';
import TVSection from './TVSection';
import { ITVItem, ITVItemGame, ITVItemSeries, ITVItemMovie } from './TVInterface';
import MovieDetail from './detailPage/MovieDetail';
import SeriesDetail from './detailPage/SeriesDetail';
import GameDetail from './detailPage/GameDetail';
import SearchView from './SearchView';
import { filterInterpretations, formatInterpretations } from '../../utils/util';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(1)
	},
	paper: {
		marginBottom: theme.spacing(1),
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column'
	},
	progress: {
		marginTop: theme.spacing(1),
		height: '4px'
	},
	sectionContainer: {
		maxHeight: 'calc(100vh - 380px)',
		overflowY: 'auto',
		margin: '10px',
		outline: '1px dashed rgb(200,200,200)'
	}
}));

export default function PreviewTV() {
	const classes = useStyles();

	const menuRef = useRef([]);
	const menuDataRef = useRef([]);
	const refreshCacheRef = useRef(false);
	const isEntertainmentDomainRef = useRef(true);
	const scrollYPosition = useRef(0);
	const scrollXPositions = useRef<number[]>([]);

	const [isLoadingMenu, setIsLoadingMenu] = useState(false);
	const [isLoadingMenuDta, setIsLoadingMenuData] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [versions, setVersions] = useState<{ [_: string]: string }>({});
	const [env, setCurrentENV] = useState(ENV_LIST.find((option) => option.isDefault)?.value || 1);
	const [client, setCurrentClient] = useState(CLIENT_LIST.find((option) => option.isDefault)?.value || 'CVTE');
	const [region, setCurrentRegion] = useState(REGION_LIST.find((option) => option.isDefault)?.value || 'IN');
	const [language, setCurrentLanguage] = useState(REGION_LIST.find((option) => option.isDefault)?.languageCode || 'en');
	const [sdkVersion, setCurrentSdkVersion] = useState(SDK_VERSION_LIST.find((option) => option.isDefault)?.value || 10000);
	const [searchSDKVersion, setCurrentSearchSDKVersion] = useState(SEARCH_SDK_VERSION_LIST.find((option) => option.isDefault)?.value || '2.0.1');
	const [searchQuery, setSearchQuery] = useState('');
	const [forceRefresh, setForceRefresh] = useState(false);
	const [currentMenu, setCurrentMenu] = useState(MENU_HOME.type);
	const [currentFocusedItem, setCurrentFocusedItem] = useState<ITVItem>();
	const [currentSelectedItem, setCurrentSelectedItem] = useState<ITVItem>();

	// fetch rec versions
	useEffect(() => {
		async function effect() {
			if (Object.keys(versions).length > 0 || isLoadingMenu) return;
			try {
				const res = await fetchRecVersions();
				if (res.status === 200) {
					const versions = await res.json();
					setVersions(versions);
				}
			} catch (error) {
				console.log(error);
			}
		}
		effect();
	});

	// fetch menu list
	useEffect(() => {
		async function effect() {
			setIsLoadingMenu(true);
			try {
				const res = await fetchRecMenu({
					serverType: env,
					client,
					regionCode: region,
					sdkVersion,
					languageCode: language,
					refreshCache: refreshCacheRef.current
				});
				const { status } = res;
				if (status === 200) {
					const { menus } = await res.json();
					if (menus) {
						menus.splice(0, 0, MENU_HOME);
						menus.splice(0, 0, MENU_SEARCH);
						menuRef.current = menus;
					}
				}
				refreshCacheRef.current = false;
				setIsLoadingMenu(false);
			} catch (error) {
				console.log(error);
				refreshCacheRef.current = false;
				setIsLoadingMenu(false);
			}
		}
		effect();
	}, [env, client, region, language, sdkVersion, forceRefresh]);

	// fetch menu data
	useEffect(() => {
		async function effect() {
			menuDataRef.current = [];
			isEntertainmentDomainRef.current = true;
			setCurrentFocusedItem(undefined);
			setCurrentSelectedItem(undefined);
			if (currentMenu === 'search') return;

			setIsLoadingMenuData(true);
			try {
				const res = await fetchRecMenuData({
					serverType: env,
					client,
					regionCode: region,
					sdkVersion,
					languageCode: language,
					refreshCache: refreshCacheRef.current,
					menuType: currentMenu
				});
				const { status } = res;
				if (status === 200) {
					const { sections } = await res.json();
					if (sections) {
						menuDataRef.current = sections;
						setCurrentFocusedItem(get(menuDataRef.current, '[0].items[0]'));
					}
				}
				refreshCacheRef.current = false;
				setIsLoadingMenuData(false);
			} catch (error) {
				console.log(error);
				refreshCacheRef.current = false;
				setIsLoadingMenuData(false);
			}
		}
		effect();
	}, [env, client, region, language, sdkVersion, forceRefresh, currentMenu]);

	const updateOption = function (
		key: 'env' | 'client' | 'region' | 'language' | 'sdkVersion' | 'refreshCache' | 'searchSDKVersion' | 'query',
		value: string | number
	) {
		switch (key) {
			case 'env':
				setCurrentENV(Number(value));
				if (value > 1 && client === 'CVTE_TEST') {
					setCurrentClient(CLIENT_LIST.find((option) => option.isDefault)?.value || 'CVTE');
				}
				break;
			case 'client':
				setCurrentClient(String(value));
				break;
			case 'region':
				setCurrentRegion(String(value));
				// Force to use default language for a specific region
				setCurrentLanguage(REGION_LIST.find((option) => option.value === String(value))?.languageCode || 'en');
				break;
			case 'language':
				setCurrentLanguage(String(value));
				break;
			case 'sdkVersion':
				setCurrentSdkVersion(Number(value));
				break;
			case 'refreshCache':
				refreshCacheRef.current = true;
				setForceRefresh((force) => !force);
				break;
			case 'searchSDKVersion':
				setCurrentSearchSDKVersion(String(value));
				break;
			case 'query':
				setSearchQuery(String(value));
				break;
		}
	};

	// ttf
	const startSearch = async function () {
		menuDataRef.current = [];
		isEntertainmentDomainRef.current = true;
		setIsSearching(true);
		setCurrentFocusedItem(undefined);
		setCurrentSelectedItem(undefined);

		try {
			const res = await textToFulfillment({
				serverType: env,
				client,
				regionCode: region,
				sdkVersion,
				languageCode: language,
				refreshCache: refreshCacheRef.current,
				searchSDKVersion,
				query: searchQuery
			});
			const { status } = res;
			if (status === 200) {
				const { interpretations } = await res.json();
				if (interpretations && interpretations.length) {
					const { interpretations: filteredInterpretations, isEntertainmentDomain } = filterInterpretations(interpretations, searchSDKVersion);
					isEntertainmentDomainRef.current = isEntertainmentDomain;
					if (isEntertainmentDomain) {
						const sections = formatInterpretations(filteredInterpretations);
						menuDataRef.current = sections as never[];
						setCurrentFocusedItem(get(menuDataRef.current, '[0].items[0]'));
					} else {
						menuDataRef.current = filteredInterpretations as never[];
					}
				}
			}
			setIsSearching(false);
		} catch (error) {
			console.log(error);
			setIsSearching(false);
		}
	};

	const renderTVMainPage = function () {
		return (
			<React.Fragment>
				{/** Item Summary */}
				<Grid item xs={12} style={{ padding: '10px' }}>
					<TVSummary item={currentFocusedItem} />
				</Grid>
				{/** Item List */}
				<Grid
					item
					xs={12}
					id="sectionContainer"
					className={classes.sectionContainer}
					style={{ padding: '10px', display: menuDataRef.current.length ? 'block' : 'none' }}
				>
					{!isEntertainmentDomainRef.current ? (
						<ReactJson name={false} displayDataTypes={false} src={menuDataRef.current || {}} />
					) : menuDataRef.current.length > 0 ? (
						menuDataRef.current.map((section, index) => (
							<TVSection
								key={index}
								menu={currentMenu}
								section={section}
								updateCurrentFocusedItem={(item) => setCurrentFocusedItem(item)}
								updateCurrentSelectedItem={(item) => {
									console.log(item);
									if (item.type.toLocaleLowerCase() !== 'music' && item.type.toLocaleLowerCase() !== 'video') {
										const sectionContainer = document.getElementById('sectionContainer');
										if (sectionContainer) {
											scrollYPosition.current = sectionContainer.scrollTop;
											scrollXPositions.current = Array.prototype.slice.call(sectionContainer.children).map((child) => child.children[1].scrollLeft as number);
										}
										setCurrentSelectedItem(item);
									}
								}}
							/>
						))
					) : null}
				</Grid>
			</React.Fragment>
		);
	};

	const renderTVDetailPage = function (item: ITVItem) {
		return (
			<Grid item xs={12}>
				<Button
					size="large"
					color="primary"
					startIcon={<ChevronLeftIcon />}
					onClick={() => {
						setCurrentSelectedItem(undefined);

						setTimeout(() => {
							const sectionContainer = document.getElementById('sectionContainer');
							if (sectionContainer) {
								if (scrollYPosition.current > 0) {
									sectionContainer.scrollTo(0, scrollYPosition.current);
								}
								Array.prototype.slice.call(sectionContainer.children).forEach((child, i) => child.children[1].scrollTo(scrollXPositions.current[i], 0));
							}
						}, 50);
					}}
				>
					{`${item.title} (${item.type.toUpperCase()})`}
				</Button>
				{(() => {
					switch (item.type) {
						case 'movie':
							return (
								<MovieDetail
									headers={{
										serverType: env,
										client,
										regionCode: region,
										sdkVersion,
										languageCode: language,
										refreshCache: refreshCacheRef.current
									}}
									updateCurrentSelectedItem={(item) => {
										console.log(item);
										if (item.type.toLocaleLowerCase() !== 'music' && item.type.toLocaleLowerCase() !== 'video') {
											setCurrentSelectedItem(item);
										}
									}}
									item={currentSelectedItem as ITVItemMovie}
								/>
							);
						case 'series':
							return (
								<SeriesDetail
									headers={{
										serverType: env,
										client,
										regionCode: region,
										sdkVersion,
										languageCode: language,
										refreshCache: refreshCacheRef.current
									}}
									updateCurrentSelectedItem={(item) => {
										console.log(item);
										if (item.type.toLocaleLowerCase() !== 'music' && item.type.toLocaleLowerCase() !== 'video') {
											setCurrentSelectedItem(item);
										}
									}}
									item={currentSelectedItem as ITVItemSeries}
								/>
							);
						case 'game':
							return (
								<GameDetail
									headers={{
										serverType: env,
										client,
										regionCode: region,
										sdkVersion,
										languageCode: language,
										refreshCache: refreshCacheRef.current
									}}
									item={currentSelectedItem as ITVItemGame}
								/>
							);
					}
				})()}
			</Grid>
		);
	};

	const renderSearchView = function () {
		return (
			<SearchView isLoading={isSearching} defaultOptions={{ searchSDKVersion, query: searchQuery }} updateOption={updateOption} startSearch={startSearch} />
		);
	};

	return (
		<React.Fragment>
			<Container maxWidth="xl" className={classes.container}>
				<Paper className={classes.paper}>
					{/** Selection Header */}
					<SelectOption
						isLoading={isLoadingMenu || isLoadingMenuDta || currentMenu === 'search'}
						defaultOptions={{ env, client, region, language, sdkVersion }}
						versions={versions}
						updateOption={updateOption}
					/>
					{/** Loading progress */}
					{isLoadingMenu || isLoadingMenuDta || isSearching ? <LinearProgress className={classes.progress} /> : <div className={classes.progress} />}

					{/** TV Launcher */}
					{menuRef.current.length ? (
						<Grid container style={{ border: '1px groove lightgrey' }}>
							{/** TV Menu */}
							<Grid item style={{ width: '130px' }}>
								<TVMenu
									currentMenu={currentMenu}
									menus={menuRef.current}
									updateCurrentMenu={(menu: string) => {
										setCurrentMenu(menu);
										document.getElementById('sectionContainer')?.scrollTo(0, 0);
									}}
								/>
							</Grid>
							{/** TV Main Page or  Detail Page*/}
							<Grid container item style={{ width: 'calc(100% - 130px)', margin: 0 }}>
								{currentMenu === 'search' ? renderSearchView() : null}
								{!currentSelectedItem ? renderTVMainPage() : renderTVDetailPage(currentSelectedItem)}
							</Grid>
						</Grid>
					) : null}
				</Paper>
			</Container>
		</React.Fragment>
	);
}
