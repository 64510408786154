import React, { useEffect, useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { fetchYoutubeChannelInfo } from '../../api';

interface BlacklistChannelProps {
	channelId: string;
}
interface BlacklistChannelsProps {
	items: { [channelId: string]: string }[];
}

const useStyles = makeStyles(() => ({
	container: {
		// overflowX: 'auto'
		flexWrap: 'wrap'
	},
	itemContainer: {
		margin: '10px',
		padding: '10px',
		width: '250px',
		backgroundColor: 'lightblue'
	},
	title: {
		width: '100%',
		height: '20px',
		overflowX: 'hidden',
		marginBottom: '5px'
	},
	infoContainer: {
		marginTop: '3px',
		display: 'flex',
		alignItems: 'center'
	},
	image: {
		width: '50px',
		height: '50px',
		marginRight: '10px'
	}
}));

interface IYoutubeChannel {
	title: string;
	image: string;
	description: string;
}

function BlacklistChannel(props: BlacklistChannelProps) {
	const classes = useStyles();
	const { channelId } = props;
	const channelLink = `https://www.youtube.com/channel/${channelId}`;
	const [info, setInfo] = useState<IYoutubeChannel>();

	useEffect(() => {
		if (info?.title) return;
		async function runEffect() {
			try {
				const res = await fetchYoutubeChannelInfo(channelId);
				setInfo(await res.json());
			} catch (error) {}
		}

		runEffect();
	}, []);

	return (
		<div className={classes.itemContainer} key={channelId}>
			<div>
				<a target="_blank" rel="noopener noreferrer" href={channelLink} className={classes.title}>
					{channelId}
				</a>
			</div>
			<div className={classes.infoContainer}>
				<img className={classes.image} src={info?.image} />
				<a target="_blank" rel="noopener noreferrer" href={channelLink} className={classes.title}>
					{info?.title}
				</a>
			</div>
		</div>
	);
}

export default function BlacklistChannels(props: BlacklistChannelsProps) {
	const classes = useStyles();
	const { items } = props;

	return (
		<Grid style={{ border: '1px dashed lightgrey', padding: '10px', marginBottom: '10px' }}>
			<Typography variant="h5" color="textSecondary">
				{'Blacklist Channels'}
			</Typography>
			<Grid container wrap="nowrap" className={classes.container} alignItems="center">
				{items
					? items.map((item, index) => {
							return <BlacklistChannel channelId={item.channelId} key={index} />;
					  })
					: null}
			</Grid>
		</Grid>
	);
}
