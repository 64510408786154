// Preview TV Options
export const ENV_LIST = [
	{
		name: 'Staging',
		value: 1,
		isDefault: true,
		env: 'next'
	},
	{
		name: 'CVTE UAT',
		value: 2,
		isDefault: false,
		env: 'uat'
	},
	{
		name: 'CVTE Prod',
		value: 3,
		isDefault: false,
		env: 'entertainment'
	}
];

export const REGION_LIST = [
	{ name: 'India', value: 'IN', isDefault: true, languageCode: 'en' },
	{ name: 'Spain', value: 'ES', isDefault: false, languageCode: 'es' },
	{ name: 'Mexico', value: 'MX', isDefault: false, languageCode: 'es' },
	{ name: 'Saudi Arabia', value: 'SA', isDefault: false, languageCode: 'ar' },
	{ name: 'UAE', value: 'AE', isDefault: false, languageCode: 'ar' },
	{ name: 'Egypt', value: 'EG', isDefault: false, languageCode: 'ar' },
	{ name: 'Israel', value: 'IL', isDefault: false, languageCode: 'he' },
	{ name: 'Vietnam', value: 'VN', isDefault: false, languageCode: 'vi' },
	{ name: 'Bangladesh', value: 'BD', isDefault: false, languageCode: 'bn' },
	{ name: 'Chile', value: 'CL', isDefault: false, languageCode: 'es' },
	{ name: 'Venezuela', value: 'VE', isDefault: false, languageCode: 'es' },
	{ name: 'Indonesia', value: 'ID', isDefault: false, languageCode: 'id' },
	{ name: 'Philippines', value: 'PH', isDefault: false, languageCode: 'en' },
	{ name: 'Thailand', value: 'TH', isDefault: false, languageCode: 'th' },
	{ name: 'Colombia', value: 'CO', isDefault: false, languageCode: 'es' },
	{ name: 'Peru', value: 'PE', isDefault: false, languageCode: 'es' },
	{ name: 'Malaysia', value: 'MY', isDefault: false, languageCode: 'ms' },
	{ name: 'Turkey', value: 'TR', isDefault: false, languageCode: 'tr' },
	{ name: 'Germany', value: 'DE', isDefault: false, languageCode: 'de' },
	{ name: 'Italy', value: 'IT', isDefault: false, languageCode: 'it' },
	{ name: 'France', value: 'FR', isDefault: false, languageCode: 'fr' },
	{ name: 'Afghanistan', value: 'AF', isDefault: false, languageCode: 'en' },
	{ name: 'Albania', value: 'AL', isDefault: false, languageCode: 'en' },
	{ name: 'Algeria', value: 'DZ', isDefault: false, languageCode: 'en' },
	{ name: 'American Samoa', value: 'AS', isDefault: false, languageCode: 'en' },
	{ name: 'Andorra', value: 'AD', isDefault: false, languageCode: 'en' },
	{ name: 'Angola', value: 'AO', isDefault: false, languageCode: 'en' },
	{ name: 'Anguilla', value: 'AI', isDefault: false, languageCode: 'en' },
	{ name: 'Antarctica', value: 'AQ', isDefault: false, languageCode: 'en' },
	{ name: 'Antigua and Barbuda', value: 'AG', isDefault: false, languageCode: 'en' },
	{ name: 'Argentina', value: 'AR', isDefault: false, languageCode: 'en' },
	{ name: 'Armenia', value: 'AM', isDefault: false, languageCode: 'en' },
	{ name: 'Aruba', value: 'AW', isDefault: false, languageCode: 'en' },
	{ name: 'Australia', value: 'AU', isDefault: false, languageCode: 'en' },
	{ name: 'Austria', value: 'AT', isDefault: false, languageCode: 'en' },
	{ name: 'Azerbaijan', value: 'AZ', isDefault: false, languageCode: 'en' },
	{ name: 'Bahamas', value: 'BS', isDefault: false, languageCode: 'en' },
	{ name: 'Bahrain', value: 'BH', isDefault: false, languageCode: 'en' },
	{ name: 'Barbados', value: 'BB', isDefault: false, languageCode: 'en' },
	{ name: 'Belarus', value: 'BY', isDefault: false, languageCode: 'en' },
	{ name: 'Belgium', value: 'BE', isDefault: false, languageCode: 'en' },
	{ name: 'Belize', value: 'BZ', isDefault: false, languageCode: 'en' },
	{ name: 'Benin', value: 'BJ', isDefault: false, languageCode: 'en' },
	{ name: 'Bermuda', value: 'BM', isDefault: false, languageCode: 'en' },
	{ name: 'Bhutan', value: 'BT', isDefault: false, languageCode: 'en' },
	{ name: 'Bolivia', value: 'BO', isDefault: false, languageCode: 'en' },
	{ name: 'Bosnia and Herzegovina', value: 'BA', isDefault: false, languageCode: 'en' },
	{ name: 'Botswana', value: 'BW', isDefault: false, languageCode: 'en' },
	{ name: 'Brazil', value: 'BR', isDefault: false, languageCode: 'en' },
	{ name: 'British Indian Ocean Territory', value: 'IO', isDefault: false, languageCode: 'en' },
	{ name: 'British Virgin Islands', value: 'VG', isDefault: false, languageCode: 'en' },
	{ name: 'Brunei', value: 'BN', isDefault: false, languageCode: 'en' },
	{ name: 'Bulgaria', value: 'BG', isDefault: false, languageCode: 'en' },
	{ name: 'Burkina Faso', value: 'BF', isDefault: false, languageCode: 'en' },
	{ name: 'Burundi', value: 'BI', isDefault: false, languageCode: 'en' },
	{ name: 'Cambodia', value: 'KH', isDefault: false, languageCode: 'en' },
	{ name: 'Cameroon', value: 'CM', isDefault: false, languageCode: 'en' },
	{ name: 'Canada', value: 'CA', isDefault: false, languageCode: 'en' },
	{ name: 'Cape Verde', value: 'CV', isDefault: false, languageCode: 'en' },
	{ name: 'Cayman Islands', value: 'KY', isDefault: false, languageCode: 'en' },
	{ name: 'Central African Republic', value: 'CF', isDefault: false, languageCode: 'en' },
	{ name: 'Chad', value: 'TD', isDefault: false, languageCode: 'en' },
	{ name: 'China', value: 'CN', isDefault: false, languageCode: 'zh' },
	{ name: 'Christmas Island', value: 'CX', isDefault: false, languageCode: 'en' },
	{ name: 'Cocos Islands', value: 'CC', isDefault: false, languageCode: 'en' },
	{ name: 'Comoros', value: 'KM', isDefault: false, languageCode: 'en' },
	{ name: 'Cook Islands', value: 'CK', isDefault: false, languageCode: 'en' },
	{ name: 'Costa Rica', value: 'CR', isDefault: false, languageCode: 'es' },
	{ name: 'Croatia', value: 'HR', isDefault: false, languageCode: 'hr' },
	{ name: 'Cuba', value: 'CU', isDefault: false, languageCode: 'en' },
	{ name: 'Curacao', value: 'CW', isDefault: false, languageCode: 'en' },
	{ name: 'Cyprus', value: 'CY', isDefault: false, languageCode: 'en' },
	{ name: 'Czech Republic', value: 'CZ', isDefault: false, languageCode: 'en' },
	{ name: 'Democratic Republic of the Congo', value: 'CD', isDefault: false, languageCode: 'en' },
	{ name: 'Denmark', value: 'DK', isDefault: false, languageCode: 'en' },
	{ name: 'Djibouti', value: 'DJ', isDefault: false, languageCode: 'en' },
	{ name: 'Dominica', value: 'DM', isDefault: false, languageCode: 'en' },
	{ name: 'Dominican Republic', value: 'DO', isDefault: false, languageCode: 'en' },
	{ name: 'East Timor', value: 'TL', isDefault: false, languageCode: 'en' },
	{ name: 'Ecuador', value: 'EC', isDefault: false, languageCode: 'en' },
	{ name: 'El Salvador', value: 'SV', isDefault: false, languageCode: 'en' },
	{ name: 'Equatorial Guinea', value: 'GQ', isDefault: false, languageCode: 'en' },
	{ name: 'Eritrea', value: 'ER', isDefault: false, languageCode: 'en' },
	{ name: 'Estonia', value: 'EE', isDefault: false, languageCode: 'en' },
	{ name: 'Ethiopia', value: 'ET', isDefault: false, languageCode: 'en' },
	{ name: 'Falkland Islands', value: 'FK', isDefault: false, languageCode: 'en' },
	{ name: 'Faroe Islands', value: 'FO', isDefault: false, languageCode: 'en' },
	{ name: 'Fiji', value: 'FJ', isDefault: false, languageCode: 'en' },
	{ name: 'Finland', value: 'FI', isDefault: false, languageCode: 'en' },
	{ name: 'French Polynesia', value: 'PF', isDefault: false, languageCode: 'en' },
	{ name: 'Gabon', value: 'GA', isDefault: false, languageCode: 'en' },
	{ name: 'Gambia', value: 'GM', isDefault: false, languageCode: 'en' },
	{ name: 'Georgia', value: 'GE', isDefault: false, languageCode: 'en' },
	{ name: 'Ghana', value: 'GH', isDefault: false, languageCode: 'en' },
	{ name: 'Gibraltar', value: 'GI', isDefault: false, languageCode: 'en' },
	{ name: 'Greece', value: 'GR', isDefault: false, languageCode: 'en' },
	{ name: 'Greenland', value: 'GL', isDefault: false, languageCode: 'en' },
	{ name: 'Grenada', value: 'GD', isDefault: false, languageCode: 'en' },
	{ name: 'Guam', value: 'GU', isDefault: false, languageCode: 'en' },
	{ name: 'Guatemala', value: 'GT', isDefault: false, languageCode: 'en' },
	{ name: 'Guernsey', value: 'GG', isDefault: false, languageCode: 'en' },
	{ name: 'Guinea', value: 'GN', isDefault: false, languageCode: 'en' },
	{ name: 'Guinea-Bissau', value: 'GW', isDefault: false, languageCode: 'en' },
	{ name: 'Guyana', value: 'GY', isDefault: false, languageCode: 'en' },
	{ name: 'Haiti', value: 'HT', isDefault: false, languageCode: 'en' },
	{ name: 'Honduras', value: 'HN', isDefault: false, languageCode: 'en' },
	{ name: 'Hong Kong', value: 'HK', isDefault: false, languageCode: 'en' },
	{ name: 'Hungary', value: 'HU', isDefault: false, languageCode: 'en' },
	{ name: 'Iceland', value: 'IS', isDefault: false, languageCode: 'en' },
	{ name: 'Iran', value: 'IR', isDefault: false, languageCode: 'en' },
	{ name: 'Iraq', value: 'IQ', isDefault: false, languageCode: 'en' },
	{ name: 'Ireland', value: 'IE', isDefault: false, languageCode: 'en' },
	{ name: 'Isle of Man', value: 'IM', isDefault: false, languageCode: 'en' },
	{ name: 'Ivory Coast', value: 'CI', isDefault: false, languageCode: 'en' },
	{ name: 'Jamaica', value: 'JM', isDefault: false, languageCode: 'en' },
	{ name: 'Japan', value: 'JP', isDefault: false, languageCode: 'jp' },
	{ name: 'Jersey', value: 'JE', isDefault: false, languageCode: 'en' },
	{ name: 'Jordan', value: 'JO', isDefault: false, languageCode: 'en' },
	{ name: 'Kazakhstan', value: 'KZ', isDefault: false, languageCode: 'en' },
	{ name: 'Kenya', value: 'KE', isDefault: false, languageCode: 'en' },
	{ name: 'Kiribati', value: 'KI', isDefault: false, languageCode: 'en' },
	{ name: 'Kosovo', value: 'XK', isDefault: false, languageCode: 'en' },
	{ name: 'Kuwait', value: 'KW', isDefault: false, languageCode: 'en' },
	{ name: 'Kyrgyzstan', value: 'KG', isDefault: false, languageCode: 'en' },
	{ name: 'Laos', value: 'LA', isDefault: false, languageCode: 'en' },
	{ name: 'Latvia', value: 'LV', isDefault: false, languageCode: 'en' },
	{ name: 'Lebanon', value: 'LB', isDefault: false, languageCode: 'en' },
	{ name: 'Lesotho', value: 'LS', isDefault: false, languageCode: 'en' },
	{ name: 'Liberia', value: 'LR', isDefault: false, languageCode: 'en' },
	{ name: 'Libya', value: 'LY', isDefault: false, languageCode: 'en' },
	{ name: 'Liechtenstein', value: 'LI', isDefault: false, languageCode: 'en' },
	{ name: 'Lithuania', value: 'LT', isDefault: false, languageCode: 'en' },
	{ name: 'Luxembourg', value: 'LU', isDefault: false, languageCode: 'en' },
	{ name: 'Macau', value: 'MO', isDefault: false, languageCode: 'en' },
	{ name: 'Macedonia', value: 'MK', isDefault: false, languageCode: 'en' },
	{ name: 'Madagascar', value: 'MG', isDefault: false, languageCode: 'en' },
	{ name: 'Malawi', value: 'MW', isDefault: false, languageCode: 'en' },
	{ name: 'Maldives', value: 'MV', isDefault: false, languageCode: 'en' },
	{ name: 'Mali', value: 'ML', isDefault: false, languageCode: 'en' },
	{ name: 'Malta', value: 'MT', isDefault: false, languageCode: 'en' },
	{ name: 'Marshall Islands', value: 'MH', isDefault: false, languageCode: 'en' },
	{ name: 'Mauritania', value: 'MR', isDefault: false, languageCode: 'en' },
	{ name: 'Mauritius', value: 'MU', isDefault: false, languageCode: 'en' },
	{ name: 'Mayotte', value: 'YT', isDefault: false, languageCode: 'en' },
	{ name: 'Micronesia', value: 'FM', isDefault: false, languageCode: 'en' },
	{ name: 'Moldova', value: 'MD', isDefault: false, languageCode: 'en' },
	{ name: 'Monaco', value: 'MC', isDefault: false, languageCode: 'en' },
	{ name: 'Mongolia', value: 'MN', isDefault: false, languageCode: 'en' },
	{ name: 'Montenegro', value: 'ME', isDefault: false, languageCode: 'en' },
	{ name: 'Montserrat', value: 'MS', isDefault: false, languageCode: 'en' },
	{ name: 'Morocco', value: 'MA', isDefault: false, languageCode: 'en' },
	{ name: 'Mozambique', value: 'MZ', isDefault: false, languageCode: 'en' },
	{ name: 'Myanmar', value: 'MM', isDefault: false, languageCode: 'en' },
	{ name: 'Namibia', value: 'NA', isDefault: false, languageCode: 'en' },
	{ name: 'Nauru', value: 'NR', isDefault: false, languageCode: 'en' },
	{ name: 'Nepal', value: 'NP', isDefault: false, languageCode: 'en' },
	{ name: 'Netherlands', value: 'NL', isDefault: false, languageCode: 'en' },
	{ name: 'Netherlands Antilles', value: 'AN', isDefault: false, languageCode: 'en' },
	{ name: 'New Caledonia', value: 'NC', isDefault: false, languageCode: 'en' },
	{ name: 'New Zealand', value: 'NZ', isDefault: false, languageCode: 'en' },
	{ name: 'Nicaragua', value: 'NI', isDefault: false, languageCode: 'en' },
	{ name: 'Niger', value: 'NE', isDefault: false, languageCode: 'en' },
	{ name: 'Nigeria', value: 'NG', isDefault: false, languageCode: 'en' },
	{ name: 'Niue', value: 'NU', isDefault: false, languageCode: 'en' },
	{ name: 'North Korea', value: 'KP', isDefault: false, languageCode: 'en' },
	{ name: 'Northern Mariana Islands', value: 'MP', isDefault: false, languageCode: 'en' },
	{ name: 'Norway', value: 'NO', isDefault: false, languageCode: 'en' },
	{ name: 'Oman', value: 'OM', isDefault: false, languageCode: 'en' },
	{ name: 'Pakistan', value: 'PK', isDefault: false, languageCode: 'en' },
	{ name: 'Palau', value: 'PW', isDefault: false, languageCode: 'en' },
	{ name: 'Palestine', value: 'PS', isDefault: false, languageCode: 'en' },
	{ name: 'Panama', value: 'PA', isDefault: false, languageCode: 'en' },
	{ name: 'Papua New Guinea', value: 'PG', isDefault: false, languageCode: 'en' },
	{ name: 'Paraguay', value: 'PY', isDefault: false, languageCode: 'en' },
	{ name: 'Pitcairn', value: 'PN', isDefault: false, languageCode: 'en' },
	{ name: 'Poland', value: 'PL', isDefault: false, languageCode: 'en' },
	{ name: 'Portugal', value: 'PT', isDefault: false, languageCode: 'en' },
	{ name: 'Puerto Rico', value: 'PR', isDefault: false, languageCode: 'en' },
	{ name: 'Qatar', value: 'QA', isDefault: false, languageCode: 'en' },
	{ name: 'Republic of the Congo', value: 'CG', isDefault: false, languageCode: 'en' },
	{ name: 'Reunion', value: 'RE', isDefault: false, languageCode: 'en' },
	{ name: 'Romania', value: 'RO', isDefault: false, languageCode: 'en' },
	{ name: 'Russia', value: 'RU', isDefault: false, languageCode: 'en' },
	{ name: 'Rwanda', value: 'RW', isDefault: false, languageCode: 'en' },
	{ name: 'Saint Barthelemy', value: 'BL', isDefault: false, languageCode: 'en' },
	{ name: 'Saint Helena', value: 'SH', isDefault: false, languageCode: 'en' },
	{ name: 'Saint Kitts and Nevis', value: 'KN', isDefault: false, languageCode: 'en' },
	{ name: 'Saint Lucia', value: 'LC', isDefault: false, languageCode: 'en' },
	{ name: 'Saint Martin', value: 'MF', isDefault: false, languageCode: 'en' },
	{ name: 'Saint Pierre and Miquelon', value: 'PM', isDefault: false, languageCode: 'en' },
	{ name: 'Saint Vincent and the Grenadines', value: 'VC', isDefault: false, languageCode: 'en' },
	{ name: 'Samoa', value: 'WS', isDefault: false, languageCode: 'en' },
	{ name: 'San Marino', value: 'SM', isDefault: false, languageCode: 'en' },
	{ name: 'Sao Tome and Principe', value: 'ST', isDefault: false, languageCode: 'en' },
	{ name: 'Senegal', value: 'SN', isDefault: false, languageCode: 'en' },
	{ name: 'Serbia', value: 'RS', isDefault: false, languageCode: 'en' },
	{ name: 'Seychelles', value: 'SC', isDefault: false, languageCode: 'en' },
	{ name: 'Sierra Leone', value: 'SL', isDefault: false, languageCode: 'en' },
	{ name: 'Singapore', value: 'SG', isDefault: false, languageCode: 'en' },
	{ name: 'Sint Maarten', value: 'SX', isDefault: false, languageCode: 'en' },
	{ name: 'Slovakia', value: 'SK', isDefault: false, languageCode: 'en' },
	{ name: 'Slovenia', value: 'SI', isDefault: false, languageCode: 'en' },
	{ name: 'Solomon Islands', value: 'SB', isDefault: false, languageCode: 'en' },
	{ name: 'Somalia', value: 'SO', isDefault: false, languageCode: 'en' },
	{ name: 'South Africa', value: 'ZA', isDefault: false, languageCode: 'en' },
	{ name: 'South Korea', value: 'KR', isDefault: false, languageCode: 'en' },
	{ name: 'South Sudan', value: 'SS', isDefault: false, languageCode: 'en' },
	{ name: 'Sri Lanka', value: 'LK', isDefault: false, languageCode: 'en' },
	{ name: 'Sudan', value: 'SD', isDefault: false, languageCode: 'en' },
	{ name: 'Suriname', value: 'SR', isDefault: false, languageCode: 'en' },
	{ name: 'Svalbard and Jan Mayen', value: 'SJ', isDefault: false, languageCode: 'en' },
	{ name: 'Swaziland', value: 'SZ', isDefault: false, languageCode: 'en' },
	{ name: 'Sweden', value: 'SE', isDefault: false, languageCode: 'en' },
	{ name: 'Switzerland', value: 'CH', isDefault: false, languageCode: 'en' },
	{ name: 'Syria', value: 'SY', isDefault: false, languageCode: 'en' },
	{ name: 'Taiwan', value: 'TW', isDefault: false, languageCode: 'en' },
	{ name: 'Tajikistan', value: 'TJ', isDefault: false, languageCode: 'en' },
	{ name: 'Tanzania', value: 'TZ', isDefault: false, languageCode: 'en' },
	{ name: 'Togo', value: 'TG', isDefault: false, languageCode: 'en' },
	{ name: 'Tokelau', value: 'TK', isDefault: false, languageCode: 'en' },
	{ name: 'Tonga', value: 'TO', isDefault: false, languageCode: 'en' },
	{ name: 'Trinidad and Tobago', value: 'TT', isDefault: false, languageCode: 'en' },
	{ name: 'Tunisia', value: 'TN', isDefault: false, languageCode: 'en' },
	{ name: 'Turkmenistan', value: 'TM', isDefault: false, languageCode: 'en' },
	{ name: 'Turks and Caicos Islands', value: 'TC', isDefault: false, languageCode: 'en' },
	{ name: 'Tuvalu', value: 'TV', isDefault: false, languageCode: 'en' },
	{ name: 'U.S. Virgin Islands', value: 'VI', isDefault: false, languageCode: 'en' },
	{ name: 'Uganda', value: 'UG', isDefault: false, languageCode: 'en' },
	{ name: 'Ukraine', value: 'UA', isDefault: false, languageCode: 'en' },
	{ name: 'United Kingdom', value: 'GB', isDefault: false, languageCode: 'en' },
	{ name: 'United States', value: 'US', isDefault: false, languageCode: 'en' },
	{ name: 'Uruguay', value: 'UY', isDefault: false, languageCode: 'en' },
	{ name: 'Uzbekistan', value: 'UZ', isDefault: false, languageCode: 'uz' },
	{ name: 'Vanuatu', value: 'VU', isDefault: false, languageCode: 'en' },
	{ name: 'Vatican', value: 'VA', isDefault: false, languageCode: 'en' },
	{ name: 'Wallis and Futuna', value: 'WF', isDefault: false, languageCode: 'en' },
	{ name: 'Western Sahara', value: 'EH', isDefault: false, languageCode: 'en' },
	{ name: 'Yemen', value: 'YE', isDefault: false, languageCode: 'en' },
	{ name: 'Zambia', value: 'ZM', isDefault: false, languageCode: 'en' },
	{ name: 'Zimbabwe', value: 'ZW', isDefault: false, languageCode: 'en' }
];

export const LANGUAGE_LIST = [
	{ name: 'Abkhazian', value: 'ab' },
	{ name: 'Afar', value: 'aa' },
	{ name: 'Afrikaans', value: 'af' },
	{ name: 'Akan', value: 'ak' },
	{ name: 'Albanian', value: 'sq' },
	{ name: 'Amharic', value: 'am' },
	{ name: 'Arabic', value: 'ar' },
	{ name: 'Aragonese', value: 'an' },
	{ name: 'Armenian', value: 'hy' },
	{ name: 'Assamese', value: 'as' },
	{ name: 'Avaric', value: 'av' },
	{ name: 'Avestan', value: 'ae' },
	{ name: 'Aymara', value: 'ay' },
	{ name: 'Azerbaijani', value: 'az' },
	{ name: 'Bambara', value: 'bm' },
	{ name: 'Bashkir', value: 'ba' },
	{ name: 'Basque', value: 'eu' },
	{ name: 'Belarusian', value: 'be' },
	{ name: 'Bengali', value: 'bn' },
	{ name: 'Bihari languages', value: 'bh' },
	{ name: 'Bislama', value: 'bi' },
	{ name: 'Bosnian', value: 'bs' },
	{ name: 'Breton', value: 'br' },
	{ name: 'Bulgarian', value: 'bg' },
	{ name: 'Burmese', value: 'my' },
	{ name: 'Catalan', value: 'ca' },
	{ name: 'Central Khmer', value: 'km' },
	{ name: 'Chamorro', value: 'ch' },
	{ name: 'Chechen', value: 'ce' },
	{ name: 'Chichewa', value: 'ny' },
	{ name: 'Chinese', value: 'zh' },
	{ name: 'Church Slavic', value: 'cu' },
	{ name: 'Chuvash', value: 'cv' },
	{ name: 'Cornish', value: 'kw' },
	{ name: 'Corsican', value: 'co' },
	{ name: 'Cree', value: 'cr' },
	{ name: 'Croatian', value: 'hr' },
	{ name: 'Czech', value: 'cs' },
	{ name: 'Danish', value: 'da' },
	{ name: 'Divehi', value: 'dv' },
	{ name: 'Dutch', value: 'nl' },
	{ name: 'Dzongkha', value: 'dz' },
	{ name: 'English', value: 'en' },
	{ name: 'Esperanto', value: 'eo' },
	{ name: 'Estonian', value: 'et' },
	{ name: 'Ewe', value: 'ee' },
	{ name: 'Faroese', value: 'fo' },
	{ name: 'Fijian', value: 'fj' },
	{ name: 'Finnish', value: 'fi' },
	{ name: 'French', value: 'fr' },
	{ name: 'Fulah', value: 'ff' },
	{ name: 'Gaelic', value: 'gd' },
	{ name: 'Galician', value: 'gl' },
	{ name: 'Ganda', value: 'lg' },
	{ name: 'Georgian', value: 'ka' },
	{ name: 'German', value: 'de' },
	{ name: 'Greek', value: 'el' },
	{ name: 'Guarani', value: 'gn' },
	{ name: 'Gujarati', value: 'gu' },
	{ name: 'Haitian', value: 'ht' },
	{ name: 'Hausa', value: 'ha' },
	{ name: 'Hebrew', value: 'he' },
	{ name: 'Herero', value: 'hz' },
	{ name: 'Hindi', value: 'hi' },
	{ name: 'Hiri Motu', value: 'ho' },
	{ name: 'Hungarian', value: 'hu' },
	{ name: 'Icelandic', value: 'is' },
	{ name: 'Ido', value: 'io' },
	{ name: 'Igbo', value: 'ig' },
	{ name: 'Indonesian', value: 'id' },
	{ name: 'Interlingua', value: 'ia' },
	{ name: 'Interlingue', value: 'ie' },
	{ name: 'Inuktitut', value: 'iu' },
	{ name: 'Inupiaq', value: 'ik' },
	{ name: 'Irish', value: 'ga' },
	{ name: 'Italian', value: 'it' },
	{ name: 'Japanese', value: 'ja' },
	{ name: 'Javanese', value: 'jv' },
	{ name: 'Kalaallisut', value: 'kl' },
	{ name: 'Kannada', value: 'kn' },
	{ name: 'Kanuri', value: 'kr' },
	{ name: 'Kashmiri', value: 'ks' },
	{ name: 'Kazakh', value: 'kk' },
	{ name: 'Kikuyu', value: 'ki' },
	{ name: 'Kinyarwanda', value: 'rw' },
	{ name: 'Kirghiz', value: 'ky' },
	{ name: 'Komi', value: 'kv' },
	{ name: 'Kongo', value: 'kg' },
	{ name: 'Korean', value: 'ko' },
	{ name: 'Kuanyama', value: 'kj' },
	{ name: 'Kurdish', value: 'ku' },
	{ name: 'Lao', value: 'lo' },
	{ name: 'Latin', value: 'la' },
	{ name: 'Latvian', value: 'lv' },
	{ name: 'Limburgan', value: 'li' },
	{ name: 'Lingala', value: 'ln' },
	{ name: 'Lithuanian', value: 'lt' },
	{ name: 'Luba-Katanga', value: 'lu' },
	{ name: 'Luxembourgish', value: 'lb' },
	{ name: 'Macedonian', value: 'mk' },
	{ name: 'Malagasy', value: 'mg' },
	{ name: 'Malay', value: 'ms' },
	{ name: 'Malayalam', value: 'ml' },
	{ name: 'Maltese', value: 'mt' },
	{ name: 'Manx', value: 'gv' },
	{ name: 'Maori', value: 'mi' },
	{ name: 'Marathi', value: 'mr' },
	{ name: 'Marshallese', value: 'mh' },
	{ name: 'Mongolian', value: 'mn' },
	{ name: 'Nauru', value: 'na' },
	{ name: 'Navajo', value: 'nv' },
	{ name: 'Ndonga', value: 'ng' },
	{ name: 'Nepali', value: 'ne' },
	{ name: 'Northern Sami', value: 'se' },
	{ name: 'Norwegian', value: 'no' },
	{ name: 'Occitan', value: 'oc' },
	{ name: 'Ojibwa', value: 'oj' },
	{ name: 'Oriya', value: 'or' },
	{ name: 'Oromo', value: 'om' },
	{ name: 'Ossetian', value: 'os' },
	{ name: 'Pali', value: 'pi' },
	{ name: 'Panjabi', value: 'pa' },
	{ name: 'Persian', value: 'fa' },
	{ name: 'Polish', value: 'pl' },
	{ name: 'Portuguese', value: 'pt' },
	{ name: 'Pushto', value: 'ps' },
	{ name: 'Quechua', value: 'qu' },
	{ name: 'Romanian', value: 'ro' },
	{ name: 'Romansh', value: 'rm' },
	{ name: 'Rundi', value: 'rn' },
	{ name: 'Russian', value: 'ru' },
	{ name: 'Samoan', value: 'sm' },
	{ name: 'Sango', value: 'sg' },
	{ name: 'Sanskrit', value: 'sa' },
	{ name: 'Sardinian', value: 'sc' },
	{ name: 'Serbian', value: 'sr' },
	{ name: 'Shona', value: 'sn' },
	{ name: 'Sichuan Yi', value: 'ii' },
	{ name: 'Sindhi', value: 'sd' },
	{ name: 'Sinhala', value: 'si' },
	{ name: 'Slovak', value: 'sk' },
	{ name: 'Slovenian', value: 'sl' },
	{ name: 'Somali', value: 'so' },
	{ name: 'Sotho', value: 'st' },
	{ name: 'Spanish', value: 'es' },
	{ name: 'Sundanese', value: 'su' },
	{ name: 'Swahili', value: 'sw' },
	{ name: 'Swati', value: 'ss' },
	{ name: 'Swedish', value: 'sv' },
	{ name: 'Tagalog', value: 'tl' },
	{ name: 'Tahitian', value: 'ty' },
	{ name: 'Tajik', value: 'tg' },
	{ name: 'Tamil', value: 'ta' },
	{ name: 'Tatar', value: 'tt' },
	{ name: 'Telugu', value: 'te' },
	{ name: 'Thai', value: 'th' },
	{ name: 'Tibetan', value: 'bo' },
	{ name: 'Tigrinya', value: 'ti' },
	{ name: 'Tonga', value: 'to' },
	{ name: 'Tsonga', value: 'ts' },
	{ name: 'Tswana', value: 'tn' },
	{ name: 'Turkish', value: 'tr' },
	{ name: 'Turkmen', value: 'tk' },
	{ name: 'Twi', value: 'tw' },
	{ name: 'Uighur', value: 'ug' },
	{ name: 'Ukrainian', value: 'uk' },
	{ name: 'Urdu', value: 'ur' },
	{ name: 'Uzbek', value: 'uz' },
	{ name: 'Venda', value: 've' },
	{ name: 'Vietnamese', value: 'vi' },
	{ name: 'Volapük', value: 'vo' },
	{ name: 'Walloon', value: 'wa' },
	{ name: 'Welsh', value: 'cy' },
	{ name: 'Western Frisian', value: 'fy' },
	{ name: 'Wolof', value: 'wo' },
	{ name: 'Xhosa', value: 'xh' },
	{ name: 'Yiddish', value: 'yi' },
	{ name: 'Yoruba', value: 'yo' },
	{ name: 'Zhuang', value: 'za' },
	{ name: 'Zulu', value: 'zu' }
];

export const CLIENT_LIST = [
	{
		name: 'CVTE',
		value: 'CVTE',
		isDefault: true
	},
	{
		name: 'MX TV',
		value: 'MXTV',
		isDefault: false
	},
	{
		name: 'CVTE TEST',
		value: 'CVTE_TEST',
		isDefault: false
	},
	{
		name: '视翔',
		value: 'SHIXIANG_TEST',
		isDefault: false
	}
];

export const SDK_VERSION_LIST = [
	{
		name: '20000',
		value: 20000,
		isDefault: false
	},
	{
		name: '30000',
		value: 30000,
		isDefault: true
	}
];

export const MENU_HOME = {
	order: 0,
	name: 'Home',
	type: 'home',
	iconUrl: 'https://mosaix-tv-cdn.mosaix.ai/assets/icons/menu/home.svg'
};

export const MENU_SEARCH = {
	order: -1,
	name: 'Search',
	type: 'search',
	iconUrl: 'https://mosaix-tv-cdn.mosaix.ai/assets/icons/menu/search.svg'
};

export const SEARCH_SDK_VERSION_LIST = [
	{
		name: '2.0.1',
		value: '2.0.1',
		isDefault: true,
		supportedIntents: [
			'Volume.Decrease',
			'Volume.Increase',
			'Volume.SetTo',
			'Volume.Mute',
			'Volume.Unmute',
			'Volume.Max',
			'Volume.Min',
			'DeviceSettings.SwitchDeviceInput',
			'DeviceSettings.SwitchSoundMode',
			'DeviceSettings.SwitchPictureMode',
			'DeviceControl.ChannelSearch',
			'DeviceControl.TurnOn',
			'DeviceControl.TurnOff',
			'DeviceControl.ChannelUp',
			'DeviceControl.ChannelDown',
			'AppControl.Open',
			'Generic.Pause',
			'Generic.Stop',
			'Generic.Next',
			'Generic.Previous',
			'Generic.Forward',
			'Generic.Rewind',
			'Generic.GoBack',
			'Generic.Home',
			'Generic.Play',
			'Generic.Resume'
		]
	}
];

export const ENTERTAINMENT_DOMAINS = ['Movie', 'TV', 'Music', 'Video'];

export const IMG_PLACEHOLDER =
	'https://www.themoviedb.org/assets/2/v4/glyphicons/basic/glyphicons-basic-38-picture-grey-c2ebdbb057f2a7614185931650f8cee23fa137b93812ccb132b9df511df1cfac.svg';
export const PROFILE_PLACEHOLDER =
	'https://www.themoviedb.org/assets/2/v4/glyphicons/basic/glyphicons-basic-4-user-grey-d8fe957375e70239d6abdd549fd7568c89281b2179b5f4470e2e12895792dfa5.svg';
