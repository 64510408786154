import { Button, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { AsrTask } from './Wer';

interface QueryListProps {
	isLoading: boolean;
	task: AsrTask | undefined;
	updateQuery: (taskId: string, queryId: string, correction: string) => void;
	werButtonPressed: (taskId: string) => void;
}

const useStyles = makeStyles((theme) => ({
	input: {
		height: '30px',
		width: '100%'
	},
	btnContainer: {
		padding: theme.spacing(2),
		display: 'flex',
		justifyContent: 'flex-end'
	}
}));

export default function WerQueryList(props: QueryListProps) {
	const { task, updateQuery, werButtonPressed } = props;
	const classes = useStyles();

	if (!task) return null;

	const { locale } = task;

	const updateCorrection = (queryId: string, e: React.ChangeEvent<HTMLInputElement>) => {
		updateQuery(task._id, queryId, e.target.value);
	};

	return (
		<>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell style={{ width: '30%' }}>Transcript</TableCell>
						<TableCell style={{ width: '40%' }}>Correction from Audio</TableCell>
						<TableCell style={{ width: '30%' }}>Audio</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{task.queries.map((el) => {
						const { queryId, transcript = '', correction = '', deviceId, timestamp, sessionId } = el;
						const audioUrl = `https://mosaixtv.blob.core.windows.net/voice-search-data/${locale}/${deviceId}/${deviceId}-${sessionId}-${timestamp}.wav`;
						return (
							<TableRow key={el.queryId}>
								<TableCell style={{ width: '30%' }}>{transcript}</TableCell>
								<TableCell style={{ width: '40%' }}>
									<input type="text" className={classes.input} value={correction} onChange={(e) => updateCorrection(queryId, e)} />
								</TableCell>
								<TableCell style={{ width: '30%' }}>
									<audio src={audioUrl} controls />
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			<div className={classes.btnContainer}>
				<Button variant="contained" color="primary" size="large" onClick={() => werButtonPressed(task._id)}>
					Calculate WER
				</Button>
			</div>
		</>
	);
}
