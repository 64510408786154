import { Grid, InputLabel, makeStyles, Select, Table, TableBody, TableCell, TableHead, TableRow, Button, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { uniq } from 'lodash';
import moment from 'moment';
import { crawlCatalogSource, indexCountry, refreshCatalogSource } from '../../api';
import { Tracker } from './HunterTool';

interface CatalogToolProps {
	tracker: Tracker[];
}

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(1)
	},
	container: {
		padding: theme.spacing(1)
	},
	selectControl: {
		marginLeft: '20px',
		width: '150px'
	},
	underlineText: {
		margin: '0 40px',
		textDecoration: 'underline',
		color: 'blue',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	indexButton: {
		margin: '0 40px'
	}
}));

export function CatalogTool(props: CatalogToolProps) {
	const classes = useStyles();
	const [currentCountry, setCurrentCountry] = useState('ES');
	const { tracker } = props;

	const filterTracker = tracker.filter((tracker) => tracker.countryCode.toLocaleUpperCase() === currentCountry.toLocaleUpperCase());
	const countries = uniq(tracker.map((tracker) => tracker.countryCode));

	return (
		<Paper className={classes.paper}>
			{filterTracker.length === 0 ? null : (
				<div className={classes.container}>
					<Grid container spacing={0} direction="row" alignItems="center" justifyContent="flex-start">
						<InputLabel htmlFor="outlined-country">Select Country: </InputLabel>
						<Select
							native
							className={classes.selectControl}
							value={currentCountry}
							onChange={(event) => {
								const country = event.target.value as string;
								setCurrentCountry(country);
							}}
							label="Select Country"
							inputProps={{
								name: 'country',
								id: 'outlined-country'
							}}
						>
							{countries.map((country) => (
								<option key={country} value={country}>
									{country}
								</option>
							))}
						</Select>
						<Button
							variant="outlined"
							className={classes.indexButton}
							onClick={() => {
								indexCountry(currentCountry, 'movie');
							}}
						>
							Index Movie
						</Button>
						<Button
							variant="outlined"
							className={classes.indexButton}
							onClick={() => {
								indexCountry(currentCountry, 'series');
							}}
						>
							Index Series
						</Button>
						<span className={classes.underlineText}>
							<a target="_blank" rel="noopener noreferrer" href={'https://console.aws.amazon.com/sqs/v2/home?region=us-east-1#/queues'}>
								SQS queue
							</a>
						</span>
					</Grid>
				</div>
			)}
			{filterTracker.length === 0 ? null : (
				<Table size="small" style={{ border: '2px solid grey' }}>
					<TableHead>
						<TableRow>
							<TableCell>Logo</TableCell>
							<TableCell>Crawl Source</TableCell>
							<TableCell>Last Start updated</TableCell>
							<TableCell>Last End updated</TableCell>
							<TableCell>Action 1</TableCell>
							<TableCell>Action 2</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filterTracker.map(({ countryCode, crawlSource, lastStartUpdated, lastEndUpdated }) => {
							return (
								<TableRow key={`${countryCode}-${crawlSource}`}>
									<TableCell>
										<img
											alt="Logo"
											width="40"
											src={`https://mosaix-tv-cdn.mosaix.ai/assets/icons/source/${crawlSource.split('.')[1].toLocaleLowerCase()}.png`}
										/>
									</TableCell>
									<TableCell>{crawlSource}</TableCell>
									<TableCell>{lastStartUpdated ? moment(lastStartUpdated).format('YYYY-MM-DD HH:mm:ss') : null}</TableCell>
									<TableCell>{lastEndUpdated ? moment(lastEndUpdated).format('YYYY-MM-DD HH:mm:ss') : null}</TableCell>
									<TableCell>
										<Button
											variant="outlined"
											onClick={() => {
												crawlCatalogSource(countryCode, crawlSource);
											}}
										>
											CRAWL
										</Button>
									</TableCell>
									<TableCell>
										<Button
											variant="outlined"
											onClick={() => {
												refreshCatalogSource(countryCode, crawlSource);
											}}
										>
											REFRESH
										</Button>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			)}
		</Paper>
	);
}
