import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(1)
	},
	anchorWrapper: {
		padding: theme.spacing(1)
	}
}));

const COMMON_LINKS = [
	{
		id: 1,
		name: 'Amazon SQS queue',
		url: 'https://console.aws.amazon.com/sqs/v2/home?region=us-east-1#/queues'
	},
	{
		id: 2,
		name: 'Kibana Dashboard',
		url: 'https://kibana.logs.saymosaic.com/s/cvte/app/dashboards#'
	},
	{
		id: 2,
		name: 'Inactive records Dashboard',
		url: 'https://metabase.saymosaic.com/dashboard/136'
	}
];

export default function Links() {
	const classes = useStyles();

	return (
		<Paper className={classes.paper}>
			{COMMON_LINKS.map(({ id, name, url }) => {
				return (
					<div key={id} className={classes.anchorWrapper}>
						<a target="_blank" rel="noopener noreferrer" href={url}>
							{name}
						</a>
					</div>
				);
			})}
		</Paper>
	);
}
