import React, { useCallback, useState, useRef } from 'react';
import { Button, Grid, TextField, Divider, FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import _ from 'lodash';
import ReactJson from 'react-json-view';
import { GameEntity } from './GameList';
import Title from '../common/Title';
import { updateGame, searchIgdbGame, deleteGame } from '../../api';
import { calRatio, esrbRatings, pegiRatings } from '../../utils/util';

interface GameDetailProps {
	game: GameEntity;
	updateCurrentGameId: (gameId: number) => void;
}

const useStyle = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		'& > .fifth': {
			width: '20%',
			paddingRight: '10px',
			marginBottom: 0
		},
		'& > .quarter': {
			width: '25%',
			paddingRight: '10px',
			marginBottom: 0
		},
		'& > .oneThird': {
			width: '33.3%',
			paddingRight: '10px',
			marginBottom: 0
		},
		'& > .half': {
			width: '50%',
			paddingRight: '10px',
			marginBottom: 0
		},
		'& > .full': {
			width: '100%',
			paddingRight: '10px',
			marginBottom: 0
		}
	},
	divider: {
		height: '20px',
		backgroundColor: 'white'
	},
	full: {
		width: '100%',
		paddingRight: '10px',
		marginBottom: 0
	},
	oneThird: {
		width: '33.3%',
		paddingRight: '10px',
		marginBottom: 0
	},
	previewImage: {
		width: '100%',
		height: 'auto'
	},
	enable: {
		margin: '20px auto'
	},
	submit: {
		margin: theme.spacing(1, 0, 1),
		padding: theme.spacing(1, 3),
		fontSize: '16px',
		width: '50%'
	}
}));

export default function GameDetail(props: GameDetailProps) {
	const classes = useStyle();
	const { updateCurrentGameId, game } = props;

	const gameInfoRef = useRef(game);
	if (game && gameInfoRef.current == null) {
		gameInfoRef.current = game;
	}
	const searchResultRef = useRef({});
	const searchIdRef = useRef(gameInfoRef.current && gameInfoRef.current.externalIdIgdb);
	const searchNameRef = useRef(gameInfoRef.current && gameInfoRef.current.name);

	const [isUploading, setIsUploading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [, setForceRefresh] = useState(false);
	const [isSearching, setIsSearching] = useState(false);

	const submitPressed = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();
			setIsUploading(true);

			try {
				gameInfoRef.current.genres = (gameInfoRef.current.genres || []).map((genre) => genre.trim());
				gameInfoRef.current.ageRatingEsrb = gameInfoRef.current.ageRatingEsrb || '';
				gameInfoRef.current.ageRatingPegi = gameInfoRef.current.ageRatingPegi || '';
				gameInfoRef.current.notes = gameInfoRef.current.notes || '';
				const res = await updateGame(gameInfoRef.current.id, gameInfoRef.current);
				const { status } = res;
				if (status !== 200) {
					setIsUploading(false);
					alert(`Updataing error: ${status}`);
				} else {
					//back to game list
					updateCurrentGameId(-1);
				}
			} catch (error) {
				setIsUploading(false);
				console.log(error);
				alert('Something went wrong, please try again later');
			}
		},
		[updateCurrentGameId]
	);

	const searchPressed = useCallback(async (e: React.FormEvent) => {
		e.preventDefault();
		searchResultRef.current = {};
		setIsSearching(true);
		try {
			const res = await searchIgdbGame(searchIdRef.current, searchNameRef.current);
			const { status } = res;
			if (status !== 200) {
				setIsUploading(false);
				alert(`Search error: ${status}`);
			} else {
				searchResultRef.current = await res.json();
			}
			setIsSearching(false);
		} catch (error) {
			setIsSearching(false);
			console.log(error);
			alert('Something went wrong, please try again later');
		}
	}, []);

	const onValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const { id, value, checked } = e.target;
		if (id === 'game') {
			gameInfoRef.current.name = value;
		} else if (id === 'externalIgdbId') {
			gameInfoRef.current.externalIdIgdb = Number(value);
		} else if (id === 'summary') {
			gameInfoRef.current.summary = value;
		} else if (id === 'releaseYear') {
			gameInfoRef.current.releaseYear = Number(value);
		} else if (id === 'pegiRating') {
			gameInfoRef.current.ageRatingPegi = value;
		} else if (id === 'esrbRating') {
			gameInfoRef.current.ageRatingEsrb = value;
		} else if (id === 'posterUrl') {
			gameInfoRef.current.posterUrlJson = { ...(gameInfoRef.current.posterUrlJson || {}), ...{ url: value } };
		} else if (id === 'posterWidth') {
			gameInfoRef.current.posterUrlJson = { ...(gameInfoRef.current.posterUrlJson || {}), ...{ width: Number(value) } };
		} else if (id === 'posterHeight') {
			gameInfoRef.current.posterUrlJson = { ...(gameInfoRef.current.posterUrlJson || {}), ...{ height: Number(value) } };
		} else if (id === 'backgroundImageUrl') {
			gameInfoRef.current.backgroundImageUrlJson = [{ ...(_.get(gameInfoRef.current, 'backgroundImageUrlJson[0]') || {}), ...{ url: value } }];
		} else if (id === 'backgroundImageWidth') {
			gameInfoRef.current.backgroundImageUrlJson = [{ ...(_.get(gameInfoRef.current, 'backgroundImageUrlJson[0]') || {}), ...{ width: Number(value) } }];
		} else if (id === 'backgroundImageHeight') {
			gameInfoRef.current.backgroundImageUrlJson = [{ ...(_.get(gameInfoRef.current, 'backgroundImageUrlJson[0]') || {}), ...{ height: Number(value) } }];
		} else if (id === 'genre') {
			gameInfoRef.current.genres = value.split(',');
		} else if (id === 'notes') {
			gameInfoRef.current.notes = value;
		} else if (id === 'enable') {
			gameInfoRef.current.isEnabled = checked ? 1 : 0;
		} else if (id === 'searchName') {
			searchNameRef.current = value;
			searchIdRef.current = 0;
		} else if (id === 'searchId') {
			searchIdRef.current = Number(value);
			searchNameRef.current = '';
		} else {
			return;
		}
		setForceRefresh((refresh) => !refresh);
	}, []);

	const deletePressed = useCallback(async () => {
		if (window.confirm('Are you sure you want to delete?')) {
			setIsDeleting(true);

			try {
				const res = await deleteGame(gameInfoRef.current.id);
				const { status } = res;
				if (status !== 200) {
					setIsDeleting(false);
					alert(`Deleting error: ${status}`);
				} else {
					//back to game list
					updateCurrentGameId(-1);
				}
			} catch (error) {
				setIsDeleting(false);
				console.log(error);
				alert('Something went wrong, please try again later');
			}
		}
	}, []);

	const posterRatio = calRatio(_.get(gameInfoRef.current, 'posterUrlJson.width'), _.get(gameInfoRef.current, 'posterUrlJson.height'));
	const backgroundImageRatio = calRatio(
		_.get(gameInfoRef.current, 'backgroundImageUrlJson[0].width'),
		_.get(gameInfoRef.current, 'backgroundImageUrlJson[0].height')
	);
	const posterUrl = _.get(gameInfoRef.current, 'posterUrlJson.url', '');
	const backgroundImageUrl = _.get(gameInfoRef.current, 'backgroundImageUrlJson[0].url');

	if (!gameInfoRef.current) return <div />;

	return (
		<div>
			<Button startIcon={<ChevronLeftIcon />} onClick={() => props.updateCurrentGameId(-1)}>
				Game List
			</Button>
			<Grid container spacing={5}>
				<Grid item md={12} lg={6}>
					<form className={classes.form} onSubmit={submitPressed}>
						<TextField className="half" margin="normal" disabled label="GUID" value={gameInfoRef.current.guid} />
						<TextField className="quarter" margin="normal" disabled label="Id" value={gameInfoRef.current.id} />
						<TextField className="quarter" margin="normal" disabled label="External Twitch Id" value={gameInfoRef.current.externalIdTwitch} />
						<TextField
							className="half"
							margin="normal"
							required
							id="game"
							label="Game"
							name="game"
							value={gameInfoRef.current.name || ''}
							onChange={onValueChange}
							autoFocus
						/>
						<TextField
							className="quarter"
							margin="normal"
							type="number"
							required
							id="externalIgdbId"
							label="External IGDB Id"
							name="externalIgdbId"
							value={gameInfoRef.current.externalIdIgdb || ''}
							onChange={onValueChange}
						/>
						<TextField
							className="quarter"
							margin="normal"
							required
							type="number"
							id="releaseYear"
							label="Release year"
							name="releaseYear"
							value={gameInfoRef.current.releaseYear || ''}
							onChange={onValueChange}
						/>
						<TextField
							className="full"
							margin="normal"
							variant="outlined"
							required
							multiline
							error={(gameInfoRef.current.summary || '').length > 250}
							helperText={(gameInfoRef.current.summary || '').length > 250 ? 'Summary length must <= 250' : ''}
							id="summary"
							label={`Summary (${_.size(gameInfoRef.current.summary)}/250)`}
							name="summary"
							value={gameInfoRef.current.summary || ''}
							onChange={onValueChange}
						/>
						<TextField
							className="half"
							margin="normal"
							required
							id="genre"
							label="Genre (e.g. RPG,Shooter)"
							name="genre"
							value={(gameInfoRef.current.genres || []).join(',')}
							onChange={onValueChange}
						/>
						<TextField
							className="half"
							margin="normal"
							error={!!(gameInfoRef.current.ageRatingEsrb && !esrbRatings.includes(gameInfoRef.current.ageRatingEsrb))}
							id="esrbRating"
							label={`ESRB rating (List: ${esrbRatings.join(', ')})`}
							name="esrbRating"
							value={gameInfoRef.current.ageRatingEsrb || ''}
							onChange={onValueChange}
						/>
						<TextField
							className="full"
							margin="normal"
							error={!!(gameInfoRef.current.ageRatingPegi && !pegiRatings.includes(gameInfoRef.current.ageRatingPegi))}
							id="pegiRating"
							label={`PEGI rating (List: ${pegiRatings.join(', ')})`}
							name="pegiRating"
							value={gameInfoRef.current.ageRatingPegi || ''}
							onChange={onValueChange}
						/>
						{/** Poster And Background image */}
						<Grid container>
							<Grid item xs={9}>
								<Divider className={classes.divider} />
								<Divider className={classes.divider} />
								<TextField
									className={classes.full}
									margin="normal"
									id="posterUrl"
									label="Poster URL (Must replace **t_thumb** with **t_cover_big**)"
									name="posterUrl"
									value={posterUrl || ''}
									onChange={onValueChange}
								/>
								<TextField
									className={classes.oneThird}
									margin="normal"
									type="number"
									required
									id="posterWidth"
									label="Width"
									name="posterWidth"
									value={_.get(gameInfoRef.current, 'posterUrlJson.width') || ''}
									onChange={onValueChange}
								/>
								<TextField
									className={classes.oneThird}
									margin="normal"
									type="number"
									required
									id="posterHeight"
									label="Height"
									name="posterHeight"
									value={_.get(gameInfoRef.current, 'posterUrlJson.height') || ''}
									onChange={onValueChange}
								/>
								<TextField
									className={classes.oneThird}
									margin="normal"
									error={!!(posterRatio && posterRatio !== '3:4')}
									disabled
									label="Ratio(Must 3:4)"
									value={posterRatio}
								/>
								<Divider className={classes.divider} />
								<Divider className={classes.divider} />
								<TextField
									className={classes.full}
									margin="normal"
									required
									id="backgroundImageUrl"
									label="Background Image URL (Must replace **t_thumb** with **t_original**)"
									name="backgroundImageUrl"
									value={backgroundImageUrl || ''}
									onChange={onValueChange}
								/>
								<TextField
									className={classes.oneThird}
									margin="normal"
									type="number"
									required
									id="backgroundImageWidth"
									label="Width"
									name="backgroundImageWidth"
									value={_.get(gameInfoRef.current, 'backgroundImageUrlJson[0].width') || ''}
									onChange={onValueChange}
								/>
								<TextField
									className={classes.oneThird}
									margin="normal"
									type="number"
									required
									id="backgroundImageHeight"
									label="Height"
									name="backgroundImageHeight"
									value={_.get(gameInfoRef.current, 'backgroundImageUrlJson[0].height') || ''}
									onChange={onValueChange}
								/>
								<TextField
									className={classes.oneThird}
									margin="normal"
									error={!!(backgroundImageRatio && backgroundImageRatio !== '16:9')}
									disabled
									label="Ratio(Must 16:9)"
									value={backgroundImageRatio}
								/>
							</Grid>
							<Grid item xs={3}>
								<a target="_blank" rel="noopener noreferrer" href={posterUrl}>
									{posterUrl ? <img src={posterUrl} alt={'img'} className={classes.previewImage} /> : null}
								</a>
								<a target="_blank" rel="noopener noreferrer" href={backgroundImageUrl}>
									{backgroundImageUrl ? <img src={backgroundImageUrl} alt={'img'} className={classes.previewImage} /> : null}
								</a>
							</Grid>
						</Grid>
						<TextField
							className="full"
							margin="normal"
							variant="outlined"
							multiline
							id="notes"
							label="Notes"
							name="notes"
							value={gameInfoRef.current.notes || ''}
							onChange={onValueChange}
						/>
						<Divider className={classes.divider} />
						<Grid container>
							<Grid container item xs={4} justifyContent="center">
								<FormControlLabel
									control={<Switch checked={gameInfoRef.current.isEnabled === 1} onChange={onValueChange} id="enable" name="enable" color="primary" />}
									label="Enable"
									className={classes.enable}
								/>
							</Grid>
							<Grid container item xs={4}>
								<Button variant="contained" color="secondary" disabled={isDeleting || isUploading} className={classes.submit} onClick={deletePressed}>
									{isDeleting ? 'DELETING' : 'DELETE'}
								</Button>
							</Grid>
							<Grid container item xs={4}>
								<Button type="submit" variant="contained" color="primary" disabled={isDeleting || isUploading} className={classes.submit}>
									{isUploading ? 'SAVING' : 'SAVE'}
								</Button>
							</Grid>
						</Grid>
					</form>
				</Grid>
				<Grid item md={12} lg={6}>
					<Title>Search on IGDB</Title>
					<form onSubmit={searchPressed}>
						<Grid container justifyContent="space-between" alignItems="flex-end">
							<TextField
								margin="normal"
								className={classes.oneThird}
								id="searchName"
								label="Name"
								name="searchName"
								value={searchNameRef.current || ''}
								onChange={onValueChange}
							/>
							<span>OR</span>
							<TextField
								margin="normal"
								className={classes.oneThird}
								type="number"
								id="searchId"
								label="Id"
								name="searchId"
								value={searchIdRef.current || ''}
								onChange={onValueChange}
							/>
							<Button type="submit" variant="contained" color="primary" disabled={isSearching}>
								{isSearching ? 'SEARCHING' : 'SEARCH'}
							</Button>
						</Grid>
					</form>

					<Divider className={classes.divider} />
					<Title>{isSearching ? 'SEARCHING...' : 'SEARCH RESULT:'}</Title>
					{_.size(searchResultRef.current) ? <ReactJson name={false} displayDataTypes={false} src={searchResultRef.current || {}} /> : null}
				</Grid>
			</Grid>
		</div>
	);
}
