import { Container, LinearProgress, makeStyles, Tabs, AppBar, Tab } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { fetchCrawlTracker } from '../../api';
import { Blacklist } from './Blacklist';
import { CatalogTool } from './CatalogTool';
import DeeplinkTool from './DeeplinkTool';
import Links from './Links';
import Wer from './Wer';

export interface Tracker {
	countryCode: string;
	crawlSource: string;
	lastStartUpdated: string;
	lastEndUpdated: string;
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`hunter-tabpanel-${index}`} aria-labelledby={`hunter-tab-${index}`} {...other}>
			{value === index && <div style={{ paddingTop: '6px' }}>{children}</div>}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `hunter-tab-${index}`,
		'aria-controls': `hunter-tabpanel-${index}`
	};
}

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(1)
	},
	paper: {
		padding: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column'
	},
	progress: {
		height: '4px'
	}
}));

export default function HunterTool() {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const tracker = useRef<Tracker[]>([]);

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		async function runEffect() {
			try {
				if (isLoading) return;

				setIsLoading(true);
				const res = await fetchCrawlTracker();
				if (res.status === 200) {
					const { data } = await res.json();
					tracker.current = data;

					setIsLoading(false);
				}
			} catch (error) {
				setIsLoading(false);
			}
		}
		runEffect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Container maxWidth="xl" className={classes.container}>
			{isLoading ? <LinearProgress /> : <div className={classes.progress} />}
			<AppBar position="static" color="transparent">
				<Tabs value={value} textColor="primary" indicatorColor="primary" onChange={handleChange} aria-label="hunter tabs">
					<Tab label="Catalog" {...a11yProps(0)} />
					<Tab label="Deeplink" {...a11yProps(1)} />
					<Tab label="Common Links" {...a11yProps(2)} />
					<Tab label="Blacklist" {...a11yProps(3)} />
					<Tab label="WER" {...a11yProps(4)} />
				</Tabs>
			</AppBar>
			<TabPanel value={value} index={0}>
				<CatalogTool tracker={tracker.current} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<DeeplinkTool tracker={tracker.current} />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<Links />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<Blacklist />
			</TabPanel>
			<TabPanel value={value} index={4}>
				<Wer />
			</TabPanel>
		</Container>
	);
}
