import React, { useCallback, useState, useRef } from 'react';
import { CatalogItem } from './CatalogList';
import { makeStyles } from '@material-ui/core/styles';
import {
	Button,
	Grid,
	TextField,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody
} from '@material-ui/core';
import { get, map, pick } from 'lodash';
import ReactJson from 'react-json-view';
import { refreshCatalogItem, searchTmdbItem, updateCatalogItem } from '../../api';
import { StatisticItemEntity } from './CatalogOverview';
import moment from 'moment';

interface CatalogDetailProps {
	statisticsItem: StatisticItemEntity;
	catalogItem: CatalogItem;
	isFetchingList: boolean;
	isLastItem: boolean;
	toNextItem: (currentItem: CatalogItem) => void;
	setRefresh: () => void;
}

interface TmdbBase {
	id: number;
	original_language: string;
	poster_path: string;
	overview: string;
	credits: {
		cast: any;
		crew: any;
	};
}
interface TmdbMovie extends TmdbBase {
	title: string;
	original_title: string;
	release_date: string;
	runtime: number;
}

interface TmdbSeries extends TmdbBase {
	name: string;
	original_name: string;
	first_air_date: string;
}

const useStyle = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
		'& > .fifth': {
			width: '20%',
			paddingRight: '10px',
			marginBottom: theme.spacing(1)
		},
		'& > .quarter': {
			width: '25%',
			paddingRight: '10px',
			marginBottom: theme.spacing(1)
		},
		'& > .oneThird': {
			width: '33.3%',
			paddingRight: '10px',
			marginBottom: theme.spacing(1)
		},
		'& > .pointFour': {
			width: '40%',
			paddingRight: '10px',
			marginBottom: theme.spacing(1)
		},
		'& > .half': {
			width: '50%',
			paddingRight: '10px',
			marginBottom: theme.spacing(1)
		},
		'& > .full': {
			width: '100%',
			paddingRight: '10px',
			marginBottom: theme.spacing(1)
		},
		'& > .divider': {
			height: '20px',
			backgroundColor: 'white'
		},
		'& > .enable': {
			margin: '20px auto'
		},
		'& > .searchButton': {
			verticalAlign: 'bottom',
			marginBottom: theme.spacing(1)
		}
	},
	poster: {
		width: '100%',
		maxWidth: '250px',
		height: 'auto'
	},
	url: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	submit: {
		margin: theme.spacing(0, 0, 1),
		// padding: theme.spacing(2, 3),
		fontSize: '16px'
	},
	table: {
		tableLayout: 'fixed',
		border: '2px solid grey'
	},
	cell: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	}
}));

export default function CatalogDetail(props: CatalogDetailProps) {
	const { statisticsItem, catalogItem, isLastItem, isFetchingList, toNextItem, setRefresh } = props;
	const classes = useStyle();
	const searchStatus = useRef(0); // 0: init; 1: searching; 2: success; 3: no result; 4: error
	const tmdbResultsRef = useRef<TmdbBase[]>([]);
	const catalogItemIdRef = useRef(catalogItem?.id);
	let [tmdbId, setTmdbId] = useState(catalogItem?.tmdbId);
	let [status, setStatus] = useState(catalogItem?.status);
	const [isUploading, setIsUploading] = useState(false);
	let [searchTmdbId, setSearchTmdbId] = useState(catalogItem?.tmdbId);
	let [searchImdbId, setSearchImdbId] = useState('');
	let [searchTitle, setSearchTitle] = useState(catalogItem?.tmdbId < 1 ? catalogItem?.title : '');
	const [isSearching, setIsSearching] = useState(false);
	const [tmdbResultIndex, setTmdbResultIndex] = useState(0);
	const [isRefreshing, setIsRefreshing] = useState(false);

	const backToList = useCallback(() => {
		setRefresh();
	}, [setRefresh]);

	const onValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		if (name === 'tmdb_id') {
			setTmdbId(Number(value));
		} else if (name === 'status') {
			setStatus(value);
		} else if (name === 'search_tmdb_id') {
			setSearchTmdbId(Number(value));
			setSearchImdbId('');
			setSearchTitle('');
		} else if (name === 'search_imdb_id') {
			setSearchTmdbId(0);
			setSearchImdbId(value);
			setSearchTitle('');
		} else if (name === 'search_title') {
			setSearchTmdbId(0);
			setSearchImdbId('');
			setSearchTitle(value);
		}
	}, []);

	const nextPressed = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();
			try {
				searchStatus.current = 1;
				tmdbResultsRef.current = [];
				setTmdbResultIndex(0);
				setIsUploading(true);
				const args: { tmdbId?: number; status?: string } = {};
				if (tmdbId > 0) args.tmdbId = tmdbId;
				args.status = status;
				const res = await updateCatalogItem(statisticsItem.country, statisticsItem.type, catalogItem.id, args);
				if (res.status === 200) {
					// Move to next record
					await toNextItem(catalogItem);
					setIsUploading(false);
				} else {
					setIsUploading(false);
				}
			} catch (error) {
				setIsUploading(false);
				console.log(error);
				alert('Something went wrong, please try again later');
			}
		},
		[tmdbId, status, statisticsItem, catalogItem, toNextItem]
	);

	const searchPressed = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();
			try {
				searchStatus.current = 1;
				tmdbResultsRef.current = [];
				setTmdbResultIndex(0);
				setIsSearching(true);
				let queryString = `type=${statisticsItem.type}`;
				if (searchTmdbId > 0) queryString = `${queryString}&tmdbId=${searchTmdbId}`;
				else if (searchImdbId) queryString = `${queryString}&imdbId=${searchImdbId}`;
				else if (searchTitle) queryString = `${queryString}&name=${searchTitle}`;

				const res = await searchTmdbItem(queryString);
				if (res.status === 200) {
					// Show search results
					tmdbResultsRef.current = (await res.json()).data;
					if (tmdbResultsRef.current.length > 0) {
						searchStatus.current = 2;
					} else {
						searchStatus.current = 3;
					}
					console.log(tmdbResultsRef.current);
				} else if (res.status === 404) {
					searchStatus.current = 3;
				} else {
					searchStatus.current = 4;
				}
				setIsSearching(false);
			} catch (error) {
				searchStatus.current = 4;
				setIsSearching(false);
				console.log(error);
			}
		},
		[searchTmdbId, searchImdbId, searchTitle, statisticsItem]
	);

	const refreshItem = async () => {
		try {
			setIsRefreshing(true);
			const res = await refreshCatalogItem(statisticsItem.country, statisticsItem.source, statisticsItem.type, String(catalogItem.id));
			if (res.status === 200) {
				setIsRefreshing(false);
			} else {
				setIsRefreshing(false);
			}
		} catch (error) {
			setIsRefreshing(false);
			console.log(error);
			alert('Something went wrong, please try again later');
		}
	};

	const getSearchTip = function () {
		switch (searchStatus.current) {
			case 1:
				return '(searching)';
			case 3:
				return '(no result)';
			default:
				return '';
		}
	};

	if (!catalogItem || !statisticsItem) return <div />;

	const currentTmdbResult = get(tmdbResultsRef.current, `[${tmdbResultIndex}]`);
	const isMovie = statisticsItem.type.toLocaleLowerCase() === 'movie';
	const searchTip = getSearchTip();

	const extra = catalogItem.extra ? JSON.parse(catalogItem.extra) : null;

	// Update state to next catalog
	if (catalogItem.id !== catalogItemIdRef.current) {
		catalogItemIdRef.current = catalogItem.id;
		setTmdbId(catalogItem.tmdbId);
		setStatus(catalogItem.status);
		setSearchTmdbId(catalogItem.tmdbId);
		setSearchImdbId('');
		setSearchTitle(catalogItem.tmdbId > 0 ? '' : catalogItem.title);
	}

	return (
		<div>
			<Grid container spacing={1}>
				{/** Catalog */}
				<Grid item md={12} lg={6} style={{ border: '1px solid blue', padding: '0 20px' }}>
					<form className={classes.form} onSubmit={nextPressed}>
						<Grid container spacing={2} justifyContent="center">
							<Grid item xs={12} sm={4}>
								<Button
									fullWidth
									disabled={isUploading || isFetchingList || isRefreshing}
									variant="outlined"
									color="primary"
									className={classes.submit}
									onClick={backToList}
								>
									CANCEL
								</Button>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Button
									fullWidth
									disabled={isUploading || isFetchingList || isRefreshing}
									type="submit"
									variant="contained"
									color="primary"
									className={classes.submit}
								>
									{isUploading ? 'UPLOADING' : isLastItem ? 'SAVE' : 'NEXT'}
								</Button>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Button
									fullWidth
									disabled={isUploading || isFetchingList || isRefreshing}
									variant="contained"
									color="primary"
									className={classes.submit}
									onClick={refreshItem}
								>
									{isRefreshing ? 'REFRESHING' : 'REFRESH'}
								</Button>
							</Grid>
						</Grid>
						<TextField disabled className="fifth" label="Id" value={catalogItem.id} />
						<TextField disabled className="oneThird" label="Title" value={catalogItem.title} />
						<TextField disabled className="oneThird" label="Original Title" value={catalogItem.originalTitle} />
						<TextField disabled className="quarter" label="Last Updated" value={moment(catalogItem.lastUpdated).format('YYYY-MM-DD HH:mm')} />
						<TextField
							autoFocus
							className="fifth"
							type="number"
							id="tmdb_id"
							label="TMDB Id"
							name="tmdb_id"
							value={tmdbId > 0 ? tmdbId : ''}
							onChange={onValueChange}
						/>
						<FormControl component="fieldset" className="half">
							<FormLabel component="legend">Status</FormLabel>
							<RadioGroup row id="status" name="status" value={status} onChange={onValueChange}>
								<FormControlLabel value="new" control={<Radio />} label="new" />
								<FormControlLabel value="moderated" control={<Radio />} label="moderated" />
								<FormControlLabel value="inactive" control={<Radio />} label="inactive" />
							</RadioGroup>
						</FormControl>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={4}>
								<a target="_blank" rel="noopener noreferrer" href={catalogItem.posterUrl}>
									<img src={catalogItem.posterUrl} alt={'img'} className={classes.poster} />
								</a>
							</Grid>
							<Grid item xs={12} sm={8}>
								<p>
									{`Release year: ${catalogItem.releaseYear}`}
									{catalogItem.durationMin ? <span>&nbsp;&nbsp;{`Duration: ${catalogItem.durationMin}`}</span> : null}
								</p>
								<p className={classes.url}>
									Content url: &nbsp;
									<a target="_blank" rel="noopener noreferrer" href={catalogItem.contentUrl} title={catalogItem.contentUrl}>
										{`${catalogItem.contentUrl.slice(
											0,
											catalogItem.contentUrl.length > 30 ? 25 : catalogItem.contentUrl.length - 5
										)}...${catalogItem.contentUrl.slice(catalogItem.contentUrl.length - 5, catalogItem.contentUrl.length)}`}
									</a>
								</p>
								<p className={classes.url}>
									Crawl url: &nbsp;
									<a target="_blank" rel="noopener noreferrer" href={catalogItem.crawlUrl} title={catalogItem.crawlUrl}>
										{catalogItem.crawlUrl}
									</a>
								</p>
								<p style={{ maxHeight: '160px', overflow: 'scroll' }}>Description: &nbsp;{catalogItem.description}</p>
							</Grid>
						</Grid>
						<Grid>
							<Table className={classes.table} size="small">
								<TableHead>
									<TableRow>
										<TableCell style={{ width: '30%' }}>contentUrl</TableCell>
										<TableCell style={{ width: '20%' }}>type</TableCell>
										<TableCell style={{ width: '12%' }}>language</TableCell>
										<TableCell style={{ width: '12%' }}>quality</TableCell>
										<TableCell style={{ width: '12%' }}>price</TableCell>
										<TableCell style={{ width: '14%' }}>currency</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{catalogItem.offers.map((offer) => {
										return (
											<TableRow key={offer.id}>
												<TableCell style={{ fontSize: '12px', padding: '6px 4px' }} className={classes.cell}>
													<span>u: </span>
													<a target="_blank" rel="noopener noreferrer" href={offer.contentUrl} title={offer.contentUrl}>
														{`${offer.contentUrl.slice(0, offer.contentUrl.length > 30 ? 25 : offer.contentUrl.length - 5)}...${offer.contentUrl.slice(
															offer.contentUrl.length - 5,
															offer.contentUrl.length
														)}`}
													</a>
													<br />
													<span>d: </span>
													<a target="_blank" rel="noopener noreferrer" href={offer.deeplinkUrl ? offer.deeplinkUrl : undefined} title={offer.contentUrl}>
														{offer.deeplinkUrl ?? 'N/A'}
													</a>
												</TableCell>
												<TableCell className={classes.cell}>{offer.type}</TableCell>
												<TableCell>{offer.audioLanguage}</TableCell>
												<TableCell>{offer.videoQuality}</TableCell>
												<TableCell>{offer.price}</TableCell>
												<TableCell>{offer.currency}</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<div>IMDB link:</div>
								{extra && extra.imdbId ? (
									<a target="_blank" rel="noopener noreferrer" href={`https://www.imdb.com/title/${extra.imdbId}`}>
										{`https://www.imdb.com/title/${extra.imdbId}`}
									</a>
								) : null}
								<div style={{ marginTop: '10px' }}>Casts:</div>
								{catalogItem.casts ? (
									<ReactJson displayDataTypes={false} groupArraysAfterLength={500} name={false} src={JSON.parse(catalogItem.casts)} />
								) : null}
							</Grid>
							<Grid item xs={12} sm={6}>
								<div>Extra:</div>
								{extra ? <ReactJson displayDataTypes={false} groupArraysAfterLength={500} name={false} src={extra} /> : null}
								<div style={{ marginTop: '10px' }}>Crews:</div>
								{catalogItem.crews ? (
									<ReactJson displayDataTypes={false} groupArraysAfterLength={500} name={false} src={JSON.parse(catalogItem.crews)} />
								) : null}
							</Grid>
						</Grid>
					</form>
				</Grid>
				{/** TMDB */}
				<Grid item md={12} lg={6} style={{ border: '1px solid grey', padding: '0 20px' }}>
					{/* <div>Search on TMDB</div> */}
					<form className={classes.form} onSubmit={searchPressed}>
						<TextField
							className="fifth"
							type="number"
							id="search_tmdb_id"
							label="TMDB Id"
							name="search_tmdb_id"
							value={searchTmdbId > 0 ? searchTmdbId : ''}
							onChange={onValueChange}
						/>
						<TextField className="fifth" id="search_imdb_id" label="IMDB Id" name="search_imdb_id" value={searchImdbId} onChange={onValueChange} />
						<TextField className="pointFour" id="search_title" label="Title" name="search_title" value={searchTitle} onChange={onValueChange} />
						<Button disabled={isSearching} className="searchButton" type="submit" variant="contained" color="primary">
							{isSearching ? 'SEARCHING' : 'SEARCH'}
						</Button>
						<h3>{`Search results: ${searchTip}`}</h3>
						{currentTmdbResult ? (
							<React.Fragment>
								{tmdbResultsRef.current.length > 1 ? (
									<Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginBottom: '10px' }}>
										<Grid item xs={12} sm={4}>
											<Button
												fullWidth
												disabled={tmdbResultIndex < 1}
												variant="outlined"
												color="primary"
												onClick={() => setTmdbResultIndex(tmdbResultIndex - 1)}
											>
												PREV
											</Button>
										</Grid>
										<Grid item xs={12} sm={1}>{`${tmdbResultIndex + 1}/${tmdbResultsRef.current.length}`}</Grid>
										<Grid item xs={12} sm={4}>
											<Button
												fullWidth
												disabled={tmdbResultIndex > tmdbResultsRef.current.length - 2}
												variant="outlined"
												color="primary"
												onClick={() => setTmdbResultIndex(tmdbResultIndex + 1)}
											>
												NEXT
											</Button>
										</Grid>
									</Grid>
								) : null}
								<TextField disabled className="fifth" label="Id" value={currentTmdbResult.id} />
								<TextField disabled className="pointFour" label="Title" value={isMovie ? currentTmdbResult.title : currentTmdbResult.name} />
								<TextField
									disabled
									className="pointFour"
									label="Original Title"
									value={isMovie ? currentTmdbResult.original_title : currentTmdbResult.original_name}
								/>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={4}>
										<a target="_blank" rel="noopener noreferrer" href={`https://image.tmdb.org/t/p/original${currentTmdbResult.poster_path}`}>
											<img src={`https://image.tmdb.org/t/p/w500${currentTmdbResult.poster_path}`} alt={'img'} className={classes.poster} />
										</a>
									</Grid>
									<Grid item xs={12} sm={8}>
										<p>
											{`Release year: ${isMovie ? currentTmdbResult.release_date : currentTmdbResult.first_air_date}`}
											{currentTmdbResult.runtime ? <span>&nbsp;&nbsp;{`Duration: ${currentTmdbResult.runtime}`}</span> : null}
										</p>
										<p className={classes.url}>
											TMDB url: &nbsp;
											<a target="_blank" rel="noopener noreferrer" href={`https://www.themoviedb.org/${isMovie ? 'movie' : 'tv'}/${currentTmdbResult.id}`}>
												{`https://www.themoviedb.org/${isMovie ? 'movie' : 'tv'}/${currentTmdbResult.id}`}
											</a>
										</p>
										<p style={{ maxHeight: '160px', overflow: 'scroll' }}>Description: &nbsp;{currentTmdbResult.overview}</p>
									</Grid>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={6}>
											<div>Casts:</div>
											{currentTmdbResult.credits.cast ? (
												<ReactJson
													displayDataTypes={false}
													groupArraysAfterLength={500}
													name={false}
													src={map(currentTmdbResult.credits.cast, (cast) => pick(cast, ['name', 'character']))}
												/>
											) : null}
										</Grid>
										<Grid item xs={12} sm={6}>
											<div>Crews:</div>
											{currentTmdbResult.credits.crew ? (
												<ReactJson
													displayDataTypes={false}
													groupArraysAfterLength={500}
													name={false}
													src={map(currentTmdbResult.credits.crew, (crew) => pick(crew, ['name', 'job']))}
												/>
											) : null}
										</Grid>
									</Grid>
								</Grid>
							</React.Fragment>
						) : null}
					</form>
				</Grid>
			</Grid>
		</div>
	);
}
