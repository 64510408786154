import React, { useCallback, useEffect, useState } from 'react';
import { CssBaseline, AppBar, Toolbar, IconButton, Typography, Drawer, Divider } from '@material-ui/core';
import { Redirect, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import PreviewTV from '../previewTV/PreviewTV';
import Game from '../game/Game';
import AppMenu from './AppMenu';
import { deleteCookies } from '../../utils/util';
import pkJson from '../../../package.json';
import CatalogCrawlers from '../catalogCrawlers/CatalogCrawlers';
import TmdbMovies from '../tmdbMovies/TmdbMovies';
import TmdbSeries from '../tmdbSeries/TmdbSeries';
import HunterTool from '../hunterTool/HunterTool';
import TmdbPerson from '../tmdbPerson/TmdbPerson';

const drawerWidth = 240;
const router: { [key: string]: { index: number; title: string; path: string; component: React.ReactNode } } = {
	game: {
		index: 0,
		title: 'Game Management',
		path: '/game',
		component: <Game />
	},
	previewtv: {
		index: 1,
		title: 'Preview TV',
		path: '/previewtv',
		component: <PreviewTV />
	},
	catalog_crawlers: {
		index: 2,
		title: 'Catalog Crawlers',
		path: '/catalog_crawlers',
		component: <CatalogCrawlers />
	},
	tmdb_movies: {
		index: 3,
		title: 'TMDB Movies',
		path: '/tmdb_movies',
		component: <TmdbMovies />
	},
	tmdb_series: {
		index: 4,
		title: 'TMDB Series',
		path: '/tmdb_series',
		component: <TmdbSeries />
	},
	tmdb_person: {
		index: 5,
		title: 'TMDB Person',
		path: '/tmdb_person',
		component: <TmdbPerson />
	},
	hunter_tool: {
		index: 6,
		title: 'Hunter tool',
		path: '/hunter_tool',
		component: <HunterTool />
	}
};

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex'
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	toolbar: {
		paddingRight: 24 // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar
	},
	menuButton: {
		marginRight: 36
	},
	menuButtonHidden: {
		display: 'none'
	},
	title: {
		flexGrow: 1
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9)
		}
	},
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto'
	},
	appBarSpacer: theme.mixins.toolbar,
	versionContainer: {
		position: 'absolute',
		bottom: '10px',
		width: '100%'
	}
}));

export default function MainPage() {
	const classes = useStyles();
	const appModule = useLocation().pathname.slice(1).split('/')[0];

	const cookieKeys = document.cookie.split(';').map((data) => data.trim().split('=')[0]);
	const [isLogined, setIsLogined] = useState(cookieKeys.includes('tv_session') && cookieKeys.includes('tv_session_data'));
	const [open, setOpen] = useState(false);
	const [currentModule, setCurrentModule] = useState(appModule);

	useEffect(() => {
		window.addEventListener('scroll-to-top', () => {
			const mainDiv = document.getElementById('main-id');
			if (mainDiv && mainDiv.scrollTop > 0) {
				mainDiv.scrollTo({ top: 0, behavior: 'smooth' });
			}
		});
	}, []);

	const existApp = useCallback(() => {
		deleteCookies();
		setIsLogined(false);
	}, []);

	if (!isLogined) {
		return <Redirect to="login" />;
	}

	if (currentModule !== appModule) {
		return <Redirect to={router[currentModule].path} />;
	}

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
				<Toolbar className={classes.toolbar}>
					<IconButton
						edge="start"
						color="inherit"
						arir-label="open drawer"
						className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
						onClick={() => setOpen(true)}
					>
						<MenuIcon />
					</IconButton>
					<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
						{router[currentModule].title}
					</Typography>
					<IconButton color="inherit" onClick={existApp}>
						<ExitToAppIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				classes={{
					paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
				}}
				open={open}
			>
				<div className={classes.toolbarIcon}>
					<IconButton onClick={() => setOpen(false)}>
						<ChevronLeftIcon />
					</IconButton>
				</div>
				<Divider />
				<AppMenu selectedModule={currentModule} callback={(module) => setCurrentModule(module)} />
				<Typography variant="body1" component="div" align="center" className={classes.versionContainer}>
					{`v${pkJson.version}`}
				</Typography>
			</Drawer>
			<main className={classes.content} id="main-id">
				<div className={classes.appBarSpacer} />
				{router[currentModule].component}
			</main>
		</div>
	);
}
