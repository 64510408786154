import React from 'react';
import { makeStyles, Grid, FormControl, Select, InputLabel, Button, TextField, TextFieldProps } from '@material-ui/core';
import { ENV_LIST, REGION_LIST, LANGUAGE_LIST, SDK_VERSION_LIST, CLIENT_LIST } from '../../utils/Const';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface SelectionProps {
	isLoading: boolean;
	defaultOptions: { env: number; client: string; region: string; language: string; sdkVersion: number };
	versions: { [_: string]: string };
	updateOption: (key: 'env' | 'client' | 'region' | 'language' | 'sdkVersion' | 'refreshCache', value: string | number) => void;
}

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(0),
		width: '80%'
	}
}));

export default function SelectOption(props: SelectionProps) {
	const classes = useStyles();
	const { isLoading, defaultOptions, versions, updateOption } = props;

	return (
		<Grid container spacing={0}>
			<Grid container item xs={10} md={2} justifyContent="center">
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel htmlFor="outlined-env">ENV</InputLabel>
					<Select
						native
						value={defaultOptions.env}
						onChange={(event) => updateOption('env', Number(event.target.value))}
						label="ENV"
						inputProps={{
							name: 'env',
							id: 'outlined-env'
						}}
					>
						{ENV_LIST.map((option) => (
							<option key={option.value} value={option.value}>
								{`${option.name} ${versions[option.env] ?? ''}`}
							</option>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid container item xs={10} md={2} justifyContent="center">
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel htmlFor="outlined-client">Client</InputLabel>
					<Select
						native
						value={defaultOptions.client}
						onChange={(event) => updateOption('client', event.target.value as string)}
						label="Client"
						inputProps={{
							name: 'client',
							id: 'outlined-client'
						}}
					>
						{/* Only show test token when env is Staging */}
						{(defaultOptions.env === 1 ? CLIENT_LIST : CLIENT_LIST.slice(0, CLIENT_LIST.length - 2)).map((option) => (
							<option key={option.value} value={option.value}>
								{option.name}
							</option>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid container item xs={10} md={2} justifyContent="center">
				<Autocomplete
					id="combo-box-region"
					value={REGION_LIST.find((region) => region.value === defaultOptions.region)}
					options={REGION_LIST}
					getOptionLabel={(option: { name: string }) => option.name}
					className={classes.formControl}
					onChange={(_, values) => (values ? updateOption('region', values.value) : null)}
					renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} label="Region" variant="outlined" />}
				/>
			</Grid>
			<Grid container item xs={10} md={2} justifyContent="center">
				<Autocomplete
					id="combo-box-language"
					value={LANGUAGE_LIST.find((language) => language.value === defaultOptions.language)}
					options={LANGUAGE_LIST}
					getOptionLabel={(option: { name: string }) => option.name}
					className={classes.formControl}
					onChange={(_, values) => (values ? updateOption('language', values.value) : null)}
					renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} label="Language" variant="outlined" />}
				/>
			</Grid>
			<Grid container item xs={10} md={2} justifyContent="center">
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel htmlFor="outlined-sdk">SDK Version</InputLabel>
					<Select
						native
						value={defaultOptions.sdkVersion}
						onChange={(event) => updateOption('sdkVersion', Number(event.target.value))}
						label="SDK Version"
						inputProps={{
							name: 'sdkVersion',
							id: 'outlined-sdkVersion'
						}}
					>
						{SDK_VERSION_LIST.map((option) => (
							<option key={option.value} value={option.value}>
								{option.name}
							</option>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid container item xs={10} md={2} justifyContent="center">
				<Button
					variant="contained"
					color="secondary"
					size="large"
					disabled={isLoading}
					onClick={() => {
						updateOption('refreshCache', '');
					}}
				>
					Refresh cache
				</Button>
			</Grid>
		</Grid>
	);
}
