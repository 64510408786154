import React, { useEffect, useRef, useState } from 'react';
import { Container, Grid, LinearProgress, makeStyles, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import TmdbPersonList, { TmdbPersonItem } from './TmdbPersonList';
import { fetchTmdbPersonList } from '../../api';
import { formatTmdbPerson } from '../../utils/util';

export const PAGE_SIZE = 10;

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(1)
	},
	paper: {
		padding: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column'
	},
	progress: {
		height: '4px'
	}
}));

export default function TmdbPerson() {
	const history = useHistory();
	const classes = useStyles();
	const personRef = useRef<TmdbPersonItem[]>([]);
	const totalPersonCountRef = useRef(0);
	const [searchText, setSearchText] = useState('');
	const [forceRefresh, setForceRefresh] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const pageInPath = /page=(\d+)/.exec(window.location.search);
	const [currentPage, setCurrentPage] = useState(pageInPath && pageInPath.length > 1 ? Number(pageInPath[1]) : 1);

	//component lifecycle
	useEffect(() => {
		async function runEffect() {
			setIsLoading(true);
			try {
				const res = await fetchTmdbPersonList(searchText, currentPage, PAGE_SIZE);
				if (res.status === 200) {
					const data = await res.json();
					personRef.current = data.data.map((persons: any) => formatTmdbPerson(persons));
					totalPersonCountRef.current = data.totalNum;
					setIsLoading(false);
				} else if (res.status === 401) {
					history.replace('/login');
				} else {
					setIsLoading(false);
				}
			} catch (error) {
				setIsLoading(false);
				console.log(error);
			}
		}
		runEffect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, currentPage, forceRefresh]);

	return (
		<Container maxWidth="xl" className={classes.container}>
			{isLoading ? <LinearProgress /> : <div className={classes.progress} />}
			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<TmdbPersonList
						currentPage={currentPage}
						totalCount={totalPersonCountRef.current}
						searchText={searchText}
						personItems={personRef.current}
						updateSearchText={(text) => setSearchText(text)}
						startSearch={() => {
							setForceRefresh((refresh) => !refresh);
						}}
						updatePage={(pageIndex) => {
							setCurrentPage(pageIndex);
							history.push(`/tmdb_person?page=${pageIndex}`);
						}}
					/>
				</Paper>
			</Grid>
		</Container>
	);
}
