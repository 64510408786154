import React from 'react';
import { makeStyles, Grid, FormControl, Select, InputLabel, Button, TextField, TextFieldProps } from '@material-ui/core';
import { ENV_LIST, REGION_LIST } from '../../utils/Const';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface SelectionProps {
	isLoading: boolean;
	defaultOptions: { env: number; regionCode: string; channelId: string };
	updateOption: (
		key: 'env' | 'regionCode' | 'refreshCache' | 'clearVideoBlacklist' | 'channelId' | 'blacklistChannel' | 'clearChannelBlacklist',
		value: string | number
	) => void;
}

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(0),
		width: '100%'
	}
}));

export default function SelectOption(props: SelectionProps) {
	const classes = useStyles();
	const { isLoading, defaultOptions, updateOption } = props;

	return (
		<Grid container spacing={1}>
			<Grid container item xs={6} spacing={1}>
				<Grid container item xs={5} justifyContent="flex-start">
					<FormControl variant="outlined" className={classes.formControl}>
						<InputLabel htmlFor="outlined-env">ENV</InputLabel>
						<Select
							native
							value={defaultOptions.env}
							onChange={(event) => updateOption('env', Number(event.target.value))}
							label="ENV"
							inputProps={{
								name: 'env',
								id: 'outlined-env'
							}}
						>
							{ENV_LIST.map((option) => (
								<option key={option.value} value={option.value}>
									{option.name}
								</option>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid container item xs={5} justifyContent="flex-end">
					<Autocomplete
						id="combo-box-region"
						value={REGION_LIST.find((region) => region.value === defaultOptions.regionCode)}
						options={REGION_LIST}
						getOptionLabel={(option: { name: string }) => option.name}
						className={classes.formControl}
						onChange={(_, values) => (values ? updateOption('regionCode', values.value) : null)}
						renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} label="Region" variant="outlined" />}
					/>
				</Grid>
				<Grid container item xs={5} justifyContent="center">
					<Button
						fullWidth
						variant="contained"
						color="primary"
						size="large"
						disabled={isLoading}
						onClick={() => {
							updateOption('refreshCache', '');
						}}
					>
						Refresh Cache
					</Button>
				</Grid>
				<Grid container item xs={5} justifyContent="center">
					<Button
						fullWidth
						variant="contained"
						color="secondary"
						size="large"
						disabled={isLoading}
						onClick={() => {
							updateOption('clearVideoBlacklist', '');
						}}
					>
						Clear Video Blacklist
					</Button>
				</Grid>
			</Grid>
			<Grid container item xs={6} spacing={1} justifyContent="flex-end">
				<Grid container item xs={10} justifyContent="center">
					<TextField
						fullWidth
						style={{ marginTop: '0px' }}
						variant="outlined"
						margin="normal"
						id="channelId"
						label="Channel ID"
						name="channelId"
						value={defaultOptions.channelId}
						onChange={(e) => {
							updateOption('channelId', e.target.value);
						}}
					/>
				</Grid>
				<Grid container item xs={5} justifyContent="center">
					<Button
						fullWidth
						variant="contained"
						color="primary"
						size="large"
						disabled={isLoading}
						onClick={() => {
							updateOption('blacklistChannel', '');
						}}
					>
						Blacklist channel
					</Button>
				</Grid>
				<Grid container item xs={5} justifyContent="center">
					<Button
						fullWidth
						variant="contained"
						color="secondary"
						size="large"
						disabled={isLoading}
						onClick={() => {
							updateOption('clearChannelBlacklist', '');
						}}
					>
						Clear Channel Blacklist
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}
