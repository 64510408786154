import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';

interface TVMenuProps {
	menus: { order: number; name: string; type: string; iconUrl: string }[];
	currentMenu: string;
	updateCurrentMenu(type: string): void;
}

export default function TVMenu(props: TVMenuProps) {
	const { menus, currentMenu, updateCurrentMenu } = props;

	return (
		<List>
			{menus.map((menu) => (
				<ListItem button selected={currentMenu === menu.type} key={menu.order} onClick={() => updateCurrentMenu(menu.type)}>
					<ListItemIcon>
						<img src={menu.iconUrl} alt="" width="40" height="40" />
					</ListItemIcon>
					<ListItemText primary={menu.name} />
				</ListItem>
			))}
		</List>
	);
}
