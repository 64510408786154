import { StatisticItemEntity } from './components/catalogCrawlers/CatalogOverview';

const BASE_URL: string = process.env.REACT_APP_API_URL || '';
console.log(`--> BE: ${BASE_URL}`);

export const login = (username: string, password: string) => {
	return fetch(`${BASE_URL}/auth/login`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include',
		body: JSON.stringify({ username, password })
	});
};

export const fetchGameList = (page: number = 0, pageSize: number = 20, isEnabled: number = -1, name: string = '') => {
	return fetch(`${BASE_URL}/game/list?page=${page}&pageSize=${pageSize}&isEnabled=${isEnabled > -1 ? isEnabled : ''}&name=${encodeURIComponent(name)}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchGameById = (gameId: number) => {
	return fetch(`${BASE_URL}/game/${gameId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const updateGame = (id: number, args: any) => {
	return fetch(`${BASE_URL}/game/${id}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include',
		body: JSON.stringify(args)
	});
};

export const deleteGame = (id: number) => {
	return fetch(`${BASE_URL}/game/${id}`, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const searchIgdbGame = async (id: number | undefined, name: string = '') => {
	return fetch(`${BASE_URL}/game/igdb?id=${id || ''}&name=${name}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchRecMenu = (args: any) => {
	return fetch(`${BASE_URL}/rec/menu`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include',
		body: JSON.stringify(args)
	});
};

export const fetchRecMenuData = (args: any) => {
	return fetch(`${BASE_URL}/rec/menuData`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include',
		body: JSON.stringify(args)
	});
};

export const fetchRecMenuItem = (args: any) => {
	return fetch(`${BASE_URL}/rec/menuItem`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include',
		body: JSON.stringify(args)
	});
};

export const fetchRecMenuItemExtra = (args: any) => {
	return fetch(`${BASE_URL}/rec/menuItemExtra`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include',
		body: JSON.stringify(args)
	});
};

export const textToFulfillment = (args: any) => {
	return fetch(`${BASE_URL}/rec/texttofulfillment`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include',
		body: JSON.stringify(args)
	});
};

export const fetchHunterStatistics = (countryCode?: string) => {
	return fetch(`${BASE_URL}/hunter/catalog/statistics?countryCode=${countryCode ?? ''}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchCatalogList = (item: StatisticItemEntity, searchText: string, orderFlag: number, page: number = 0, pageSize: number = 20) => {
	return fetch(
		`${BASE_URL}/hunter/catalog/list?countryCode=${item.country}&crawlSource=${item.source}&type=${item.type}&status=${item.status}&query=${searchText}&orderFlag=${orderFlag}&page=${page}&pageSize=${pageSize}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: 'include'
		}
	);
};

export const deleteCatalog = (countryCode: string, crawlSource: string, type: string) => {
	return fetch(`${BASE_URL}/hunter/catalog/delete?countryCode=${countryCode}&crawlSource=${crawlSource}&type=${type}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchCatalogItemById = (countryCode: string, type: string, id: number) => {
	return fetch(`${BASE_URL}/hunter/catalog/${countryCode}/${type}/${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const searchTmdbItem = (queryString: string) => {
	return fetch(`${BASE_URL}/hunter/catalog/tmdb?${queryString}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const updateCatalogItem = (country: string, type: string, id: number, args: any) => {
	return fetch(`${BASE_URL}/hunter/catalog/${country}/${type}/${id}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include',
		body: JSON.stringify(args)
	});
};

export const refreshCatalogItem = (country: string, source: string, type: string, ids: string) => {
	return fetch(`${BASE_URL}/hunter/catalog/refresh/${country}/${source}/${type}/${ids}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const refreshTmdbMetadata = (type: string, id: number) => {
	return fetch(`${BASE_URL}/hunter/tmdb/refresh/${type}/${id}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchTmdbMovieList = (status: string, searchText: string, page: number = 0, pageSize: number = 20) => {
	return fetch(`${BASE_URL}/hunter/tmdb/movie/list?status=${status}&query=${searchText}&page=${page}&pageSize=${pageSize}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchTmdbSeriesList = (seriesType: string, inProduction = -1, status: string, searchText: string, page: number = 0, pageSize: number = 20) => {
	return fetch(
		`${BASE_URL}/hunter/tmdb/series/list?seriesType=${seriesType}&inProduction=${
			inProduction > -1 ? inProduction : ''
		}&status=${status}&query=${searchText}&page=${page}&pageSize=${pageSize}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: 'include'
		}
	);
};

export const fetchTmdbItemById = (type: string, id: number) => {
	return fetch(`${BASE_URL}/hunter/tmdb/${type}/${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchMovieById = (id: number) => {
	return fetch(`${BASE_URL}/hunter/movie/${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchSeriesById = (id: number) => {
	return fetch(`${BASE_URL}/hunter/series/${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchRecVersions = () => {
	return fetch(`${BASE_URL}/rec/versions`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchCrawlTracker = () => {
	return fetch(`${BASE_URL}/hunter/catalog/tracker`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const crawlCatalogSource = (country: string, source: string) => {
	return fetch(`${BASE_URL}/hunter/catalog/crawl/${country}/${source}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const refreshCatalogSource = (country: string, source: string) => {
	return fetch(`${BASE_URL}/hunter/catalog/refresh/${country}/${source}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const refreshDeeplink = (countries: string[]) => {
	const queries = countries.map((country) => `countries[]=${country}`);
	return fetch(`${BASE_URL}/hunter/catalog-crawler/refreshdeeplink?${queries.join('&')}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const indexCountry = (country: string, type: string) => {
	return fetch(`${BASE_URL}/hunter/indexer/${country}/${type}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchTmdbPersonList = (searchText: string, page: number = 0, pageSize: number = 20) => {
	return fetch(`${BASE_URL}/hunter/tmdb/person/list?query=${searchText}&page=${page}&pageSize=${pageSize}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchV1Recommendation = (regionCode: string, serverType: number = 1, forceRefresh: boolean = false) => {
	return fetch(`${BASE_URL}/rec/recommendation?regionCode=${regionCode}&serverType=${serverType}&forceRefresh=${forceRefresh ? 1 : 0}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchBlacklist = (regionCode: string, serverType: number = 1) => {
	return fetch(`${BASE_URL}/rec/blacklist?regionCode=${regionCode}&serverType=${serverType}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const pushBlacklist = (regionCode: string, blacklist: object, serverType: number = 1) => {
	return fetch(`${BASE_URL}/rec/blacklist`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include',
		body: JSON.stringify({
			regionCode,
			serverType,
			blacklist
		})
	});
};

export const fetchYoutubeChannelBlacklist = (regionCode: string, serverType: number = 1) => {
	return fetch(`${BASE_URL}/rec/youtubeChannelBlacklist?regionCode=${regionCode}&serverType=${serverType}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const pushYoutubeChannelBlacklist = (regionCode: string, blacklist: object, serverType: number = 1) => {
	return fetch(`${BASE_URL}/rec/youtubeChannelBlacklist`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include',
		body: JSON.stringify({
			regionCode,
			serverType,
			blacklist
		})
	});
};

export const fetchYoutubeChannelInfo = (channelId: string) => {
	return fetch(`${BASE_URL}/rec/youtubeChannelInfo?channelId=${channelId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchAsrAggregateData = () => {
	return fetch(`${BASE_URL}/asr/aggregate`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchAsrNewTask = (engine: string, locale: string, region: string, limit = 100) => {
	return fetch(`${BASE_URL}/asr/task/new?engine=${engine}&locale=${locale}&region=${region}&limit=${limit}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const fetchAsrTaskList = (engine: string, locale: string, region: string, limit = 50) => {
	return fetch(`${BASE_URL}/asr/task/list?engine=${engine}&locale=${locale}&region=${region}&limit=${limit}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};

export const updateAsrTask = (taskId: string, queryId: string, correction: string) => {
	return fetch(`${BASE_URL}/asr/task/update/${taskId}/${queryId}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include',
		body: JSON.stringify({
			correction
		})
	});
};

export const calculateAsrTaskWer = (taskId: string) => {
	return fetch(`${BASE_URL}/asr/task/wer/${taskId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	});
};
