import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

interface BlacklistVideosProps {
	items: { [id: string]: string }[];
}

const useStyles = makeStyles(() => ({
	container: {
		// overflowX: 'auto'
		flexWrap: 'wrap'
	},
	itemContainer: {
		padding: '10px'
	},
	posterContainer: {
		width: '250px',
		height: '140px',
		padding: '10px',
		flexShrink: 0,
		transition: '0.3s',
		'&:hover': {
			cursor: 'pointer',
			padding: '5px'
		}
	},
	poster: {
		width: '230px',
		height: '130px',
		transition: '0.3s',
		opacity: 0.85,
		'&:hover': {
			width: '240px',
			opacity: 1
		}
	}
}));

export default function BlacklistVideos(props: BlacklistVideosProps) {
	const classes = useStyles();
	const { items } = props;

	return (
		<Grid style={{ border: '2px dashed red', padding: '10px', marginBottom: '10px' }}>
			<Typography variant="h5" color="error">
				{'Blacklist Videos'}
			</Typography>
			<Grid container wrap="nowrap" className={classes.container} alignItems="center">
				{items
					? items.map((item, key) => {
							const posterUrl = `https://i.ytimg.com/vi/${item.id}/mqdefault.jpg`;
							const webLink = `https://www.youtube.com/watch?v=${item.id}`;

							return (
								<div className={classes.itemContainer} key={`${item.id}-${key}`}>
									<div className={classes.posterContainer}>
										<a target="_blank" rel="noopener noreferrer" href={webLink}>
											<img alt="img" src={posterUrl} className={classes.poster} />
										</a>
									</div>
								</div>
							);
					  })
					: null}
			</Grid>
		</Grid>
	);
}
