import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router';
import { LinearProgress, Container, makeStyles, Grid, Paper } from '@material-ui/core';
import GameDetail from './GameDetail';
import GameList, { GameEntity } from './GameList';
import { fetchGameById, fetchGameList } from '../../api';

export const PAGE_SIZE = 10;

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(1)
	},
	paper: {
		padding: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column'
	},
	progress: {
		height: '4px'
	}
}));

export default function Game() {
	const history = useHistory();
	const classes = useStyles();

	const totalGameCountRef = useRef(0);
	const gameListRef = useRef([]);
	const [enabled, setEnabled] = useState(0);
	const [searchText, setSearchText] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentGameId, setCurrentGameId] = useState(-1);
	const [refresh, setRefresh] = useState(false);

	//component lifecycle
	useEffect(() => {
		async function runEffect() {
			if (currentGameId > -1) return;
			setIsLoading(true);
			try {
				const res = await fetchGameList(currentPage, PAGE_SIZE, enabled, searchText);
				if (res.status === 200) {
					const gameResult = await res.json();
					totalGameCountRef.current = gameResult.totalNum;
					gameListRef.current = gameResult.data;
					setIsLoading(false);
				} else if (res.status === 401) {
					history.replace('/login');
				} else {
					setIsLoading(false);
				}
			} catch (error) {
				setIsLoading(false);
				// alert('Something went wrong, please try again later');
				console.log(error);
			}
		}
		runEffect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, enabled, refresh, history]);

	useEffect(() => {
		async function runEffect() {
			if (totalGameCountRef.current > 0 || currentGameId < 0) return;
			setIsLoading(true);
			try {
				const res = await fetchGameById(currentGameId);
				if (res.status === 200) {
					const game = await res.json();
					totalGameCountRef.current = 1;
					gameListRef.current = [].concat(game.data);
					setIsLoading(false);
				} else if (res.status === 401) {
					history.replace('/login');
				} else {
					history.replace('/game');
					setIsLoading(false);
					setCurrentGameId(-1);
					setRefresh((refresh) => !refresh);
				}
			} catch (error) {
				setIsLoading(false);
				console.log(error);
			}
		}
		runEffect();
	}, [currentGameId, history]);

	const updateCurrentGameId = useCallback(
		(gameId: number) => {
			setCurrentGameId(gameId);
			if (gameId > -1 && gameListRef.current.length) {
				history.replace(`/game/${gameId}`);
			} else if (gameId === -1) {
				history.replace('/game');
			}
		},
		[history]
	);

	// Redirect to game home page when user keyin game detail page directly(https://admin.tv.saymosaic.com/game/1077)
	// TODO: Do not redirect to game home page, but go to detail page and fetch game detail simultaneously
	const paths = useLocation().pathname.slice(1).split('/');
	if (paths.length > 1 && gameListRef.current.length === 0 && currentGameId < 0) {
		const gameId = Number(paths[1]);
		if (gameId > 0) {
			setCurrentGameId(gameId);
		} else {
			return <Redirect to="/game" />;
		}
	}

	return (
		<div>
			<Container maxWidth="xl" className={classes.container}>
				{isLoading ? <LinearProgress /> : <div className={classes.progress} />}
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						{currentGameId < 0 ? (
							<GameList
								isSearching={isLoading}
								searchText={searchText}
								showEnabledFalse={enabled === 0}
								currentPage={currentPage}
								totalGameCount={totalGameCountRef.current}
								gameList={gameListRef.current}
								setShowEnabledFalse={(showEnabledFalse) => {
									setCurrentPage(1);
									setEnabled(showEnabledFalse ? 0 : -1);
								}}
								updatePage={(pageIndex) => setCurrentPage(pageIndex)}
								selectedGame={(gameId) => updateCurrentGameId(gameId)}
								updateSearchText={(searchText) => setSearchText(searchText)}
								startSearch={() => setRefresh((refresh) => !refresh)}
							/>
						) : (
							<GameDetail
								game={(gameListRef.current.find((data) => (data as any).id === currentGameId) as unknown) as GameEntity}
								updateCurrentGameId={(gameId) => {
									updateCurrentGameId(gameId);
									setRefresh((refresh) => !refresh);
								}}
							/>
						)}
					</Paper>
				</Grid>
			</Container>
		</div>
	);
}
