import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Login from './auth/Login';
import MainPage from './main/MainPage';

function App() {
	return (
		<Router>
			<Switch>
				<Route path="/login">
					<Login />
				</Route>
				<Route path={['/game', '/previewtv', '/catalog_crawlers', '/tmdb_movies', '/tmdb_series', '/tmdb_person', '/hunter_tool']}>
					<MainPage />
				</Route>
				<Route path="*">
					<Redirect to="/game" />
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
