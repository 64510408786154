import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, Table, TableHead, TableRow, TableCell, TableBody, TextField, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { groupBy, map, pick, uniqBy, keys } from 'lodash';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { TmdbSeriesItem } from './TmdbSeriesList';
import moment from 'moment';
import ReactJson from 'react-json-view';
import { IMG_PLACEHOLDER, PROFILE_PLACEHOLDER } from '../../utils/Const';
import { calRatio } from '../../utils/util';
import { fetchSeriesById, refreshCatalogItem, refreshTmdbMetadata } from '../../api';
import { CatalogItem } from '../tmdbMovies/TmdbMovieDetail';

interface TmdbSeriesDetailProps {
	seriesItem?: TmdbSeriesItem;
	backToHome: (id: number) => void;
}

interface Series {
	id: number;
	country_code: string;
	catalog_items: CatalogItem[];
}

const useStyle = makeStyles((theme) => ({
	container: {
		paddingBottom: theme.spacing(1),
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end'
	},
	poster: {
		height: 'auto',
		width: '95%'
	},
	table: {
		tableLayout: 'fixed',
		border: '1px solid grey',
		marginBottom: '10px'
	},
	divContianer: {
		marginTop: '10px'
	},
	smallPoster: {
		height: '200px',
		padding: '0px 10px'
	},
	smallBackdrop: {
		height: '120px',
		padding: '0px 10px'
	},
	profile: {
		height: '195px',
		width: '150px',
		padding: '0px 10px'
	},
	smallVideo: {
		height: '120px',
		padding: '0px 10px'
	}
}));

function renderSeriesOffer(series: Series) {
	const { country_code: countryCode, catalog_items: catalogItems } = series;
	return (
		<CardContent key={countryCode} style={{ paddingLeft: 0 }}>
			<Typography variant="h6" component="h6">
				{countryCode}
			</Typography>
			{catalogItems.map((catalogItem, index) => {
				const {
					type,
					content_source: source,
					crawl_source: crawlSource,
					content_url: url,
					audio_language: audioLanguage,
					currency,
					price,
					video_quality: videoQuality,
					catalog_item_parent_id: catalogItemParentId
				} = catalogItem;
				const sourceLogUrl = `https://mosaix-tv-cdn.mosaix.ai/assets/icons/source/${source.toLocaleLowerCase()}.png`;
				return (
					<Grid container key={`${source}-${index}`} alignItems="center">
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`/catalog_crawlers/${countryCode}/${crawlSource}?type=series&status=moderated&page=1&catalogId=${catalogItemParentId}`}
						>
							<img alt="logo" src={sourceLogUrl} style={{ height: '30px', width: 'auto', margin: '5px' }} />
						</a>
						<a target="_blank" rel="noopener noreferrer" href={url}>
							{type === 'free' || type === 'subscription' ? (
								<span style={{ fontSize: '16px' }}>{`${type} - [audio: ${audioLanguage}] - [quality: ${videoQuality}]`}</span>
							) : (
								<span style={{ fontSize: '16px' }}>{`${type} - $${price}(${currency}) - [audio: ${audioLanguage}] - [quality: ${videoQuality}]`}</span>
							)}
						</a>
					</Grid>
				);
			})}
		</CardContent>
	);
}

export default function TmdbSeriesDetail(props: TmdbSeriesDetailProps) {
	const { seriesItem, backToHome } = props;
	const classes = useStyle();
	const relatedSeriesRef = useRef<Series[]>([]);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [isFetchingOffer, setIsFetchingOffer] = useState(false);

	useEffect(() => {
		async function runEffect() {
			try {
				if (seriesItem?.id) {
					setIsFetchingOffer(true);
					const res = await fetchSeriesById(seriesItem.id);
					if (res.status === 200) {
						const data = await res.json();
						relatedSeriesRef.current = data;
						setIsFetchingOffer(false);
					} else {
						setIsFetchingOffer(false);
					}
				}
			} catch (error) {
				setIsFetchingOffer(false);
			}
		}
		runEffect();
	}, [seriesItem]);

	const refreshMetadata = async () => {
		try {
			if (!seriesItem) return;
			setIsRefreshing(true);
			const res = await refreshTmdbMetadata('series', seriesItem.id);
			if (res.status === 200) {
				setIsRefreshing(false);
			} else {
				setIsRefreshing(false);
			}
		} catch (error) {
			setIsRefreshing(false);
			console.log(error);
			alert('Something went wrong, please try again later');
		}
	};

	const refreshCatalog = async () => {
		relatedSeriesRef.current.forEach(async ({ country_code, catalog_items }) => {
			const uniqCatalogItems = uniqBy(catalog_items, 'catalog_item_parent_id');
			const groupBySource = groupBy(uniqCatalogItems, 'crawl_source');
			keys(groupBySource).forEach(async (source) => {
				const ids = groupBySource[source].map((catalogItem) => catalogItem.catalog_item_parent_id);
				await refreshCatalogItem(country_code, source, 'series', ids.join(','));
			});
		});
	};

	if (!seriesItem) return <div />;

	console.log('seriesItem:');
	console.log(seriesItem);

	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
				<Button
					startIcon={<ChevronLeftIcon />}
					onClick={() => {
						backToHome(seriesItem.id);
					}}
				>
					TMDB series List
				</Button>
				<div>
					{relatedSeriesRef.current.length > 0 ? (
						<Button variant="outlined" color="primary" onClick={refreshCatalog} style={{ marginRight: '20px' }}>
							REFRESH CATALOG
						</Button>
					) : null}
					<Button disabled={isRefreshing} variant="contained" color="primary" onClick={refreshMetadata}>
						{isRefreshing ? 'REFRESHING' : 'REFRESH METADATA'}
					</Button>
				</div>
			</div>

			{/** Main info */}
			<Grid container>
				<Grid item xs={12}>
					<Table size="small" className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>id</TableCell>
								<TableCell>imdbId</TableCell>
								<TableCell>status</TableCell>
								<TableCell>type</TableCell>
								<TableCell>firstAirDate</TableCell>
								<TableCell>lastAirDate</TableCell>
								<TableCell>numberOfSeasons</TableCell>
								<TableCell>numberOfEpisodes</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>
									<a target="_blank" rel="noopener noreferrer" href={`https://www.themoviedb.org/tv/${seriesItem.id}`}>
										{seriesItem.id}
									</a>
								</TableCell>
								<TableCell>
									{seriesItem.imdbId ? (
										<a target="_blank" rel="noopener noreferrer" href={`https://www.imdb.com/title/${seriesItem.imdbId}`}>
											{seriesItem.imdbId}
										</a>
									) : (
										seriesItem.imdbId
									)}
								</TableCell>
								<TableCell>{seriesItem.status}</TableCell>
								<TableCell>{seriesItem.type}</TableCell>
								<TableCell>{seriesItem.firstAirDate}</TableCell>
								<TableCell>{seriesItem.lastAirDate}</TableCell>
								<TableCell>{seriesItem.numberOfSeasons}</TableCell>
								<TableCell>{seriesItem.numberOfEpisodes}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<Table size="small" className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>name</TableCell>
								<TableCell>originalName</TableCell>
								<TableCell>originalLanguage / languages</TableCell>
								<TableCell>genres</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>{seriesItem.name}</TableCell>
								<TableCell>{seriesItem.originalName}</TableCell>
								<TableCell>{`${seriesItem.originalLanguage} / ${seriesItem.languages.join(',')}`}</TableCell>
								<TableCell>{seriesItem.genres.map((genre) => genre.name).join(',')}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<Table size="small" className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>originCountry</TableCell>
								<TableCell>episodeRuntime</TableCell>
								<TableCell>popularity</TableCell>
								<TableCell>voteAverage</TableCell>
								<TableCell>voteCount</TableCell>
								<TableCell>inProduction</TableCell>
								<TableCell>created</TableCell>
								<TableCell>lastUpdated</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>{seriesItem.originCountry}</TableCell>
								<TableCell>{seriesItem.episodeRuntime.join(',')}</TableCell>
								<TableCell>{seriesItem.popularity}</TableCell>
								<TableCell>{seriesItem.voteAverage}</TableCell>
								<TableCell>{seriesItem.voteCount}</TableCell>
								<TableCell>{seriesItem.inProduction > 0 ? 'Y' : 'N'}</TableCell>
								<TableCell>{moment(seriesItem.created).format('YYYY-MM-DD')}</TableCell>
								<TableCell>{moment(seriesItem.lastUpdated).format('YYYY-MM-DD')}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<div>
						<TextField disabled style={{ width: '100%' }} margin="dense" variant="outlined" multiline label="Overview" value={seriesItem.overview} />
					</div>
					<div>
						<TextField
							disabled
							style={{ width: '100%' }}
							margin="dense"
							variant="outlined"
							multiline
							label="Keywords"
							value={seriesItem.keywords.map((keyword) => keyword.name).join(', ')}
						/>
					</div>
					<div>
						<TextField
							disabled
							style={{ width: '100%' }}
							margin="dense"
							variant="outlined"
							multiline
							label="Recomendations"
							value={seriesItem.recommendations}
						/>
					</div>
					<div className={classes.divContianer}>
						Homepage: &nbsp; &nbsp;
						<a target="_blank" rel="noopener noreferrer" href={seriesItem.homepage}>
							{seriesItem.homepage}
						</a>
					</div>
				</Grid>
			</Grid>
			{/** Offers */}
			<Grid container style={{ marginTop: '10px' }}>
				{isFetchingOffer ? (
					<div>Fetching offer...</div>
				) : relatedSeriesRef.current.length > 0 ? (
					relatedSeriesRef.current.map((series) => {
						return renderSeriesOffer(series);
					})
				) : (
					<div>No offer!!!</div>
				)}
			</Grid>
			{/** Poster */}
			<h3>Posters:</h3>
			<Grid container wrap="nowrap" style={{ overflowX: 'scroll' }}>
				{seriesItem.images.posters.map((poster) => (
					<div key={`poster.${poster.file_path}`}>
						<a target="_blank" rel="noopener noreferrer" href={`https://image.tmdb.org/t/p/original${poster.file_path}`}>
							<img className={classes.smallPoster} alt={'img'} src={`https://image.tmdb.org/t/p/w185${poster.file_path}`} />
						</a>
						<div style={{ textAlign: 'center' }}>{`${poster.width}*${poster.height} - ${poster.iso_639_1 ? poster.iso_639_1 : ''}`}</div>
						<div style={{ textAlign: 'center' }}>{`Ratio: ${calRatio(poster.width, poster.height)}`}</div>
					</div>
				))}
			</Grid>
			{/** Backdrops */}
			<h3>Backdrops:</h3>
			<Grid container wrap="nowrap" style={{ overflowX: 'scroll' }}>
				{seriesItem.images.backdrops.map((backdrop) => (
					<div key={`backdrop.${backdrop.file_path}`}>
						<a target="_blank" rel="noopener noreferrer" href={`https://image.tmdb.org/t/p/original${backdrop.file_path}`}>
							<img className={classes.smallBackdrop} alt={'img'} src={`https://image.tmdb.org/t/p/w300${backdrop.file_path}`} />
						</a>
						<div style={{ textAlign: 'center' }}>{`${backdrop.width}*${backdrop.height} - ${backdrop.iso_639_1 ? backdrop.iso_639_1 : ''}`}</div>
						<div style={{ textAlign: 'center' }}>{`Ratio: ${calRatio(backdrop.width, backdrop.height)}`}</div>
					</div>
				))}
			</Grid>
			{/** Casts */}
			<h3>Casts:</h3>
			<Grid container wrap="nowrap" style={{ overflowX: 'scroll' }}>
				{seriesItem.credits.cast.map((cast) => (
					<div key={`cast.${cast.id}`}>
						<a target="_blank" rel="noopener noreferrer" href={`https://www.themoviedb.org/person/${cast.id}`}>
							<img
								className={classes.profile}
								alt={'img'}
								src={cast.profile_path ? `https://image.tmdb.org/t/p/w185${cast.profile_path}` : PROFILE_PLACEHOLDER}
							/>
						</a>
						<div style={{ textAlign: 'center', maxWidth: '150px' }}>{cast.name}</div>
						<div style={{ textAlign: 'center', maxWidth: '150px' }}>{`ch: ${cast.character}`}</div>
					</div>
				))}
			</Grid>
			{/** Crews */}
			<h3>Crews:</h3>
			<Grid container wrap="nowrap" style={{ overflowX: 'scroll' }}>
				{seriesItem.credits.crew.map((crew) => (
					<div key={`crew.${crew.id}.${crew.department}.${crew.credit_id}`}>
						<a target="_blank" rel="noopener noreferrer" href={`https://www.themoviedb.org/person/${crew.id}`}>
							<img
								className={classes.profile}
								alt={'img'}
								src={crew.profile_path ? `https://image.tmdb.org/t/p/w185${crew.profile_path}` : PROFILE_PLACEHOLDER}
							/>
						</a>
						<div style={{ textAlign: 'center', maxWidth: '150px' }}>{crew.name}</div>
						<div style={{ textAlign: 'center', maxWidth: '150px' }}>{`job: ${crew.job}`}</div>
					</div>
				))}
			</Grid>
			{/** Created by */}
			<h3>Created by:</h3>
			<Grid container wrap="nowrap" style={{ overflowX: 'scroll' }}>
				{seriesItem.createdBy.map((createdBy) => (
					<div key={`createdBy.${createdBy.id}.${createdBy.credit_id}`}>
						<a target="_blank" rel="noopener noreferrer" href={`https://www.themoviedb.org/person/${createdBy.id}`}>
							<img
								className={classes.profile}
								alt={'img'}
								src={createdBy.profile_path ? `https://image.tmdb.org/t/p/w185${createdBy.profile_path}` : PROFILE_PLACEHOLDER}
							/>
						</a>
						<div style={{ textAlign: 'center', maxWidth: '150px' }}>{createdBy.name}</div>
					</div>
				))}
			</Grid>
			{/** Seasons */}
			<h3>Seasons:</h3>
			<Grid container wrap="nowrap" style={{ overflowX: 'scroll' }}>
				{seriesItem.seasons.map((season) => (
					<div key={`season.${season.id}`}>
						<a target="_blank" rel="noopener noreferrer" href={`https://www.themoviedb.org/tv/${seriesItem.id}/season/${season.season_number}`}>
							<img
								className={classes.smallPoster}
								alt={'img'}
								src={season.poster_path ? `https://image.tmdb.org/t/p/w185${season.poster_path}` : IMG_PLACEHOLDER}
							/>
						</a>
						<div style={{ textAlign: 'center' }}>{season.name}</div>
					</div>
				))}
			</Grid>
			{/** Videos */}
			<h3>Videos:</h3>
			<Grid container wrap="nowrap" style={{ overflowX: 'scroll' }}>
				{seriesItem.videos.map((video) => (
					<div key={`video.${video.id}`}>
						<a target="_blank" rel="noopener noreferrer" href={`https://www.themoviedb.org/video/play?key=${video.key}`}>
							<img className={classes.smallVideo} alt={'img'} src={`https://i.ytimg.com/vi/${video.key}/hqdefault.jpg`} />
						</a>
						<div style={{ textAlign: 'center', maxWidth: '170px' }}>{video.name}</div>
					</div>
				))}
			</Grid>
			<Grid container spacing={1} style={{ marginTop: '10px' }}>
				{/** Translations */}
				<Grid item xs={6}>
					<h5>Translations:</h5>
					<ReactJson
						displayDataTypes={false}
						groupArraysAfterLength={500}
						name={false}
						src={map(seriesItem.translations, (translation) => pick(translation, ['name', 'iso_639_1', 'data.title', 'data.runtime', 'data.overview']))}
					/>
				</Grid>
				{/** Content ratings */}
				<Grid item xs={3}>
					<h5>Content ratings:</h5>
					<ReactJson displayDataTypes={false} groupArraysAfterLength={500} name={false} src={seriesItem.contentRatings} />
				</Grid>
				{/** Alternative titles */}
				<Grid item xs={3}>
					<h5>Alternative titles:</h5>
					<ReactJson displayDataTypes={false} groupArraysAfterLength={500} name={false} src={seriesItem.alternativeTitles} />
				</Grid>
			</Grid>
		</div>
	);
}
