import React, { useRef, useState, useEffect } from 'react';
import { makeStyles, Grid, Card, CardContent, Typography, LinearProgress } from '@material-ui/core';
import { get } from 'lodash';
import moment from 'moment';
import { ITVItemGame, ITVItemGameVideo, ITVSection } from '../TVInterface';
import { fetchRecMenuItem } from '../../../api';

interface GameDetailProps {
	item: ITVItemGame;
	headers: { serverType: number; client: string; regionCode: string; sdkVersion: number; languageCode?: string; refreshCache: boolean };
}

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		height: '200px'
	},
	image: {
		minWidth: '355px',
		height: '200px'
	},
	progress: {
		marginTop: theme.spacing(2),
		height: '4px'
	},
	sectionContainer: {
		maxHeight: 'calc(100vh - 450px)',
		overflowY: 'auto',
		margin: '10px',
		padding: '10px',
		outline: '1px dashed rgb(200,200,200)'
	},
	container: {
		overflowX: 'auto'
	},
	itemContainer: {
		width: '200px',
		height: '120px',
		flexShrink: 0,
		padding: '10px',
		transition: '0.3s',
		'&:hover': {
			cursor: 'pointer',
			padding: '5px'
		}
	},
	poster: {
		width: '180px',
		height: 'auto',
		transition: '0.3s',
		opacity: 0.85,
		'&:hover': {
			width: '190px',
			opacity: 1
		}
	}
}));

function renderGameMetadata(item: ITVItemGame) {
	const { releaseYear, rating, genres } = item;
	return (
		<CardContent>
			<Typography variant="h6" component="h4">
				<span>{`${releaseYear}`}&nbsp;&nbsp;&nbsp;</span>
				<span>{`${rating.esrb.code}/${rating.pegi.code}`}&nbsp;&nbsp;&nbsp;</span>
				<span>{`${genres.join(', ')}`}</span>
			</Typography>
		</CardContent>
	);
}

function renderVideoMetadata(videoItem?: ITVItemGameVideo) {
	if (!videoItem) return null;

	const { title, creatorName, duration, publishedAt, viewCount } = videoItem;
	const mins = Math.floor(Number(duration.value) / 60);
	const seconds = Number(duration.value) % 60;
	const durationText = mins > 60 ? `${Math.floor(mins / 60)}h ${mins % 60}m` : mins > 0 ? `${mins}m ${seconds}s` : `${seconds}s`;
	const viewCountText = viewCount > 1000 ? `${Math.floor(viewCount / 1000)}K views` : `${viewCount} views`;

	return (
		<CardContent style={{ paddingTop: 0 }}>
			<Typography variant="h6" component="h6">
				<span>{title}&nbsp;&nbsp;&nbsp;</span>
			</Typography>
			<Typography variant="body2" component="p">
				<span>{`by: ${creatorName}`}</span>
				<br />
				<span>{`duration: ${durationText}`}</span>
				<br />
				<span>{`published: ${moment(publishedAt).format('MMM d YYYY')}`}</span>
				<br />
				<span>{viewCountText}</span>
			</Typography>
		</CardContent>
	);
}

export default function GameDetail(props: GameDetailProps) {
	const classes = useStyles();
	const { item, headers } = props;

	const videoDataRef = useRef([]);
	const [isLoadingVideo, setIsLoadingVideo] = useState(false);
	const [currentFocusedItem, setCurrentFocusedItem] = useState<ITVItemGameVideo>();

	useEffect(() => {
		async function effect() {
			setIsLoadingVideo(true);
			try {
				const res = await fetchRecMenuItem({ ...headers, ...{ menuType: item.type, itemId: item.id } });
				const { status } = res;
				if (status === 200) {
					const { sections } = await res.json();
					videoDataRef.current = sections;
					setCurrentFocusedItem(get(videoDataRef.current, '[0].items[0]'));
				} else {
					setCurrentFocusedItem(undefined);
				}
				setIsLoadingVideo(false);
			} catch (error) {
				console.log(error);
				setIsLoadingVideo(false);
			}
		}
		effect();
	}, [headers, item.type, item.id]);

	const renderVideoSection = function (section: ITVSection) {
		const { name, items } = section;

		return (
			<Grid key={`${name}`}>
				<Typography variant="h5" color="textSecondary">
					{name}
				</Typography>
				<Grid container wrap="nowrap" className={classes.container} alignItems="center">
					{items.map((item) => {
						const { media, url } = item;
						const posterUrl = media.posters[0].url;

						return (
							<div key={`${name}-${item.id}`} className={classes.itemContainer} onMouseEnter={() => setCurrentFocusedItem(item as ITVItemGameVideo)}>
								<a target="_blank" rel="noopener noreferrer" href={`${url.web || url.android}`}>
									<img alt="img" src={posterUrl} className={classes.poster} />
								</a>
							</div>
						);
					})}
				</Grid>
			</Grid>
		);
	};

	if (!item) return null;

	const { media } = item;
	const backgroundImageUrl = media.backdrops[0].url;

	return (
		<React.Fragment>
			{/** Header */}
			<Grid container>
				<Grid item xs>
					<Card style={{ minHeight: '200px', marginRight: '20px' }}>
						{renderGameMetadata(item)}
						{renderVideoMetadata(currentFocusedItem)}
					</Card>
				</Grid>
				<Grid item key={`summary-${item.id}`} className={classes.imageContainer}>
					<a target="_blank" rel="noopener noreferrer" href={backgroundImageUrl}>
						<img alt="img" src={backgroundImageUrl} className={classes.image} />
					</a>
				</Grid>
			</Grid>
			{/** Loading progress */}
			{isLoadingVideo ? <LinearProgress className={classes.progress} /> : <div className={classes.progress} />}

			{/** Section list */}
			<Grid className={classes.sectionContainer}>
				{videoDataRef.current.length ? videoDataRef.current.map((section) => renderVideoSection(section)) : null}
			</Grid>
		</React.Fragment>
	);
}
