import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Checkbox,
	Container,
	TablePagination,
	FormControlLabel,
	Switch,
	TextField,
	Button
} from '@material-ui/core';
import moment from 'moment';
import { PAGE_SIZE } from './Game';
import Title from '../common/Title';

export interface GameEntity {
	id: number;
	guid: string;
	name: string;
	summary?: string;
	releaseYear?: number;
	ageRatingEsrb?: string;
	ageRatingPegi?: string;
	posterUrlJson?: ImageInfo;
	backgroundImageUrlJson?: ImageInfo[];
	currentRank: number;
	externalIdTwitch: string;
	externalIdIgdb?: number;
	isEnabled: number;
	createdAt: string;
	lastTop100: string;
	genres?: string[];
	notes?: string;
}

export interface ImageInfo {
	url?: string;
	width?: number;
	height?: number;
}

interface GameListProps {
	isSearching: boolean;
	searchText: string;
	showEnabledFalse: boolean;
	currentPage: number;
	totalGameCount: number;
	gameList: GameEntity[];
	updatePage: (pageIndex: number) => void;
	setShowEnabledFalse: (enabledFalse: boolean) => void;
	selectedGame: (gameId: number) => void;
	updateSearchText: (searchText: string) => void;
	startSearch: () => void;
}

const useStyles = makeStyles((theme) => ({
	container: {
		paddingBottom: theme.spacing(1),
		paddingRight: 0,
		display: 'flex',
		justifyContent: 'space-between'
	},
	searchContainer: {
		display: 'flex',
		alignItems: 'flex-end',
		width: '50%'
	},
	searchText: {
		width: '100%',
		marginRight: '10px'
	},
	searchButton: {
		width: '150px'
	}
}));

export default function GameList(props: GameListProps) {
	const {
		isSearching,
		searchText,
		showEnabledFalse,
		currentPage,
		totalGameCount,
		gameList,
		updatePage,
		setShowEnabledFalse,
		selectedGame,
		updateSearchText,
		startSearch
	} = props;
	const classes = useStyles();

	return (
		<React.Fragment>
			<Title>Games</Title>
			<Container className={classes.container}>
				<FormControlLabel
					control={
						<Switch
							checked={showEnabledFalse}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								setShowEnabledFalse(e.target.checked);
							}}
							name="enabledFalse"
							color="primary"
						/>
					}
					label="Only show(enabled: false)"
				/>
				<div className={classes.searchContainer}>
					<TextField
						className={classes.searchText}
						margin="none"
						id="search"
						label="Search"
						name="search"
						value={searchText}
						onChange={(event) => updateSearchText(event.target.value)}
						onKeyDown={(event) => (event.keyCode === 13 ? startSearch() : null)}
					/>
					<Button size="large" variant="contained" color="primary" className={classes.searchButton} disabled={isSearching} onClick={startSearch}>
						{isSearching ? 'SEARCHING' : 'SEARCH'}
					</Button>
				</div>
			</Container>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>id</TableCell>
						<TableCell>Game</TableCell>
						<TableCell>Current rank</TableCell>
						<TableCell>Enabled</TableCell>
						<TableCell>Imported data</TableCell>
						<TableCell>Last top100</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{gameList.map((game) => (
						<TableRow
							key={`${game.id}-${setShowEnabledFalse}`}
							onClick={() => {
								selectedGame(game.id);
							}}
						>
							<TableCell>{game.id}</TableCell>
							<TableCell>{game.name}</TableCell>
							<TableCell>{game.currentRank}</TableCell>
							<TableCell>
								<Checkbox disabled checked={!!game.isEnabled} />
							</TableCell>
							<TableCell>{moment(game.createdAt).format('MMM DD YYYY')}</TableCell>
							<TableCell>{moment(game.lastTop100).format('MMM DD YYYY')}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[PAGE_SIZE]}
				component="div"
				count={totalGameCount}
				rowsPerPage={PAGE_SIZE}
				page={totalGameCount > 0 ? currentPage - 1 : 0}
				onPageChange={(event: unknown, newPage: number) => {
					updatePage(newPage + 1);
				}}
			/>
		</React.Fragment>
	);
}
