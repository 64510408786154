import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import BugReportIcon from '@material-ui/icons/BugReport';
import MovieIcon from '@material-ui/icons/Movie';
import TvIcon from '@material-ui/icons/Tv';
import BuildIcon from '@material-ui/icons/Build';
import PeopleIcon from '@material-ui/icons/People';

interface AppMenuProps {
	selectedModule: string;
	callback(module: string): void;
}

export default function AppMenu(props: AppMenuProps) {
	const [selectedModule, setSelectedModule] = React.useState(props.selectedModule);

	const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, module: string) => {
		setSelectedModule(module);
		props.callback(module);
	};

	return (
		<List>
			<ListItem button selected={selectedModule === 'game'} onClick={(event) => handleListItemClick(event, 'game')}>
				<ListItemIcon>
					<SportsEsportsIcon />
				</ListItemIcon>
				<ListItemText primary="Game Management" />
			</ListItem>
			<ListItem button selected={selectedModule === 'previewtv'} onClick={(event) => handleListItemClick(event, 'previewtv')}>
				<ListItemIcon>
					<LiveTvIcon />
				</ListItemIcon>
				<ListItemText primary="Preview TV" />
			</ListItem>
			<ListItem button selected={selectedModule === 'catalog_crawlers'} onClick={(event) => handleListItemClick(event, 'catalog_crawlers')}>
				<ListItemIcon>
					<BugReportIcon />
				</ListItemIcon>
				<ListItemText primary="Catalog Crawlers" />
			</ListItem>
			<ListItem button selected={selectedModule === 'tmdb_movies'} onClick={(event) => handleListItemClick(event, 'tmdb_movies')}>
				<ListItemIcon>
					<MovieIcon />
				</ListItemIcon>
				<ListItemText primary="TMDB Movies" />
			</ListItem>
			<ListItem button selected={selectedModule === 'tmdb_series'} onClick={(event) => handleListItemClick(event, 'tmdb_series')}>
				<ListItemIcon>
					<TvIcon />
				</ListItemIcon>
				<ListItemText primary="TMDB Series" />
			</ListItem>
			<ListItem button selected={selectedModule === 'tmdb_person'} onClick={(event) => handleListItemClick(event, 'tmdb_person')}>
				<ListItemIcon>
					<PeopleIcon />
				</ListItemIcon>
				<ListItemText primary="TMDB Person" />
			</ListItem>
			<ListItem button selected={selectedModule === 'hunter_tool'} onClick={(event) => handleListItemClick(event, 'hunter_tool')}>
				<ListItemIcon>
					<BuildIcon />
				</ListItemIcon>
				<ListItemText primary="Hunter tool" />
			</ListItem>
		</List>
	);
}

AppMenu.propTypes = {
	selectedModule: PropTypes.string,
	callback: PropTypes.func
};
