import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Table, TableHead, TableRow, TableCell, TablePagination, TableBody } from '@material-ui/core';
import clsx from 'clsx';
import { get, first } from 'lodash';
import { TmdbGenre, TmdbImage, TmdbCast, TmdbCrew, TmdbKeyword, TmdbVideo } from '../tmdbMovies/TmdbMovieList';
import { PAGE_SIZE } from './TmdbSeries';

interface TmdbSeason {
	air_date: string;
	episode_count: number;
	id: number;
	name: string;
	overview: string;
	poster_path: string;
	season_number: number;
}

interface TmdbSeriesCreatedBy {
	credit_id: string;
	gender: number;
	id: number;
	name: string;
	profile_path: string;
}

export interface TmdbSeriesItem {
	id: number;
	uid: string;
	imdbId: string;
	status: string;
	type: string;
	inProduction: number;
	name: string;
	originalName: string;
	originalLanguage: string;
	overview: string;
	firstAirDate: string;
	lastAirDate: string;
	numberOfEpisodes: number;
	numberOfSeasons: number;
	originCountry: string;
	episodeRuntime: number[];
	popularity: number;
	genres: TmdbGenre[];
	homepage: string;
	voteAverage: number;
	voteCount: number;
	languages: string[];
	seasons: TmdbSeason[];
	createdBy: TmdbSeriesCreatedBy[];
	lastEpisodeToAir: any;
	nextEpisodeToAir: any;
	alternativeTitles: any;
	images: {
		backdrops: TmdbImage[];
		posters: TmdbImage[];
	};
	credits: {
		cast: TmdbCast[];
		crew: TmdbCrew[];
	};
	keywords: TmdbKeyword[];
	translations: any;
	videos: TmdbVideo[];
	recommendations: string;
	contentRatings: any;
	created: string;
	lastUpdated: string;
}

interface TmdbSeriesListProps {
	seriesItems: TmdbSeriesItem[];
	currentPage: number;
	totalSeriesCount: number;
	searchText: string;
	status: string;
	updateSearchText: (searchText: string) => void;
	updateStatus: (status: string) => void;
	startSearch: () => void;
	updatePage: (newPage: number) => void;
	updateCurrentSeriesItem: (series: TmdbSeriesItem) => void;
}

const useStyle = makeStyles((theme) => ({
	container: {
		paddingBottom: theme.spacing(1),
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end'
	},
	searchText: {
		minWidth: '300px'
	},
	underlineText: {
		textDecoration: 'underline',
		fontWeight: 400,
		fontSize: '1rem'
	},
	hover: {
		'&:hover': {
			cursor: 'pointer'
		}
	},
	editText: {
		color: 'blue'
	},
	table: {
		tableLayout: 'fixed',
		border: '2px solid grey'
	},
	cell: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	poster: {
		height: '100px'
	}
}));

export default function TmdbSeriesList(props: TmdbSeriesListProps) {
	const { currentPage, totalSeriesCount, updatePage, seriesItems, searchText, updateSearchText, startSearch, updateCurrentSeriesItem } = props;
	const classes = useStyle();

	const detailActionPressed = useCallback(
		(seriesItem: TmdbSeriesItem) => {
			updateCurrentSeriesItem(seriesItem);
		},
		[updateCurrentSeriesItem]
	);

	return (
		<React.Fragment>
			<div className={classes.container}>
				<div></div>
				<div>
					<TextField
						className={classes.searchText}
						margin="none"
						id="search"
						name="search"
						placeholder="Key in and Press 'Return' to search"
						value={searchText}
						onChange={(event) => updateSearchText(event.target.value)}
						onKeyDown={(event) => (event.keyCode === 13 ? startSearch() : null)}
					/>
				</div>
			</div>
			<React.Fragment>
				<Table className={classes.table} size="small">
					<TableHead>
						<TableRow>
							<TableCell style={{ width: '5%' }}>Id</TableCell>
							<TableCell style={{ width: '15%' }}>Title</TableCell>
							<TableCell style={{ width: '10%' }}>Poster</TableCell>
							<TableCell style={{ width: '10%' }}>Release Date</TableCell>
							<TableCell style={{ width: '45%' }}>Overview</TableCell>
							<TableCell style={{ width: '7%' }}>action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{seriesItems.map((seriesItem) => (
							<TableRow key={seriesItem.id} onClick={() => detailActionPressed(seriesItem)}>
								<TableCell>{seriesItem.id}</TableCell>
								<TableCell>{seriesItem.name}</TableCell>
								<TableCell className={classes.cell}>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={`https://image.tmdb.org/t/p/original${get(first(seriesItem.images.posters), 'file_path')}`}
										onClick={(e) => e.stopPropagation()}
									>
										<img src={`https://image.tmdb.org/t/p/w500${get(first(seriesItem.images.posters), 'file_path')}`} alt={'img'} className={classes.poster} />
									</a>
								</TableCell>
								<TableCell className={classes.cell}>{seriesItem.firstAirDate}</TableCell>
								<TableCell>{seriesItem.overview}</TableCell>
								<TableCell className={classes.cell}>
									<span className={clsx(classes.underlineText, classes.editText, classes.hover)} onClick={() => detailActionPressed(seriesItem)}>
										Detail
									</span>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[PAGE_SIZE]}
					component="div"
					count={totalSeriesCount}
					rowsPerPage={PAGE_SIZE}
					page={totalSeriesCount > 0 ? currentPage - 1 : 0}
					onPageChange={(_, newPage: number) => {
						updatePage(newPage + 1);
					}}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
