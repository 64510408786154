import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableHead, TableRow, TableCell, TableBody, Grid, InputLabel, Select } from '@material-ui/core';
import { useHistory } from 'react-router';
import { keys, first } from 'lodash';
import moment from 'moment';
import clsx from 'clsx';

export interface StatisticsEntity {
	[key: string]: StatisticsEntityValue[];
}

export interface StatisticsEntityValue {
	source: string;
	type: string;
	new?: number;
	moderated?: number;
	inactive?: number;
	lastStartUpdated?: string;
	lastEndUpdated?: string;
}

export interface StatisticItemEntity {
	country: string;
	source: string;
	type: string;
	status: string;
	count?: number;
}
interface CatalogOverviewProps {
	statistics: StatisticsEntity | undefined;
	currentCountry: string | undefined;
	updateCurrentCountry: (country: string) => void;
	updateStatisticsItem: (item: StatisticItemEntity) => void;
	purgeData: (item: StatisticsEntityValue) => void;
}

const useStyle = makeStyles((theme) => ({
	container: {
		paddingBottom: theme.spacing(1),
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		display: 'flex',
		justifyContent: 'space-between'
	},
	selectControl: {
		marginLeft: '20px',
		width: '150px'
	},
	underlineText: {
		textDecoration: 'underline',
		color: 'blue',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	cell: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	hover: {
		'&:hover': {
			cursor: 'pointer'
		}
	},
	editText: {
		color: 'red'
	}
}));

export default function CatalogOverview(props: CatalogOverviewProps) {
	const classes = useStyle();
	const history = useHistory();

	let { statistics, currentCountry, updateStatisticsItem, updateCurrentCountry, purgeData } = props;
	const countries = keys(statistics);
	if (!currentCountry && countries.length) {
		currentCountry = first(countries);
	}

	const purgeActionPressed = useCallback(
		(item) => {
			const shouldPurge = window.confirm('Are you sure you want to delete?');
			if (shouldPurge) {
				purgeData(item);
			}
		},
		[purgeData]
	);

	const navigateToList = useCallback(
		(source: string, type: string, status: string, count: number) => {
			if (count) {
				const country = currentCountry as string;
				updateStatisticsItem({ country, source, type, status, count });
				history.push(`/catalog_crawlers/${currentCountry}/${source}?type=${type}&status=${status}&page=1`);
			}
		},
		[currentCountry, history, updateStatisticsItem]
	);

	if (!statistics || !currentCountry || !statistics[currentCountry]) return <div />;

	return (
		<React.Fragment>
			<div className={classes.container}>
				<Grid container spacing={0} direction="row" alignItems="center" justifyContent="flex-start">
					<InputLabel htmlFor="outlined-country">Select Country: </InputLabel>
					<Select
						native
						className={classes.selectControl}
						value={currentCountry}
						onChange={(event) => updateCurrentCountry(event.target.value as string)}
						label="Select Country"
						inputProps={{
							name: 'country',
							id: 'outlined-country'
						}}
					>
						{countries.map((country) => (
							<option key={country} value={country}>
								{country}
							</option>
						))}
					</Select>
				</Grid>
			</div>
			<Table size="small" style={{ border: '2px solid grey' }}>
				<TableHead>
					<TableRow>
						<TableCell>Logo</TableCell>
						<TableCell>Crawl Source</TableCell>
						<TableCell>Type</TableCell>
						<TableCell>NEW</TableCell>
						<TableCell>MODERATED</TableCell>
						<TableCell>INACTIVE</TableCell>
						<TableCell>Last Start updated</TableCell>
						<TableCell>Last End updated</TableCell>
						<TableCell>Action</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{statistics[currentCountry].map((value) => (
						<TableRow key={`${currentCountry}-${value.source}-${value.type}`}>
							<TableCell>
								<img alt="Logo" width="40" src={`https://mosaix-tv-cdn.mosaix.ai/assets/icons/source/${value.source.split('.')[1].toLocaleLowerCase()}.png`} />
							</TableCell>
							<TableCell>{value.source}</TableCell>
							<TableCell>{value.type}</TableCell>
							<TableCell>
								<div
									className={value.new ? classes.underlineText : undefined}
									onClick={() => navigateToList(value.source, value.type, 'new', Number(value.new))}
								>
									{value.new ? value.new : 0}
								</div>
							</TableCell>
							<TableCell>
								<div
									className={value.moderated ? classes.underlineText : undefined}
									onClick={() => navigateToList(value.source, value.type, 'moderated', Number(value.moderated))}
								>
									{value.moderated ? value.moderated : 0}
								</div>
							</TableCell>
							<TableCell>
								<div
									className={value.inactive ? classes.underlineText : undefined}
									onClick={() => navigateToList(value.source, value.type, 'inactive', Number(value.inactive))}
								>
									{value.inactive ? value.inactive : 0}
								</div>
							</TableCell>
							<TableCell>{value.lastStartUpdated ? moment(value.lastStartUpdated).format('YYYY-MM-DD HH:mm:ss') : null}</TableCell>
							<TableCell>{value.lastEndUpdated ? moment(value.lastEndUpdated).format('YYYY-MM-DD HH:mm:ss') : null}</TableCell>
							<TableCell className={classes.cell}>
								<span className={clsx(classes.underlineText, classes.editText, classes.hover)} onClick={() => purgeActionPressed(value)}>
									PURGE
								</span>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</React.Fragment>
	);
}
