import React, { useState, useEffect, useRef } from 'react';
import { Container, LinearProgress, Grid, Paper, makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { fetchTmdbMovieList, fetchTmdbItemById } from '../../api';
import TmdbMovieList, { TmdbMovie } from './TmdbMovieList';
import TmdbMovieDetail from './TmdbMovieDetail';
import { formatTmdbMovie } from '../../utils/util';

export const PAGE_SIZE = 10;

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(1)
	},
	paper: {
		padding: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column'
	},
	progress: {
		height: '4px'
	}
}));

export default function TmdbMovies() {
	const history = useHistory();
	const classes = useStyles();
	const moviesRef = useRef<TmdbMovie[]>([]);
	const totalMovieCountRef = useRef(0);
	const [currentMovieItem, setCurrentMovieItem] = useState<TmdbMovie>();
	const [status, setStatus] = useState('');
	const [searchText, setSearchText] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [pageStatus, setPageStatus] = useState('home');
	const pageInPath = /page=(\d+)/.exec(window.location.search);
	const [currentPage, setCurrentPage] = useState(pageInPath && pageInPath.length > 1 ? Number(pageInPath[1]) : 1);
	const [forceRefresh, setForceRefresh] = useState(false);
	const [directSearchMovieId, setDirectSearchMovieId] = useState(-1);

	//component lifecycle
	useEffect(() => {
		async function runEffect() {
			setIsLoading(true);
			try {
				const res = await fetchTmdbMovieList(status, searchText, currentPage, PAGE_SIZE);
				if (res.status === 200) {
					const data = await res.json();
					moviesRef.current = data.data.map((movie: any) => formatTmdbMovie(movie));
					totalMovieCountRef.current = data.totalNum;
					setIsLoading(false);
				} else if (res.status === 401) {
					history.replace('/login');
				} else {
					setIsLoading(false);
				}
			} catch (error) {
				setIsLoading(false);
				console.log(error);
			}
		}
		runEffect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, status, currentPage, forceRefresh]);

	useEffect(() => {
		async function runEffect() {
			setIsLoading(true);
			try {
				const res = await fetchTmdbItemById('movie', directSearchMovieId);
				if (res.status === 200) {
					const data = await res.json();
					setCurrentMovieItem(formatTmdbMovie(data));
					setIsLoading(false);
				} else if (res.status === 401) {
					history.replace('/login');
				} else {
					setIsLoading(false);
				}
			} catch (error) {
				setIsLoading(false);
				console.log(error);
			}
		}
		if (directSearchMovieId > -1) runEffect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [directSearchMovieId]);

	const paths = useLocation().pathname.slice(1).split('/');
	const queries = useLocation().search.slice(1).split('&');

	if (paths.length > 1 && pageStatus === 'home') {
		setPageStatus('detail');
		setDirectSearchMovieId(Number(paths[1]));
	} else if (paths.length === 1 && pageStatus === 'detail') {
		setPageStatus('home');
	} else if (paths.length === 1 && pageStatus === 'home') {
		if (queries.length > 0) {
			const page = Number(queries[0].split('=')[1]);
			if (page && page !== currentPage) {
				setCurrentPage(page);
			}
		}
	}

	return (
		<Container maxWidth="xl" className={classes.container}>
			{isLoading ? <LinearProgress /> : <div className={classes.progress} />}
			<Grid item xs={12}>
				<Paper className={classes.paper}>
					{pageStatus === 'home' ? (
						<TmdbMovieList
							currentPage={currentPage}
							totalMovieCount={totalMovieCountRef.current}
							searchText={searchText}
							status={status}
							movieItems={moviesRef.current}
							updateSearchText={(text) => setSearchText(text)}
							updateStatus={(status) => setStatus(status)}
							startSearch={() => {
								setForceRefresh((refresh) => !refresh);
							}}
							updatePage={(pageIndex) => {
								setCurrentPage(pageIndex);
								history.push(`/tmdb_movies?page=${pageIndex}`);
							}}
							updateCurrentMovieItem={(movie) => {
								dispatchEvent(new Event('scroll-to-top'));
								setCurrentMovieItem(movie);
								setPageStatus('detail');
								history.push(`/tmdb_movies/${movie.id}`);
							}}
						/>
					) : (
						<TmdbMovieDetail
							movieItem={currentMovieItem}
							backToHome={() => {
								setPageStatus('home');
								history.replace(`/tmdb_movies?page=${currentPage}`);
							}}
						/>
					)}
				</Paper>
			</Grid>
		</Container>
	);
}
