import React from 'react';
import { Grid, FormControl, InputLabel, Select, makeStyles, Button, TextField } from '@material-ui/core';
import { SEARCH_SDK_VERSION_LIST } from '../../utils/Const';

interface SearchProps {
	isLoading: boolean;
	defaultOptions: { searchSDKVersion: string; query: string };
	updateOption: (key: 'searchSDKVersion' | 'query', value: string) => void;
	startSearch: () => void;
}

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(0),
		width: '80%'
	}
}));

export default function SearchView(props: SearchProps) {
	const classes = useStyles();
	const { isLoading, defaultOptions, updateOption, startSearch } = props;

	return (
		<Grid container spacing={3} justifyContent="center" style={{ margin: '0 0 10px 0', height: '80px' }}>
			<Grid item xs={10} md={3}>
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel htmlFor="outlined-sdk">Search SDK</InputLabel>
					<Select
						native
						value={defaultOptions.searchSDKVersion}
						onChange={(event) => updateOption('searchSDKVersion', event.target.value as string)}
						label="Search SDK"
						inputProps={{
							name: 'sdk',
							id: 'outlined-sdk'
						}}
					>
						{SEARCH_SDK_VERSION_LIST.map((option) => (
							<option key={option.value} value={option.value}>
								{option.name}
							</option>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={10} md={7}>
				<TextField
					variant="standard"
					margin="none"
					required
					fullWidth
					id="query"
					label="Query"
					name="query"
					value={defaultOptions.query}
					onChange={(event) => updateOption('query', event.target.value as string)}
					onKeyDown={(event) => (event.keyCode === 13 && defaultOptions.query ? startSearch() : null)}
					autoFocus
				/>
				{/* </FormControl> */}
			</Grid>
			<Grid item xs={10} md={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<Button
					variant="contained"
					color="primary"
					size="large"
					disabled={isLoading || !defaultOptions.query}
					onClick={() => {
						startSearch();
					}}
				>
					{isLoading ? 'SEARCHING' : 'SEARCH'}
				</Button>
			</Grid>
		</Grid>
	);
}
