import React, { useState, useEffect, useRef } from 'react';
import { Container, LinearProgress, Grid, Paper, makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { get, size } from 'lodash';
import CatalogOverview, { StatisticItemEntity, StatisticsEntityValue } from './CatalogOverview';
import { fetchHunterStatistics, fetchCatalogList, fetchCatalogItemById, deleteCatalog } from '../../api';
import CatalogList, { CatalogItem } from './CatalogList';
import CatalogDetail from './CatalogDetail';

export const PAGE_SIZE = 10;

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(1)
	},
	paper: {
		padding: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column'
	},
	progress: {
		height: '4px'
	}
}));

export default function CatalogCrawlers() {
	const history = useHistory();
	const classes = useStyles();
	const statisticsRef = useRef<any>();
	const catalogItemsRef = useRef<CatalogItem[]>([]);
	const waitingNewItemRef = useRef(false);
	const totalCatalogCountRef = useRef(0);
	const [searchText, setSearchText] = useState('');
	const [orderFlag, setOrderFlag] = useState(1); // 0: ID(⬆); 1: ID(⬇) ; 2: LAST(⬆) ; 3: LAST(⬇);
	const [catalogItem, setCatalogItem] = useState<CatalogItem>();
	const [catalogItemId, setCatalogItemId] = useState(-1);
	const [forceRefreshHome, setForceRefreshHome] = useState(false);
	const [forceRefreshList, setForceRefreshList] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [pageStatus, setPageStatus] = useState('home');
	const [currentCountry, setCurrentCountry] = useState('');
	const [currentPage, setCurrentPage] = useState(-1);
	const [currentStatisticsItem, setCurrentStatisticsItem] = useState<StatisticItemEntity>();

	//component lifecycle
	useEffect(() => {
		async function runEffect() {
			if (isLoading) return;
			var tempCountry = currentCountry;
			if (!currentCountry) {
				const paths = window.location.pathname.slice(1).split('/');
				tempCountry = get(paths, '[1]', 'ES');
			}
			if (statisticsRef.current && size(statisticsRef.current[tempCountry]) > 0) statisticsRef.current[tempCountry] = [];
			setIsLoading(true);
			try {
				const res = await fetchHunterStatistics(tempCountry);
				if (res.status === 200) {
					const statistics = await res.json();
					if (statisticsRef.current) {
						statisticsRef.current[tempCountry] = get(statistics, tempCountry, []);
					} else {
						statisticsRef.current = statistics;
					}
					if (pageStatus === 'home' && !currentCountry) {
						const country = tempCountry.toUpperCase();
						setCurrentCountry(country);
						history.push(`/catalog_crawlers/${country}`);
					}
					setIsLoading(false);
				} else if (res.status === 401) {
					history.replace('/login');
				} else {
					setIsLoading(false);
				}
			} catch (error) {
				setIsLoading(false);
				console.log(error);
			}
		}
		runEffect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forceRefreshHome, history, currentCountry]);

	useEffect(() => {
		async function runEffect() {
			if (!currentStatisticsItem) return;
			catalogItemsRef.current = [];
			setIsLoading(true);
			try {
				const res = await fetchCatalogList(currentStatisticsItem, searchText, orderFlag, currentPage, PAGE_SIZE);
				if (res.status === 200 || res.status === 304) {
					const data = await res.json();
					catalogItemsRef.current = data.data;
					totalCatalogCountRef.current = data.totalNum;
					if (waitingNewItemRef.current) setCatalogItem(catalogItemsRef.current[0]);
					setIsLoading(false);
				} else if (res.status === 401) {
					history.replace('/login');
				} else {
					setIsLoading(false);
				}
			} catch (error) {
				setIsLoading(false);
				console.log(error);
			}
		}
		runEffect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forceRefreshList, orderFlag, history, currentPage, currentStatisticsItem]);

	useEffect(() => {
		async function runEffect() {
			if (!catalogItemId || catalogItemId < 1 || !currentStatisticsItem) return;

			setIsLoading(true);
			try {
				const { country, type } = currentStatisticsItem;
				const res = await fetchCatalogItemById(country, type, catalogItemId);
				if (res.status === 200 || res.status === 304) {
					const data = await res.json();
					setCatalogItem(data);
					setIsLoading(false);
				} else if (res.status === 401) {
					history.replace('/login');
				} else {
					setIsLoading(false);
				}
			} catch (error) {
				setIsLoading(false);
				console.log(error);
			}
		}
		runEffect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [catalogItemId]);

	const updateStatisticsItem = function (item: StatisticItemEntity) {
		setCurrentStatisticsItem(item);
		setCurrentPage(1);
		setPageStatus('list');
	};

	const purgeCatalog = async function (item: StatisticsEntityValue) {
		if (!currentCountry) return;

		setIsLoading(true);
		await deleteCatalog(currentCountry, item.source, item.type);
		setIsLoading(false);
		//re-fetch data
		setForceRefreshHome((refresh) => !refresh);
	};

	const editCatalogItem = function (item: CatalogItem) {
		setCatalogItem(item);
		setPageStatus('edit');
		history.push(
			`/catalog_crawlers/${currentStatisticsItem?.country}/${currentStatisticsItem?.source}?type=${currentStatisticsItem?.type}&status=${
				currentStatisticsItem?.status
			}&page=${currentPage + 1}&catalogId=${item.id}`
		);
	};

	const moveToNextCatalogItem = function (currentItem: CatalogItem) {
		waitingNewItemRef.current = false;
		const nextItem = catalogItemsRef.current.find((item) => item.id > currentItem.id);
		if (nextItem) {
			setCatalogItem(nextItem);
			setForceRefreshList((refresh) => !refresh);
		} else {
			//Finished all current items, force to fetch more items
			if (currentPage * PAGE_SIZE < totalCatalogCountRef.current) {
				waitingNewItemRef.current = true;
				history.push(
					`/catalog_crawlers/${currentStatisticsItem?.country}/${currentStatisticsItem?.source}?type=${currentStatisticsItem?.type}&status=${
						currentStatisticsItem?.status
					}&page=${currentPage + 1}`
				);
				setCurrentPage(currentPage + 1);
			} else {
				// No need to navigate to list page
				// setPageStatus('list');
				// setForceRefreshList((refresh) => !refresh);
			}
		}
	};

	// console.log(useLocation());

	const paths = useLocation().pathname.slice(1).split('/');
	const queries = useLocation().search.slice(1).split('&');

	if (paths.length < 3 && pageStatus === 'list') {
		setCurrentCountry(paths[0]);
		setPageStatus('home');
	}

	if (paths.length >= 3 && pageStatus === 'home') {
		if (queries.length === 3) {
			setCurrentPage(Number(queries[2].split('=')[1]));
			setCurrentStatisticsItem({
				country: paths[1],
				source: paths[2],
				type: queries[0].split('=')[1],
				status: queries[1].split('=')[1]
			});
			setPageStatus('list');
		} else if (queries.length === 4) {
			setCurrentPage(Number(queries[2].split('=')[1]));
			setCurrentStatisticsItem({
				country: paths[1],
				source: paths[2],
				type: queries[0].split('=')[1],
				status: queries[1].split('=')[1]
			});
			setCatalogItemId(Number(queries[3].split('=')[1]));
			setPageStatus('edit');
		}
	} else if (paths.length < 3 && pageStatus !== 'home') {
		setCurrentCountry(paths[1]);
		setPageStatus('home');
	}

	return (
		<div>
			<Container maxWidth="xl" className={classes.container}>
				{isLoading ? <LinearProgress /> : <div className={classes.progress} />}
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						{pageStatus === 'home' ? (
							<CatalogOverview
								currentCountry={currentCountry}
								statistics={statisticsRef.current}
								updateCurrentCountry={(country) => {
									setCurrentCountry(country);
									history.push(`/catalog_crawlers/${country}`);
								}}
								updateStatisticsItem={updateStatisticsItem}
								purgeData={purgeCatalog}
							/>
						) : pageStatus === 'list' ? (
							<CatalogList
								searchText={searchText}
								orderFlag={orderFlag}
								statisticsItem={currentStatisticsItem as StatisticItemEntity}
								catalogItems={catalogItemsRef.current}
								currentPage={currentPage}
								totalCatalogCount={totalCatalogCountRef.current}
								updateSearchText={(text) => setSearchText(text)}
								updateOrderFlag={(flag) => setOrderFlag(flag)}
								startSearch={() => {
									if (currentPage > 1) {
										setCurrentPage(1);
										history.push(
											`/catalog_crawlers/${currentStatisticsItem?.country}/${currentStatisticsItem?.source}?type=${currentStatisticsItem?.type}&status=${currentStatisticsItem?.status}&page=1`
										);
									} else {
										setForceRefreshList((refresh) => !refresh);
									}
								}}
								backToHome={(country) => {
									setCurrentCountry(country);
									setPageStatus('home');
									history.push(`/catalog_crawlers/${country}`);
								}}
								updatePage={(pageIndex) => {
									setCurrentPage(pageIndex);
									history.push(
										`/catalog_crawlers/${currentStatisticsItem?.country}/${currentStatisticsItem?.source}?type=${currentStatisticsItem?.type}&status=${currentStatisticsItem?.status}&page=${pageIndex}`
									);
								}}
								editCatalogItem={editCatalogItem}
							/>
						) : (
							<CatalogDetail
								statisticsItem={currentStatisticsItem as StatisticItemEntity}
								catalogItem={catalogItem as CatalogItem}
								isFetchingList={isLoading}
								isLastItem={
									!!catalogItem && currentPage * PAGE_SIZE >= totalCatalogCountRef.current && !catalogItemsRef.current.find((item) => item.id > catalogItem.id)
								}
								toNextItem={moveToNextCatalogItem}
								setRefresh={() => {
									setPageStatus('list');
									history.push(
										`/catalog_crawlers/${currentStatisticsItem?.country}/${currentStatisticsItem?.source}?type=${currentStatisticsItem?.type}&status=${currentStatisticsItem?.status}&page=${currentPage}`
									);
									setForceRefreshHome((refresh) => !refresh);
									setForceRefreshList((refresh) => !refresh);
								}}
							/>
						)}
					</Paper>
				</Grid>
			</Container>
		</div>
	);
}
