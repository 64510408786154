import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { AsrTask } from './Wer';

interface TaskProps {
	isLoading: boolean;
	tasks: AsrTask[];
	currentTaskId: string;
	taskButtonClicked: (taskId: string) => void;
}

const useStyles = makeStyles((theme) => ({
	taskWrapper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexWrap: 'wrap'
	},
	task: {
		width: '18%',
		minWidth: '170px',
		minHeight: '150px',
		padding: theme.spacing(1),
		marginBottom: theme.spacing(2),
		marginRight: '1.5%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		alignItems: 'center',
		borderRadius: '5px',
		cursor: 'pointer'
	}
}));

const normalizedTaskInfo = (task: AsrTask) => {
	const totalNum = task.queries.length;
	const processedNum = task.queries.filter((el) => !!el.correction).length;

	return {
		timestamp: moment(task.timestamp, 'YYYYMMDDHHmmss').format('MMM DD YYYY HH:mm:ss'),
		status: task.status,
		wer: task.wer,
		totalNum,
		processedNum
	};
};

export default function Task(props: TaskProps) {
	const classes = useStyles();
	const { tasks, currentTaskId, taskButtonClicked } = props;

	if (tasks.length === 0) return null;

	return (
		<div className={classes.taskWrapper}>
			{tasks.map((task) => {
				const { timestamp, status, wer, totalNum, processedNum } = normalizedTaskInfo(task);

				return (
					<div
						className={classes.task}
						key={task._id}
						onClick={() => taskButtonClicked(task._id)}
						style={{ border: currentTaskId === task._id ? '1px solid blue' : undefined, backgroundColor: status === 'done' ? 'lightblue' : 'antiquewhite' }}
					>
						<div>{timestamp}</div>
						<div>{`${processedNum}/${totalNum}`}</div>
						<div>{`WER(%): ${wer ? wer : ''}`}</div>
					</div>
				);
			})}
		</div>
	);
}
