import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Table, TableHead, TableRow, TableCell, TablePagination, TableBody } from '@material-ui/core';
import moment from 'moment';
import { get, first } from 'lodash';
import { PAGE_SIZE } from './TmdbPerson';
import { formatPersonAlternativeName } from '../../utils/util';

export interface TmdbAlternativeName {
	language_code: string;
	aliases: {
		alias: string;
		score: string;
	}[];
}

interface TmdbPersonImages {
	profiles: {
		aspect_ratio: number;
		height: number;
		iso_639_1?: string;
		file_path: string;
		vote_average: number;
		vote_count: number;
		width: number;
	}[];
}
export interface TmdbPersonItem {
	id: string;
	uid: string;
	status: string;
	name: string;
	alternativeNames: TmdbAlternativeName[];
	adult: number;
	alsoKnownAs: string[];
	biography: string;
	birthday: string;
	deathday?: string;
	gender: number;
	homepage?: string;
	imdbId: string;
	knownForDepartment: string;
	placeOfBirth: string;
	popularity: number;
	images: TmdbPersonImages;
	created: string;
	lastUpdated: string;
}

interface TmdbPersonListPros {
	personItems: TmdbPersonItem[];
	currentPage: number;
	totalCount: number;
	searchText: string;
	updateSearchText: (searchText: string) => void;
	startSearch: () => void;
	updatePage: (newPage: number) => void;
}

const useStyle = makeStyles((theme) => ({
	container: {
		paddingBottom: theme.spacing(1),
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end'
	},
	searchText: {
		minWidth: '300px'
	},
	underlineText: {
		textDecoration: 'underline',
		fontWeight: 400,
		fontSize: '1rem'
	},
	hover: {
		'&:hover': {
			cursor: 'pointer'
		}
	},
	editText: {
		color: 'blue'
	},
	table: {
		tableLayout: 'fixed',
		border: '2px solid grey'
	},
	cell: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	poster: {
		height: '100px'
	}
}));

export default function TmdbPersonList(props: TmdbPersonListPros) {
	const { currentPage, totalCount, updatePage, personItems, searchText, updateSearchText, startSearch } = props;
	const classes = useStyle();

	return (
		<React.Fragment>
			<div className={classes.container}>
				<div></div>
				<div>
					<TextField
						className={classes.searchText}
						margin="none"
						id="search"
						name="search"
						placeholder="Key in and Press 'Return' to search"
						value={searchText}
						onChange={(event) => updateSearchText(event.target.value)}
						onKeyDown={(event) => (event.keyCode === 13 ? startSearch() : null)}
					/>
				</div>
			</div>
			<React.Fragment>
				<Table className={classes.table} size="small">
					<TableHead>
						<TableRow>
							<TableCell style={{ width: '7%' }}>Id</TableCell>
							<TableCell style={{ width: '12%' }}>Poster</TableCell>
							<TableCell style={{ width: '12%' }}>Name</TableCell>
							<TableCell style={{ width: '25%' }}>AlternativeNames</TableCell>
							<TableCell style={{ width: '10%' }}>KnowFor</TableCell>
							<TableCell style={{ width: '10%' }}>Birthday</TableCell>
							<TableCell style={{ width: '12%' }}>PlaceOfBirth</TableCell>
							<TableCell style={{ width: '6%' }}>Popularity</TableCell>
							<TableCell style={{ width: '10%' }}>LastUpdated</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{personItems.map((person) => (
							<TableRow
								key={person.id}
								onClick={() => {
									console.log(person);
									window.open(`https://www.themoviedb.org/person/${person.id}`, '_blank');
								}}
							>
								<TableCell>{person.id}</TableCell>
								<TableCell className={classes.cell}>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={`https://image.tmdb.org/t/p/original${get(first(person.images.profiles), 'file_path')}`}
										onClick={(e) => e.stopPropagation()}
									>
										<img src={`https://image.tmdb.org/t/p/w500${get(first(person.images.profiles), 'file_path')}`} alt={'poster'} className={classes.poster} />
									</a>
								</TableCell>
								<TableCell className={classes.cell}>{person.name}</TableCell>
								<TableCell className={classes.cell}>
									<pre>{person.alternativeNames.map((altName) => formatPersonAlternativeName(altName)).join('\n')}</pre>
								</TableCell>
								<TableCell>{person.knownForDepartment}</TableCell>
								<TableCell className={classes.cell}>{person.birthday}</TableCell>
								<TableCell>{person.placeOfBirth}</TableCell>
								<TableCell>{person.popularity}</TableCell>
								<TableCell>{moment(person.lastUpdated).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[PAGE_SIZE]}
					component="div"
					count={totalCount}
					rowsPerPage={PAGE_SIZE}
					page={totalCount > 0 ? currentPage - 1 : 0}
					onPageChange={(_, newPage: number) => {
						updatePage(newPage + 1);
					}}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
