import React, { useCallback, FormEvent, useState, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container, CssBaseline, Avatar, Typography, TextField, Link, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { login } from '../../api';

function CopyRight() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="https://mosaix.ai/">
				mosaix.ai
			</Link>
			{`  ${new Date().getFullYear()}.`}
		</Typography>
	);
}

const useStyle = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		padding: theme.spacing(2, 3),
		fontSize: '16px'
	}
}));

export default function Login() {
	const classes = useStyle();
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(false);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const loginPressed = useCallback(
		async (e: FormEvent) => {
			e.preventDefault();
			setIsLoading(true);

			try {
				const res = await login(username, password);
				const { status } = res;
				if (status !== 200) {
					setIsLoading(false);
					alert('The username or password is incorrect.');
				} else {
					history.push('/game');
				}
			} catch (error) {
				setIsLoading(false);
				alert('Something went wrong, please try again later');
			}
		},
		[username, password, history]
	);

	const onValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const { id, value } = e.target;
		if (id === 'username') {
			setUsername(value);
		} else if (id === 'password') {
			setPassword(value);
		}
	}, []);

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Login in
				</Typography>
				<form className={classes.form} onSubmit={loginPressed}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="username"
						label="Username"
						name="username"
						value={username}
						onChange={onValueChange}
						autoFocus
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="password"
						type="password"
						id="password"
						value={password}
						onChange={onValueChange}
						autoComplete="true"
					/>
					<Button type="submit" fullWidth variant="contained" color="primary" disabled={isLoading} className={classes.submit}>
						{isLoading ? 'Logging in ...' : 'Login'}
					</Button>
				</form>
			</div>
			<Box mt={8}>
				<CopyRight />
			</Box>
		</Container>
	);
}
