import React from 'react';
import { makeStyles, Grid, FormControl, Select, InputLabel, Button } from '@material-ui/core';

interface SelectionProps {
	isLoading: boolean;
	defaultOptions: {
		engine: string;
		locale: string;
		region: string;
		engines: string[];
		locales: string[];
		regionsAndCount: { region: string; count: number }[];
	};
	updateOption: (key: 'engine' | 'locale' | 'region', value: string) => void;
	pickButtonPressed: () => void;
}

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(0),
		width: '100%'
	}
}));

export default function Selection(props: SelectionProps) {
	const classes = useStyles();
	const { isLoading, defaultOptions, updateOption, pickButtonPressed } = props;

	if (!defaultOptions.locale || !defaultOptions.engine) return null;

	return (
		<Grid container spacing={4}>
			<Grid container item xs={3} justifyContent="flex-start">
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel htmlFor="outlined-engine">ENGINE</InputLabel>
					<Select
						native
						value={defaultOptions.engine}
						onChange={(event) => updateOption('engine', event.target.value as string)}
						label="ENGINE"
						inputProps={{
							name: 'engine',
							id: 'outlined-engine'
						}}
					>
						{defaultOptions.engines.map((option) => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid container item xs={3} justifyContent="flex-start">
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel htmlFor="outlined-locale">LOCALE</InputLabel>
					<Select
						native
						value={defaultOptions.locale}
						onChange={(event) => updateOption('locale', event.target.value as string)}
						label="LOCALE"
						inputProps={{
							name: 'locale',
							id: 'outlined-locale'
						}}
					>
						{defaultOptions.locales.map((option) => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid container item xs={3} justifyContent="flex-start">
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel htmlFor="outlined-locale">REGION</InputLabel>
					<Select
						native
						value={defaultOptions.region}
						onChange={(event) => updateOption('region', event.target.value as string)}
						label="REGION"
						inputProps={{
							name: 'region',
							id: 'outlined-locale'
						}}
					>
						{defaultOptions.regionsAndCount.map((option) => (
							<option key={option.region} value={option.region}>
								{`${option.region} (${option.count})`}
							</option>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid container item xs={3} justifyContent="center">
				<Button fullWidth variant="contained" color="primary" size="large" disabled={isLoading} onClick={pickButtonPressed}>
					Pick latest 100 queries
				</Button>
			</Grid>
		</Grid>
	);
}
